import React from 'react'
import { Layout } from 'antd'
import { Outlet } from 'react-router'
import BreadCrumb from '../../utils/breadCrumb'

const {Content} =  Layout

export default function Board() {
  
  return (
         <Layout
          style={{
            padding: '0 24px 24px',
          }}
        >
          <BreadCrumb />
          <Content
            style={{
              // padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
  )
}
