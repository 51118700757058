import { Button, Form, Modal, Radio, Space, Spin, Tabs } from "antd"
import {
    constructNestApiUrl,
    createOptions,
    isNotManu,
    validNumber,
} from "../../../utils/helpers"
import FormRenderer from "../formRenderer"
import { infoForm } from "../forms"
import { FileAddOutlined } from "@ant-design/icons"
import { useCallback, useContext, useEffect, useState } from "react"

import useNestedForm from "./nestedDataCall"
import moment from "moment"
import AmputationInfoTab from "./InfoTab"
import StumpInfoTab from "./StumpTab"
import DiagnosisInfoTab from "./DiagnosisTab"
import { useLocation } from "react-router"
import ImagePreview from "./components/ImagePreview"
import GlobalContext from "../../../context/global"
import { useSearchParams } from "react-router-dom"
import Store from "../../../utils/authHelper"
import { ACHILLI_OFFICE } from "../../../utils/constants"
import Access from "../../../permission/accessHelper"

const { TabPane } = Tabs

const AmputationForm = ({
    form,
    // data,
    submitForm,
    id,
    setPatientDetails,
}) => {
    const location = useLocation()
    const [params] = useSearchParams()
    const ampTab = params.get("ampTab")
    const queryAmp = params.get("ampSelected")

    const [selectedNestedForm, setSelectedForm] = useState(
        ampTab || location?.state?.tab || "info"
    )
    const [nestedDiagForm, setDiagnosisNestedForm] = useState(
        location?.state?.nestedTab || "d_stump"
    )
    const [ampModal, setAmpModal] = useState(false)
    const [ampSelected, setAmp] = useState(
        queryAmp || location?.state?.selectedAmp || 0
    )
    const [lastDno, setLastDno] = useState(0)

    // DNO NUMER FIR DISPLAYING IN DNO TAB
    const [dno, setDnoNumber] = useState(location?.state?.dno || "")
    const [proType, setProType] = useState("")
    const [url] = useState(() => {
        if (ampTab === "stump") return `editor/stump/${id}/${ampSelected}`
        else if (location?.state?.url) return location?.state?.url
        else return `/editor/${id}/amp/${ampSelected}/info`
    })

    const { data: nestData, formData, loading } = useNestedForm(url, "get")
    const store = useContext(GlobalContext.GlobalContextState)

    const [newForm] = Form.useForm()

    const reasonVal = Form.useWatch("reason", newForm)

    const office = Store.getFromLocal(ACHILLI_OFFICE)

    useEffect(() => {
        // Skip when coming from diagnosis tab (redirection flag)
        if (
            !nestData?.patient_info?.last_ano ||
            nestData?.patient_info?.last_ano === ampSelected ||
            location?.state?.redirection ||
            ampTab === "stump"
        )
            return
        setAmp(nestData?.patient_info?.last_ano)
        formData(
            `/editor/${id}/amp/${nestData?.patient_info?.last_ano}/info`,
            undefined,
            "get"
        )
        // uncomment below line if amp no is not passed via state of another page
        // setUrl(`/editor/${id}/amp/${nestData?.patient_info?.last_ano}/info`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nestData?.patient_info?.last_ano])

    // Redirection case to diagnosis from table
    useEffect(() => {
        if (!location?.state?.dno || lastDno === location?.state?.dno) return
        setLastDno(location?.state?.dno)
        if (!location?.state?.stopEffect) {
            formData(
                `/editor/dgns/stump/${id}/${ampSelected}/${location?.state?.dno}/`,
                undefined,
                "get"
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.dno])

    useEffect(() => {
        if (nestData?.form_data?.current_diagnosis?.pro_type) {
            setProType(nestData?.form_data?.current_diagnosis?.pro_type)
        }
    }, [nestData?.form_data?.current_diagnosis?.pro_type])

    useEffect(() => {
        // Last DNO is only present in info tab/api
        if (selectedNestedForm === "diagnosis") return
        // if (!(nestData?.last_dno || nestData?.diagnosis?.dno)) return
        const validDnoValue =
            validNumber(nestData?.last_dno) ||
            validNumber(nestData?.current_diagnosis?.dno)

        setLastDno(validDnoValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nestData?.current_diagnosis?.dno, nestData?.last_dno])

    useEffect(() => {
        // if (!nestData?.form_data?.current_diagnosis?.pro_type) return
        //  if(!nestData?.diagnosis?.pro_type) return
        if (nestedDiagForm === "d_physical") return

        setProType(
            nestData?.form_data?.current_diagnosis?.pro_type ||
                nestData?.current_diagnosis?.pro_type
        )
        if (
            !(
                nestData?.form_data?.current_diagnosis?.dno ||
                nestData?.current_diagnosis?.dno
            )
        )
            return
        setDnoNumber(
            nestData?.form_data?.current_diagnosis?.dno ||
                nestData?.current_diagnosis?.dno
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        nestData?.form_data?.current_diagnosis?.pro_type,
        nestData?.current_diagnosis?.pro_type,
        nestData?.form_data?.current_diagnosis?.dno,
        nestData?.current_diagnosis?.dno,
    ])

    useEffect(() => {
        if (nestData?.patient_info) {
            setPatientDetails(nestData?.patient_info)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nestData?.patient_info])

    useEffect(() => {
        if (nestData?.current_amp?.date) {
            nestData.current_amp.date = moment(nestData?.current_amp?.date)
        }
        form.setFieldsValue(nestData?.current_amp)
    }, [nestData?.current_amp, form])

    const changeTab = (tab) => {
        setSelectedForm(tab)

        if (tab === "stump") {
            formData(`editor/stump/${id}/${ampSelected}`, undefined, "get")
        } else if (tab === "info") {
            formData(`/editor/${id}/amp/${ampSelected}/info`, undefined, "get")
        } else if (
            tab === "diagnosis" &&
            lastDno &&
            isNotManu(nestedDiagForm)
        ) {
            const nestDict = {
                d_stump: "stump",
                d_physical: "phy",
                d_parts: "parts",
            }
            formData(
                `/editor/dgns/${nestDict[nestedDiagForm]}/${id}/${ampSelected}/${lastDno}/`,
                undefined,
                "get"
            )
        } else if (
            tab === "diagnosis" &&
            lastDno &&
            !isNotManu(nestedDiagForm)
        ) {
            const url = constructNestApiUrl(
                nestedDiagForm,
                id,
                ampSelected,
                lastDno
            )
            formData(url, undefined, "get")
        }
    }

    const selectAmp = (target) => {
        setAmp(target.value)
        setSelectedForm("info")
        formData(`/editor/${id}/amp/${target.value}/info`, undefined, "get")
    }

    const renderAmputations = useCallback(() => {
        const amps = store.amps
        return amps?.map(({ no, pro_type_name, side_name }) => (
            <Radio.Button value={no}>
                {no} ({side_name} {pro_type_name})
            </Radio.Button>
        ))
    }, [store.amps])

    const handleSuccessSubmit = async () => {
        formData(
            `/editor/${id}/amp/${ampSelected}/${selectedNestedForm}`,
            undefined,
            "get"
        )
        setAmpModal(false)
        newForm.resetFields()
    }

    return (
        <Spin spinning={loading}>
            <ImagePreview />
            <Modal
                visible={ampModal}
                title="Add New Amputation"
                onCancel={() => setAmpModal(false)}
                footer={[
                    <Button
                        key="back"
                        onClick={() => {
                            setAmpModal(false)
                        }}
                    >
                        Cancel
                    </Button>,
                    <Button
                        onClick={newForm.submit}
                        type="primary"
                        loading={false}
                    >
                        Save
                    </Button>,
                ]}
            >
                <Form
                    form={newForm}
                    onFinish={(values) => {
                        if (reasonVal !== 98) {
                            values.other_reason = ""
                            newForm.setFields([
                                { name: ["other_reason"], value: "" },
                            ])
                        }
                        submitForm(values, "amp", handleSuccessSubmit)
                    }}
                    labelCol={{ span: 10 }}
                    labelAlign="left"
                >
                    {infoForm?.map((field) => {
                        if((field.onlyForIndia && !Access.isIDrequired(office))) return null
                        if (
                            field.name === "zoho_patient_record" &&
                            Access.isIDrequired(office)
                        ) {
                            field.rules = [
                                {
                                    required: true,
                                },
                            ]
                        }
                        return (
                            (field.name !== "other_reason" ||
                                reasonVal === 98) && (
                                <Form.Item
                                    key={field.name}
                                    {...field}
                                    valuePropName={
                                        field.type === "checkbox"
                                            ? "checked"
                                            : "value"
                                    }
                                >
                                    {FormRenderer(field.type, {
                                        ...field.props,
                                        // value: valueFormatter(field.type, data?.basic?.[field.name]),
                                        options: createOptions(
                                            nestData?.form_data?.[
                                                field?.optionKey
                                            ]
                                        ),
                                    })}
                                </Form.Item>
                            )
                        )
                    })}
                </Form>
            </Modal>
            <Space>
                <Radio.Group
                    value={ampSelected}
                    onChange={({ target }) => {
                        selectAmp(target)
                    }}
                >
                    {renderAmputations()}
                </Radio.Group>
                <Button
                    type="primary"
                    icon={<FileAddOutlined />}
                    size={"default"}
                    onClick={() => {
                        setAmpModal(true)
                        setSelectedForm("info")
                        formData(
                            `/editor/${id}/amp/${ampSelected}/info`,
                            undefined,
                            "get"
                        )
                    }}
                >
                    Add
                </Button>
            </Space>
            {!!ampSelected && (
                <Tabs
                    style={{ marginTop: 20 }}
                    type="card"
                    onChange={(tab) => {
                        changeTab(tab)
                        // setUrl(`/editor/stump/${id}/${ampSelected}/`)
                    }}
                    activeKey={selectedNestedForm || "info"}
                >
                    <TabPane key="info" tab="info">
                        {selectedNestedForm === "info" && (
                            <AmputationInfoTab
                                id={id}
                                form={form}
                                ampSelected={ampSelected}
                                formData={formData}
                                nestData={nestData}
                            />
                        )}
                    </TabPane>
                    <TabPane key="stump" tab="stump">
                        {selectedNestedForm === "stump" && (
                            <StumpInfoTab
                                id={id}
                                form={form}
                                ampSelected={ampSelected}
                                formData={formData}
                                nestData={nestData}
                            />
                        )}
                    </TabPane>
                    <TabPane key="diagnosis" tab="diagnosis">
                        {selectedNestedForm === "diagnosis" && (
                            <DiagnosisInfoTab
                                id={id}
                                form={form}
                                ampSelected={ampSelected}
                                formData={formData}
                                nestData={nestData}
                                selectedNestedForm={selectedNestedForm}
                                setSelectedForm={setSelectedForm}
                                lastDno={lastDno}
                                setLastDno={setLastDno}
                                proType={proType}
                                diagnosisNestedForm={nestedDiagForm}
                                setDiagnosisNestedForm={setDiagnosisNestedForm}
                                loading={loading}
                                dno={dno}
                            />
                        )}
                    </TabPane>
                </Tabs>
            )}
        </Spin>
    )
}

export default AmputationForm
