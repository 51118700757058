import {  Tabs } from 'antd'
import NotApproved from './ManufactureNotApproved'
import StumpRejected from './StumpRejected'

const {TabPane} = Tabs
function PoManager() {

  return (
    <Tabs  defaultActiveKey="1" onChange={() => { }}>
          <TabPane tab="Stump: Rejected" key="rejected">
           <StumpRejected />
          </TabPane>
          <TabPane tab="Manufacture: Not Approved" key="not-done">
            <NotApproved />
          </TabPane>
        </Tabs>
  )
}

export default PoManager
