import React, { useContext } from 'react'
import { Layout, Space, Button, Dropdown, Switch, Spin, Form, Typography } from 'antd'
import { DownOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { logo, greet } from '../layout/style'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { useLocation, useNavigate } from 'react-router'
import FormRenderer from '../pages/Form/formRenderer'
import GlobalContext from '../context/global'
import { SET_PATIENT_NAME } from '../context/constants'
import { PATIENTS } from '../layout/constants'
import { Link } from 'react-router-dom'

const { Header } = Layout
const HEADER = (props) => {
  const [isDarkMode, setIsDarkMode] = React.useState()
  const dispatch = useContext(GlobalContext.GlobalContextDispath)
  const { search } = useContext(GlobalContext.GlobalContextState)
  const { switcher, status, themes } = useThemeSwitcher()
  const navigate = useNavigate()
  const location = useLocation()

  const toggleTheme = (isChecked) => {
    setIsDarkMode(isChecked)
    switcher({ theme: isChecked ? themes.dark : themes.light })
  }

  if (status === 'loading') {
    return <Spin spinning={true} />
  }

  const persistName = (value) => {
    dispatch({
      type: SET_PATIENT_NAME,
      payload: { patientName: value },
    })
  }

  const searchPatient = () => {
    const patientToSearch = search.patientName
    navigate({
      pathname: '/patients',
      search: `?search=${patientToSearch}`,
    })
  }

  const shouldSeeSearch = () => {
    const patientPage = location.pathname.includes(PATIENTS.toLowerCase())

    if (!patientPage && props.loggedInUser) {
      return true
    }

    return false
  }

  return (
    <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div onClick={navigate.bind(null, '/overview')} className={logo}>
        Achilli
      </div>
      <div style={{ display: 'flex', gap: 5 }}>
        <Link to={"/contact"} component={Typography.Link} style={{ color: '#fff' }}>Facing Issues?</Link>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {shouldSeeSearch() && (
            <Form onFinish={searchPatient}>
              {FormRenderer('input', {
                placeholder: 'Search Patient',
                value: search.patientName,
                onChange: (e) => {
                  persistName(e.target.value)
                },
              })}
            </Form>
          )}
          {props.loggedInUser && props.menu && (
            <span className={greet}>
              <Dropdown overlay={props.menu}>
                <Button>
                  <Space>
                    <UserSwitchOutlined />
                    {props.loggedInUser}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </span>
          )}
          <Switch checked={isDarkMode} onChange={toggleTheme} />
        </div>
      </div>
    </Header>
  )
}

export default HEADER
