import { message } from 'antd'
import { useCallback, useContext, useEffect, useState } from 'react'
import { SET_ALL_AMPUTATION, SET_CURRENT_DIAGNOSIS } from '../../../context/constants'
import GlobalContext from '../../../context/global'
import { http } from '../../../network/axiosInstance'

function useNestedForm(url, method, body, skipSaving) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useContext(GlobalContext.GlobalContextDispath)

  const formData = useCallback(
    async (path, body, method, skipSaving) => {
      setLoading(true)

      const dataFromServer = await http[method](path, body)

      !skipSaving && setData(dataFromServer.data)
      if (dataFromServer?.data?.all_amputations) {
        dispatch({
          type: SET_ALL_AMPUTATION,
          payload: { all_amputations: dataFromServer?.data?.all_amputations },
        })
      }
     
      if(dataFromServer?.data?.form_data?.current_diagnosis) {
        dispatch({
          type: SET_CURRENT_DIAGNOSIS,
          payload: { current_diagnosis: dataFromServer?.data?.form_data?.current_diagnosis },
        })
      }

      setLoading(false)
      method === 'post' && message.info('Updated Successfully')
    },
    [dispatch]
  )

  useEffect(() => {
    formData(url, body, method, skipSaving)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body, formData, method, skipSaving, url])

  return { data, loading, formData }
}

export default useNestedForm
