import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
// import 'antd/dist/antd.min.css';
// import 'antd/dist/antd.dark.min.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from './pages/Error/ErrorBoundaries'
import ErrorHandler from './network/axiosInstance'
import './index.css'

import { ThemeSwitcherProvider } from "react-css-theme-switcher";

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
     <ThemeSwitcherProvider themeMap={themes} defaultTheme="light">
    <BrowserRouter>
      <ErrorHandler>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </ErrorHandler>
    </BrowserRouter>
    </ThemeSwitcherProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
