import { Button, Col, Form, Row } from 'antd'
import { useNavigate } from 'react-router'
import { createOptions } from '../../../utils/helpers'
import FormRenderer from '../formRenderer'
import { subscriptionForm } from '../forms'

const SubscriptionForm = ({ form, tab, data, submitForm }) => {
  const navigate = useNavigate()

  return (
    <Form
      form={form}
      initialValues={data?.[tab]}
      onFinish={(values) => submitForm(values, 'sub')}
      labelCol={{ span: 4 }}
      labelAlign='left'
      wrapperCol={{ span: 16 }}
    >
      {subscriptionForm?.map((field, idx) => (
        <Form.Item key={idx + field.name} {...field} valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}>
          {FormRenderer(field.type, {
            ...field.props,
            // value: valueFormatter(field.type, data?.sub?.[field.name]),
            options: createOptions(data?.form_data?.[field?.optionKey]),
          })}
        </Form.Item>
      ))}
      <Row gutter={6} justify='end'>
        <Col>
          <Button onClick={() => navigate('/patients')}>Back to Patients</Button>
        </Col>
        <Col>
          <Button htmlType='submit' type='primary'>
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default SubscriptionForm
