import moment from 'moment'
import { http } from '../../network/axiosInstance'
import { formatDateForBE } from '../../utils/helpers'

const getAnalytics = async (date) => {
  if (!date) date = formatDateForBE(moment().subtract(6, 'months'))

  const body = {
    from_date: date,
  }

  try {
    const { data } = await http.post('/editor/analytics/', body)
    return data
  } catch (error) {
    console.log('Error fetching graphs', error)
  }
}

const monthsDict = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const formulateData = function (
  dataSet,
  graphName,
  graphHeading,
  color = 'rgba(53, 162, 235, 0.5)'
) {
  const dataCount = []
  const months = []

  dataSet?.forEach((data) => {
    dataCount.push(data.total)
    months.push(monthsDict[data.month - 1])
  })

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: graphHeading,
      },
    },
    scales: {
        y: {
            // type: 'line',
            ticks: {
                precision:0,
                callback: (val, idx, allVal) => val
            }
        }
    }
  }

  const data = {
    labels: months,
    datasets: [
      {
        label: graphName,
        data: dataCount,
        backgroundColor: color,
      },
    ],
  }

  return { data, options }
}

const INIT_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Loading',
    },
  },
}

export const INIT_DATA = {
  options: INIT_OPTIONS,
  data: {
    labels: [],
    datasets: [
      {
        label: 'Loading',
        data: [],
        backgroundColor: '#fff',
      },
    ],
  },
}

export default getAnalytics
