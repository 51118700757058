import { Button, Col, Divider, Form, Row, Upload } from 'antd'
import React from 'react'
import {
  assessedBy,
  beforeUpload,
  filterFileType,
  formatHelper,
  infoHelper,
} from '../../../../utils/helpers'
import { UploadWrapper } from '../style'
import { UploadOutlined } from '@ant-design/icons'
import FormRenderer from '../../formRenderer'
import usePreview from '../components/ImagePreview/usePreview'

export default function Sense({
  nestData,
  showDownloadProps,
  previewImage,
  id,
  dno,
  saveImage,
  lastDno,
  formData,
  ampSelected,
  getOptions,
  diagnosisNestedForm,
  onProgress,
  setPercent,
  files,
  handleDiagNestSubmission,
  formOne,
  formTwo,
  triggerMultiDelete,
  setFiles,
}) {
  const { onDownload } = usePreview()

  return (
    <>
      <Divider orientation='center'>WITH SL</Divider>
      <Form
        form={formOne}
        onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '1')}
        labelCol={{ span: 10 }}
        labelAlign='left'
        wrapperCol={{ span: 10 }}
      >
        <Form.Item label='Upload' name={'sl'}>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              fileList={files?.sl}
              onProgress={onProgress}
              onFinish={() => setPercent(0)}
              beforeUpload={(file) => beforeUpload(file, 'sense', files?.sl, 1)}
              maxCount={1}
              accept={filterFileType('sense', 1)}
              multiple={false}
              onPreview={(file) => previewImage(file, 1)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 1, filename: file.name, url: file.url })
              }}
              customRequest={(options) =>
                saveImage(options, `patientdata/${id}/${lastDno}/sense/With SL/`, 1, () =>
                  formData(`/editor/manu/sense/${id}/${ampSelected}/${lastDno}/`, undefined, 'get')
                )
              }
              listType='picture'
              onRemove={(image) =>
                triggerMultiDelete(image, () =>
                  setFiles((prev) => ({
                    ...prev,
                    sl: files.sl?.filter((img) => img.uid !== image?.uid),
                  }))
                )
              }
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('sense', 1)}
            </Upload>
          </div>
          {infoHelper(
            nestData?.form_data?.file1?.upload_staff,
            nestData?.form_data?.file1?.upload_date
          )}
        </Form.Item>
        <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
          {FormRenderer('select', {
            options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
          })}
        </Form.Item>
        {assessedBy(
          nestData?.form_data?.file1?.approval_staff,
          nestData?.form_data?.file1?.approval_date
        )}
        <Form.Item label='Memo' name='memo'>
          {FormRenderer('textarea', { autoSize: true })}
        </Form.Item>
        <Row gutter={6} justify='end'>
          <Col>
            <Button disabled={!files?.sl?.length} htmlType='submit' type='primary'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider orientation='center'>NO SL</Divider>
      <Form
        form={formTwo}
        // initialValues={setInitialValues}
        onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '2')}
        labelCol={{ span: 10 }}
        labelAlign='left'
        wrapperCol={{ span: 10 }}
      >
        <Form.Item label='Upload' name={'sl'}>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              fileList={files?.no_sl}
              onProgress={onProgress}
              onFinish={() => setPercent(0)}
              beforeUpload={(file) => beforeUpload(file, 'sense', files?.no_sl, 2)}
              maxCount={1}
              accept={filterFileType('sense', 2)}
              multiple={false}
              onPreview={(file) => previewImage(file, 1)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 1, filename: file.name, url: file.url })
              }}
              customRequest={(options) =>
                saveImage(
                  options,
                  `patientdata/${id}/${lastDno}/sense/No SL/`,
                  1,
                  () =>
                    formData(
                      `/editor/manu/sense/${id}/${ampSelected}/${lastDno}/`,
                      undefined,
                      'get'
                    ),
                  2
                )
              }
              listType='picture'
              onRemove={(image) =>
                triggerMultiDelete(image, () =>
                  setFiles((prev) => ({
                    ...prev,
                    no_sl: files.no_sl?.filter((img) => img.uid !== image?.uid),
                  }))
                )
              }
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('sense', 2)}
            </Upload>
          </div>
          {infoHelper(
            nestData?.form_data?.file2?.upload_staff,
            nestData?.form_data?.file2?.upload_date
          )}
        </Form.Item>
        <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
          {FormRenderer('select', {
            options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
          })}
        </Form.Item>
        {assessedBy(
          nestData?.form_data?.file2?.approval_staff,
          nestData?.form_data?.file2?.approval_date
        )}
        <Form.Item label='Memo' name='memo'>
          {FormRenderer('textarea', { autoSize: true })}
        </Form.Item>
        <Row gutter={6} justify='end'>
          <Col>
            <Button disabled={!files?.no_sl?.length} htmlType='submit' type='primary'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider orientation='center'>CHECKSHEET</Divider>
      <Form labelCol={{ span: 10 }} labelAlign='left' wrapperCol={{ span: 10 }}>
        <Form.Item label='Upload' name={'sl'}>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              fileList={files?.check_sheet}
              onProgress={onProgress}
              onFinish={() => setPercent(0)}
              beforeUpload={(file) => beforeUpload(file, 'sense', files?.check_sheet, 3)}
              maxCount={1}
              accept={filterFileType('sense', 3)}
              multiple={false}
              onPreview={(file) => previewImage(file, 1)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 1, filename: file.name, url: file.url })
              }}
              customRequest={(options) =>
                saveImage(options, `patientdata/${id}/${lastDno}/sense/Check Sheet/`, 1, () =>
                  formData(`/editor/manu/sense/${id}/${ampSelected}/${lastDno}/`, undefined, 'get'),
                  3
                )
              }
              listType='picture'
              onRemove={(image) =>
                triggerMultiDelete(image, () =>
                  setFiles((prev) => ({
                    ...prev,
                    check_sheet: files.check_sheet?.filter((img) => img.uid !== image?.uid),
                  }))
                )
              }
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('sense', 3)}
            </Upload>
          </div>
          {infoHelper(
            nestData?.form_data?.file3?.upload_staff,
            nestData?.form_data?.file3?.upload_date
          )}
        </Form.Item>
      </Form>
    </>
  )
}
