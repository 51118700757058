import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Typography, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import FormRenderer from '../../../Form/formRenderer'
import { createFile, filterFileType, formImageList } from '../../../../utils/helpers'
import { fetchCuraId, readCuraFile, saveCuraProf, updateCuraProf } from '../Cura.network'
import { useNavigate, useParams } from 'react-router'

const { TextArea } = Input

const CuraAddView = () => {
  const [uploadedFile, setFile] = useState()
  const [uploading, setUploading] = useState(false)
  const [fileContent, setFileContent] = useState([])
  const params = useParams()
  const navigate = useNavigate()
  const isEdit = Boolean(params?.id)

  const fetchById = useCallback(async (id) => {
    const curaProfile = await fetchCuraId(id)
    setFileContent(curaProfile)
    setFile(formImageList([curaProfile?.file]))
  }, [])

  useEffect(() => {
    if (params?.id) fetchById(params?.id)
  }, [params?.id, fetchById])

  const sendFileToServer = async (options) => {
    const { onSuccess, file } = options
    setFile([file])

    const form = new FormData()
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    form.append('file', file)
    const readFile = await readCuraFile(form, config)
    setFileContent(readFile?.data)
    onSuccess('Ok')
  }

  const changeDetail = (target) => {
    setFileContent(prevContent => ({
      ...prevContent,
      detail: target.value
    }))
  }

  const saveCura = async () => {
    const form = new FormData()
    try {
      setUploading(true)
      let file = uploadedFile?.[0]
      if (isEdit) {
        file = await createFile(uploadedFile[0]?.url, uploadedFile[0]?.name)
      }
      form.append('file', file)
      form.append('name', fileContent.name)
      form.append('detail', fileContent.detail)
      form.append('general_1', fileContent.cura[0]?.general_1)
      form.append('metadata_1', fileContent.cura[0]?.metadata_1)
      form.append('values_1', fileContent.cura[0]?.values_1)
      form.append('general_2', fileContent.cura[1]?.general_2)
      form.append('metadata_2', fileContent.cura[1]?.metadata_2)
      form.append('values_2', fileContent.cura[1]?.values_2)
      form.append('general_3', fileContent.cura[2]?.general_3)
      form.append('metadata_3', fileContent.cura[2]?.metadata_3)
      form.append('values_3', fileContent.cura[2]?.values_3)

      const fn = isEdit ? updateCuraProf : saveCuraProf
      const isSaved = await fn(form, params?.id)
      if (isSaved.status === 200) {
        message.success('Profile Successfully Created')
        return navigate('/settings/cura')
      }
    } catch (error) {
      message.error('Something went wrong')
    } finally {
      setUploading(false)
    }
  }

  const isSavedDisabled = () => {
    return !Boolean(uploadedFile)
  }

  return (
    <>
      <Form labelCol={{ span: 5 }} labelAlign='left' wrapperCol={{ span: 16 }}>
        <Form.Item label='Name'>
          <Input value={fileContent?.name} placeholder='Name' />
        </Form.Item>
        <Form.Item label='Detail'>
          <TextArea value={fileContent?.detail} onChange={({ target }) => changeDetail(target)} placeholder='Detail' />
        </Form.Item>
        <Form.Item label='Select Cura Profile' name={'cura_profile'}>
          <Upload
            showUploadList={{
              showDownloadIcon: false,
              showPreviewIcon: false,
              showRemoveIcon: false,
            }}
            fileList={uploadedFile}
            accept={filterFileType('cura', 0)}
            // beforeUpload={(file) => beforeUpload(file, 'testsocket', files?.check_sheet, 3)}
            customRequest={(options) => sendFileToServer(options)}
          >
            <Button icon={<UploadOutlined />}>Cura File</Button>
          </Upload>
        </Form.Item>
      </Form>
      <Row gutter={10} style={{ margin: '2em 0' }}>
        <Col xs={7} push={2}>
          <Typography.Text strong>Global Quality</Typography.Text>
        </Col>
        <Col xs={7} push={2}>
          <Typography.Text strong>Extruder Quality</Typography.Text>
        </Col>
        <Col xs={7} push={2}>
          <Typography.Text strong>*</Typography.Text>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={2}>
          <Typography.Text strong>General</Typography.Text>
        </Col>
        <Col xs={7}>
          {FormRenderer('textarea', { disabled: true, value: fileContent?.cura?.[0]?.general_1, rows: 10 })}
        </Col>
        <Col xs={7}>
          {FormRenderer('textarea', { disabled: true, value: fileContent?.cura?.[1]?.general_2, rows: 10 })}
        </Col>
        <Col xs={7}>
          {FormRenderer('textarea', { disabled: true, value: fileContent?.cura?.[2]?.general_3, rows: 10 })}
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={2}>
          <Typography.Text strong>MetaData</Typography.Text>
        </Col>
        <Col xs={7}>
          {FormRenderer('textarea', { disabled: true, value: fileContent?.cura?.[0]?.metadata_1, rows: 10 })}
        </Col>
        <Col xs={7}>
          {FormRenderer('textarea', { disabled: true, value: fileContent?.cura?.[1]?.metadata_2, rows: 10 })}
        </Col>
        <Col xs={7}>
          {FormRenderer('textarea', { disabled: true, value: fileContent?.cura?.[2]?.metadata_3, rows: 10 })}
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={2}>
          <Typography.Text strong>Values</Typography.Text>
        </Col>
        <Col xs={7}>
          {FormRenderer('textarea', { disabled: true, value: fileContent?.cura?.[0]?.values_1, rows: 10 })}
        </Col>
        <Col xs={7}>
          {FormRenderer('textarea', { disabled: true, value: fileContent?.cura?.[1]?.values_2, rows: 10 })}
        </Col>
        <Col xs={7}>
          {FormRenderer('textarea', { disabled: true, value: fileContent?.cura?.[2]?.values_3, rows: 10 })}
        </Col>
        <Col xs={23} style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
          <Button>Cancel</Button>
          <Button loading={uploading} disabled={isSavedDisabled()} onClick={saveCura} type='primary'>Save</Button>
        </Col>
      </Row>
    </>
  )
}

export default CuraAddView
