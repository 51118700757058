import { Button, Form, Input, List, Modal, Typography, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Container, MsgTitle } from './style'
import GlobalContext from '../../context/global'
import { useNavigate } from 'react-router'
import { http } from '../../network/axiosInstance'
import { SET_PATIENT_INFO } from '../../context/constants'
import { useSearchParams } from 'react-router-dom'

const AddPatient = () => {
    const store = useContext(GlobalContext.GlobalContextState)
    const dispatch = useContext(GlobalContext.GlobalContextDispath)

    const [newPatientName, setPatientName] = useState('')
    const [newPatientAge, setPatientAge] = useState('')
    const [addPath, setAddPath] = useState({ path: '/editor/patient/new/', button: 'Add Patient' })
    const [savingPatient, setSavingPatient] = useState(false)
    const [existMessage, setExistMessage] = useState({})
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const id = params.get('id')
    const tab = params.get('tab')

    const patientModalShow = store?.patientInfo?.showModal

    useEffect(() => {
        setPatientName(store?.patientInfo?.patientName)
        setPatientAge(store?.patientInfo?.patientAge)
    }, [store?.patientInfo?.patientName, store?.patientInfo?.patientAge])

    useEffect(() => {
        if (id) {
            setAddPath(prev => ({
                path: `/editor/patient/${id}/`,
                button: 'Update Patient'
            }))
        }
    }, [id])

    const closeAndClear = () => {
        setPatientName('')
        setPatientAge('')
        setExistMessage({})
        dispatch({
            type: SET_PATIENT_INFO,
            payload: {
                patientName: '',
                patientAge: '',
                showModal: false
            }
        })
    }

    const addPatient = async () => {
        if (!newPatientName || !newPatientAge) return message.error('Enter Patient Name / Age')
        setSavingPatient(true)
        try {
            const req = await http.post(addPath.path, { patient_name: newPatientName, age: Number(newPatientAge) })
            if (req.status >= 200 && req.status <= 205) {
                if (Array.isArray(req.data.patients)) {
                    setExistMessage(req.data)
                    return setAddPath({
                        path: '/editor/patient/new/?duplication_check=True',
                        button: 'Yes, Create',
                    })
                }
                const accessKey = id ? 'patient' : 'patients'
                const activeTab = tab || 'sales'
                message.success(req.data?.message)
                navigate(`/form?tab=${activeTab}&id=${req.data?.[accessKey]?.pk}`)
                closeAndClear()
            }
        } catch (error) {
        } finally {
            setSavingPatient(false)
        }
    }

    return (
        <Modal
            visible={patientModalShow}
            title={id ? 'Update Patient' : 'Add New Patient'}
            onOk={() => { }}
            onCancel={closeAndClear}
            footer={[
                <Button key='back' onClick={closeAndClear}>
                    Cancel
                </Button>,
                <Button onClick={addPatient} type='primary' loading={savingPatient}>
                    {addPath.button}
                </Button>,
            ]}
        >
            <Form labelAlign='left' labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
                <Form.Item label='Patient Name'>
                    <Input value={newPatientName} onChange={({ target }) => setPatientName(target.value)} />
                </Form.Item>
                <Form.Item label='Patient Age'>
                    <Input type='number' value={newPatientAge} onChange={({ target }) => setPatientAge(target.value)} />
                </Form.Item>
            </Form>
            {Array.isArray(existMessage?.patients) && (
                <div className={Container}>
                    <List
                        itemLayout='horizontal'
                        dataSource={existMessage?.patients}
                        header={
                            <Typography.Title className={MsgTitle} style={{ fontSize: 13 }} align='center'>
                                {existMessage?.message}
                            </Typography.Title>
                        }
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={<Typography.Text>{item.patient_name}</Typography.Text>}
                                    description={item.patient_no}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            )}
        </Modal>
    )
}

export default AddPatient