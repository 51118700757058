import { boardDateFormatter } from "../../../utils/helpers"

const dataSource = [
  {
    key: '1',
    id: '112',
    name: 'Patient 1',
    number: 32,
    address: '10 Downing Street',
    phone: 9998787867,
    pwd: 126,
    action: 112,
  },
  {
    key: '2',
    id: 114,
    name: 'John',
    number: 42,
    address: '10 Downing Street',
    phone: 9998787867,
    pwd: 126,
    action: 114,
  },
]

const columns = [
  {
    title: 'Id',
    dataIndex: 'pk',
    key: 'pk',
  },
  {
    title: 'Number',
    dataIndex: 'patient_no',
    key: 'patient_no',
    search: true,
  },
  {
    title: 'Name',
    dataIndex: 'patient_name',
    key: 'patient_name',
    search: true,
  },
  {
    title: 'Registered Date',
    dataIndex: 'register_date',
    key: 'register_date',
    render:(val) => boardDateFormatter(val),
  },
  {
    title: 'Phone',
    dataIndex: 'phone_no',
    key: 'phone_no',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'PWD/SC',
    dataIndex: 'id_no',
    key: 'id_no',
  },
]

export const patientData = {
  dataSource,
  columns,
}
