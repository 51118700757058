import { Button, Col, Form, Input, Row, Table } from 'antd'
import { patientData } from './__mock__data/patients'
import { SearchOutlined, UserAddOutlined } from '@ant-design/icons'
import useTabViewData from '../../hooks/useView'
import { ACHILLI_OFFICE, ACHILLI_ROLE, SIZE_CHANGER } from '../../utils/constants'
import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import getColumnSearchProps from './filterView'
import Store from '../../utils/authHelper'
import GlobalContext from '../../context/global'
import { SET_PATIENT_INFO, SET_PATIENT_NAME } from '../../context/constants'
import AddPatient from './AddPatient'
import Access from '../../permission/accessHelper'

const { Column } = Table

function PatientsTab({ patientToSearch }) {
  const dispatch = useContext(GlobalContext.GlobalContextDispath)
  const [filterControl, setFilters] = useState({
    patient_name: patientToSearch || '',
  })

  const role = Store.getObjectFromLocal(ACHILLI_ROLE)
  const office = Store.getFromLocal(ACHILLI_OFFICE)

  const navigate = useNavigate()
  const { data, loading, totalPages, limit, setLimit, setPage, currentPage, tableData } =
    useTabViewData('', '/list/patient/', 'patients', 'post', filterControl)


  const renderColumns = (column) => {
    if (!Access.shouldShowPI(office) && column.title === 'Phone') return null
    if (!(office === '2' && role?.includes('po') && column.title === 'Phone'))
      return <Column
        {...(column.search &&
          getColumnSearchProps(
            column.title,
            column.key,
            handleUpdateFilter,
            getFilteredData,
            filterControl
          ))}
        render={column.key === 'done' ? (text) => String(text) : column.render}
        title={column.title}
        dataIndex={column.dataIndex}
        key={column.key}
      />

    return null
  }

  const handleUpdateFilter = useCallback(
    (field, value) => {
      setFilters((existingFilters) => ({
        ...existingFilters,
        [field]: value,
      }))
      if (field === 'patient_name') {
        dispatch({
          type: SET_PATIENT_NAME,
          payload: { patientName: value },
        })
      }
    },
    [setFilters, dispatch]
  )

  function getFilteredData(filters) {
    tableData('/list/patient/', 1, limit, filters || filterControl)
  }

  const submitForm = (e) => {
    if (e.keyCode === 13) {
      getFilteredData()
    }
  }

  return (
    <>
      <AddPatient />
      <Form style={{ width: '100%' }} onKeyUp={submitForm}>
        <Row style={{ margin: '10px 0' }} gutter={4}>
          <Col span={6}>
            <Input
              onChange={({ target }) => handleUpdateFilter('patient_no', target.value)}
              placeholder='No'
            />
          </Col>
          <Col span={6}>
            <Input
              value={patientToSearch || ''}
              onChange={({ target }) => handleUpdateFilter('patient_name', target.value)}
              placeholder='Name'
            />
          </Col>
          <Col span={1}>
            <Button
              onClick={() => getFilteredData()}
              type='primary'
              shape='circle'
              icon={<SearchOutlined />}
            />
          </Col>
          <Col push={8}>
            <Button
              onClick={() => {
                dispatch({
                  type: SET_PATIENT_INFO,
                  payload: {
                    patientName: '',
                    patientAge: '',
                    showModal: true
                  }
                })
              }}
              type='secondary'
              icon={<UserAddOutlined />}
            >
              New Patient
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        pagination={{
          total: totalPages,
          pageSize: limit,
          current: currentPage,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (_, b) => {
            setLimit(Number(b))
          },
          pageSizeOptions: SIZE_CHANGER,
        }}
        loading={loading}
        bordered
        dataSource={data}
        style={{ width: '100%' }}
      >
        {patientData?.columns?.map(
          (column) => {
            return renderColumns(column)
          }
        )}
        <Column
          title={'Action'}
          dataIndex={'action'}
          key={'action'}
          render={(_, rec) => (
            <>
              <Button
                onClick={() => navigate(`/form?tab=sales&id=${rec.pk}`)}
                primary
                shape='round'
              >
                Edit
              </Button>
            </>
          )}
        />
      </Table>
    </>
  )
}

export default PatientsTab
