import { Row, Col, Select, Table, Input, Button, Form } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import useTabViewData from '../../hooks/useView'
import { fileData } from './__mock__data/file'
import { SIZE_CHANGER } from '../../utils/constants'
import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { constructNestApiUrl, detectFilePath } from '../../utils/helpers'
import { renderOptions } from '../../utils/optionRenderer'
import { SET_PATIENT_NAME } from '../../context/constants'
import GlobalContext from '../../context/global'

const { Column } = Table

function FilesTab({ patientToSearch }) {
  const dispatch = useContext(GlobalContext.GlobalContextDispath)
  const navigate = useNavigate()
  const [filterControl, setFilters] = useState({
    patient_name: patientToSearch || '',
  })
  const { data, loading, totalPages, limit, setLimit, setPage, currentPage, filters, tableData } =
    useTabViewData('', '/list/file/', 'files', 'post', filterControl)

  const handleUpdateFilter = useCallback(
    (field, value) => {
      setFilters((existingFilters) => ({
        ...existingFilters,
        [field]: value,
      }))
      if (field === 'patient_name') {
        dispatch({
          type: SET_PATIENT_NAME,
          payload: { patientName: value },
        })
      }
    },
    [setFilters, dispatch]
  )

  const getFilteredData = () => tableData('/list/file/', 1, limit, filterControl)

  const submitForm = (e) => {
    if (e.keyCode === 13) {
      getFilteredData()
    }
  }

  return (
    <>
      <Form style={{ width: '100%' }} onKeyUp={submitForm}>
        <Row style={{ margin: '10px 0' }} gutter={4}>
          <Col span={6}>
            <Input
              onChange={({ target }) => handleUpdateFilter('patient_no', target.value)}
              placeholder='No'
            />
          </Col>
          <Col span={6}>
            <Input
              value={patientToSearch || ''}
              onChange={({ target }) => handleUpdateFilter('patient_name', target.value)}
              placeholder='Name'
            />
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('pro_type', value)}
              placeholder='Type'
              allowClear
            >
              {renderOptions(filters, 'pro_types')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(v) => handleUpdateFilter('kind', v)}
              placeholder='File Type'
              allowClear
              dropdownStyle={{ minWidth: 'max-content' }}
            >
              {renderOptions(filters, 'kind_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(v) => handleUpdateFilter('upload_staff', v)}
              placeholder='Upload Staff'
              allowClear
            >
              {renderOptions(filters, 'upload_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(v) => handleUpdateFilter('approval_staff', v)}
              placeholder='Approval Staff'
              allowClear
            >
              {renderOptions(filters, 'approval_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(v) => handleUpdateFilter('file_status', v)}
              placeholder='Status'
              allowClear
              dropdownStyle={{ minWidth: 'max-content' }}
            >
              {renderOptions(filters, 'file_status_list')}
            </Select>
          </Col>
          <Button
            onClick={getFilteredData}
            type='primary'
            shape='circle'
            icon={<SearchOutlined />}
          />
        </Row>
      </Form>
      <Table
        pagination={{
          total: totalPages,
          pageSize: limit,
          current: currentPage,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (_, b) => {
            setLimit(Number(b))
          },
          pageSizeOptions: SIZE_CHANGER,
        }}
        loading={loading}
        bordered
        dataSource={data}
        style={{ width: '100%' }}
      >
        {fileData?.columns?.map((column) => (
          <Column
            render={column.key === 'done' ? (text) => String(text) : column.render}
            title={column.title}
            dataIndex={column.dataIndex}
            key={column.key}
            {...(column.sorter && {
              sorter: true,
              sortDirections: ['descend']
            })}
          />
        ))}
        <Column
          title={'Action'}
          dataIndex={'action'}
          key={'action'}
          render={(_, rec) => (
            <>
              <Button
                onClick={() => {
                  const [nestedForm, typeValue] = detectFilePath(rec)
                  const url = constructNestApiUrl(nestedForm, rec.p, rec.amp_no, rec.dno)
                  navigate(`/form?tab=amp&id=${rec.p}`, {
                    state: {
                      tab: 'diagnosis',
                      nestedTab: nestedForm,
                      dno: rec.dno,
                      selectedAmp: rec.amp_no,
                      redirection: true,
                      url,
                      stopEffect: true,
                      typeValue,
                    },
                  })
                }}
                primary
                shape='round'
              >
                Edit
              </Button>
            </>
          )}
        />
      </Table>
    </>
  )
}

export default FilesTab
