import React from "react"
import { Button, message } from "antd"
import { col1, col2, container } from "./style"
import { useNavigate, useLocation } from 'react-router-dom'

const Error500 = () => {
    const location = useLocation();
    const { errorData}=location?.state;
    const navigate = useNavigate();
    const goToHome = () => {
        navigate('/overview')
    }
    if(!errorData) return message.error('Something Went Wrong')
    message.error(errorData?.message || errorData?.detail || 'Something Went Wrong, Check Console')
    return (
        <div style={{flexDirection:"column"}} className={container}>
            <div style={{ display: "table-row"}}>
                    <h1 className={col1} >500</h1>
                    <div className={col2} style={{paddingInlineEnd:"200px"}}>
                        <h2 style={{fontSize: "1.875rem",fontWeight: "bold"}}>Sorry!</h2>
                        <h2 style={{fontSize: "1.5rem",fontWeight: "normal"}}>Internal Server Error!</h2>
                    </div>
            </div>
            <Button style={{marginInlineEnd:"130px"}} onClick= {goToHome}>Back to Home</Button>
        </div>

    )
}

export default Error500;