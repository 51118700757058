import {SALES, PO_MANAGER, PO_STUMP, PO_MANUFACTURE, PRODUCT_MANAGER, PRODUCT_MANUFACTURE} from '../layout/constants'
import { ADD_STAFF, EDIT_STAFF, ADMIN_PANEL, DOWNLOAD_ALL } from '../utils/constants'
export const PERMISSION_LIST = {
[SALES]: ['admin', 'sales'],
[PO_MANAGER]: ['admin', 'po_mng'],
[PO_STUMP]: ['admin', 'po'],
[PO_MANUFACTURE]: ['admin', 'po'],
[PRODUCT_MANAGER]: ['admin', 'product_mng'],
[PRODUCT_MANUFACTURE]: ['admin', 'product'],
[EDIT_STAFF]: ['admin'],
[ADD_STAFF]: ['admin'],
[ADMIN_PANEL]: ['admin'],
[DOWNLOAD_ALL]: ['admin'],
}
