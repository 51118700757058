import React from 'react'
import { Button, Form, Input, message } from 'antd'
import { container, form } from './loginStyle'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { http } from '../../network/axiosInstance'

export default function ResetPassword() {
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const onFinish = async (fields) => {
    const hideMessage = message.loading(`Resetting password...`, 0)
    const resetToken = params.get("token")
    const uid = params.get("uid")
    try {
      const req = await http.post(
        `/resetPassword/${uid}/${resetToken}/`,
        fields
      )
      if (req.status === 200) {
        message.success(req.data.message)
        navigate("/login")
      }

      throw Error(req)
    } catch (error) {
      console.log(error)
    } finally {
      hideMessage()
    }
  }

  return (
    <div className={container}>
      {
        <div className={form}>
          <Form
            name="basic"
            labelAlign="left"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: "Please enter confirm password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 9,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Proceed
              </Button>
            </Form.Item>
          </Form>
        </div>
      }
    </div>
  )
}
