import { Tabs } from 'antd'
import NotUploaded from './NotUploaded'
import NotOkay from './NotOkay'

const { TabPane } = Tabs

function PoManufacture() {
  return (
    <Tabs  defaultActiveKey='1' onChange={() => {}}>
      <TabPane tab='PO Manufacture: Not Uploaded' key='not-uploaded'>
        <NotUploaded />
      </TabPane>
      <TabPane tab='PO Manufacture: Not Okay' key='not-okay'>
        <NotOkay />
      </TabPane>
    </Tabs>
  )
}

export default PoManufacture
