import { Alert, Button, Col, Form, message, Row, Upload } from 'antd'
import React, { useEffect } from 'react'
import { createOptions } from '../../../utils/helpers'
import FormRenderer from '../formRenderer'
import { gaitanalysisForm } from '../forms'
import { UploadOutlined } from '@ant-design/icons'
import { http } from '../../../network/axiosInstance'
import { useNavigate } from 'react-router'

const GaitAnalysis = ({ form, tab, data, submitForm }) => {
    useEffect(() => {
        return () => {
            form.resetFields()
        }
    })
    const navigate = useNavigate()
    const disabled =
        data?.gait_analysis?.task_id &&
        (data?.gait_analysis?.status === 'TASK_STATUS_QUEUED' ||
            data?.gait_analysis?.status === 'TASK_STATUS_FINISHED')
    const queued = data?.gait_analysis?.task_id && data?.gait_analysis?.status === 'TASK_STATUS_QUEUED'
    const taskFinished =
        data?.gait_analysis?.task_id && data?.gait_analysis?.status === 'TASK_STATUS_FINISHED'
    const gaitId = data?.gait_analysis?.id

    const filterListFormatted = {}
    const kptsListFormatted = {}
    Object.values(data?.form_data?.filter_list || {})?.forEach((val) => {
        filterListFormatted[val] = val
    })
    Object.values(data?.form_data?.kpts_list || {})?.forEach((val) => {
        kptsListFormatted[val] = val
    })

    data = { ...data, form_data: { filter_list: filterListFormatted, kpts_list: kptsListFormatted } }

    const sendForm = (values) => {
        const form = new FormData()
        for (let keys in values) {
            if (keys === 'file') form.append(keys, values[keys]?.file?.originFileObj)
            else form.append(keys, values[keys])
        }

        submitForm(tab, form, 'post')
    }

    const mockRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const deleteAnalysis = async () => {
        const deleted = await http.post(`/ai/gaitanalysis/${gaitId}/delete`)
        if (deleted.status === 200) {
            message.success('Analysis Deleted')
            submitForm(`gaitanalysis`, undefined, 'get')
        }
    }
    return (
        <>
            {queued && (
                <Alert
                    message='QUEUED'
                    description='Please wait for 10 - 15 minutes, this has been queued for computation'
                    type='error'
                    style={{ marginBottom: 10 }}
                />
            )}
            {taskFinished && (
                <Alert
                    message='Computation Done'
                    description={
                        <span>
                            Computation is done, visit
                            <a
                                style={{ margin: '0 5px' }}
                                href={`http://52.194.139.193:3000/dataview/${data?.gait_analysis?.task_id}`}
                                target="_blank" rel="noreferrer"
                            >
                                This Link
                            </a>
                            for details
                        </span>
                    }
                    type='success'
                    style={{ marginBottom: 10 }}
                />
            )}
            <Form
                form={form}
                initialValues={data?.[tab]}
                onFinish={(values) => sendForm(values)}
                labelCol={{ span: 4 }}
                labelAlign='left'
                wrapperCol={{ span: 16 }}
            >
                <Form.Item name='file' label='File'>
                    <Upload
                        showUploadList={true}
                        accept={['.mp4']}
                        multiple={false}
                        customRequest={mockRequest}
                        disabled={disabled}
                    >
                        <Button disabled={disabled} icon={<UploadOutlined />}>
                            Upload
                        </Button>
                    </Upload>
                </Form.Item>
                {gaitanalysisForm?.map((field, idx) => (
                    <Form.Item
                        key={idx + field.name}
                        {...field}
                        valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}
                    >
                        {FormRenderer(field.type, {
                            ...field.props,
                            options: createOptions(data?.form_data?.[field?.optionKey]),
                            disabled: disabled,
                        })}
                    </Form.Item>
                ))}
                <Row gutter={6} justify='end'>
                    {gaitId && (
                        <Col>
                            <Button disabled={false} onClick={deleteAnalysis}>
                                Delete Analysis
                            </Button>
                        </Col>
                    )}
                    <Col>
                        <Button onClick={() => navigate('/patients')}>Back to Patients</Button>
                    </Col>
                    <Col>
                        <Button disabled={disabled} htmlType='submit' type='primary'>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default GaitAnalysis
