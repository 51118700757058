import React from "react"
import { Button } from "antd"
import { col1, col2, container } from "./style"
import { useNavigate } from 'react-router-dom'

const Notfound = () => {
    const navigate = useNavigate();
    const goToHome = () => {
        navigate('/overview')
    }
    return (
        <div style={{flexDirection:"column"}} className={container}>
            <div style={{ display: "table-row"}}>
                    <h1 className={col1} >404</h1>
                    <div className={col2}>
                        <h2 style={{fontSize: "1.875rem",fontWeight: "bold"}}>Sorry!</h2>
                        <h2 style={{fontSize: "1.5rem",fontWeight: "normal"}}>The page you're looking for was not found!</h2>
                    </div>
            </div>
            <Button style={{marginInlineEnd:"130px"}} onClick= {goToHome}>Back to Home</Button>
        </div>

    )
}

export default Notfound;