import React from 'react'
import Access from '../../permission/accessHelper'
import { Button, Checkbox, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import ColumnGroup from 'antd/lib/table/ColumnGroup'
import useTabViewData from '../../hooks/useView'
import { SIZE_CHANGER,EDIT_STAFF, ACHILLI_ROLE, ADD_STAFF } from '../../utils/constants'
import Store from '../../utils/authHelper'
import { UserAddOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'

const Staff = () => {
  const { data, loading, totalPages, limit, setLimit, setPage, currentPage } = useTabViewData(
    'staff',
    '/list/',
    '',
    'get'
  )
  const role = Store.getObjectFromLocal(ACHILLI_ROLE)
  const navigate = useNavigate()
  return (
    <>
    {Access.hasAccess(role,ADD_STAFF)&&<Button onClick={() =>  window.location.href = `staff/register`} type='secondary' icon={<UserAddOutlined />} style={{marginBlockEnd:'15px'}} >
            Add Staff
          </Button>}
    <Table
      pagination={{
        total: totalPages,
        pageSize: limit,
        current: currentPage,
        onChange: (page) => setPage(page),
        showSizeChanger: true,
        onShowSizeChange: (_, b) => {
          setLimit(Number(b))
        },
        pageSizeOptions: SIZE_CHANGER,
      }}
      loading={loading}
      bordered
      dataSource={data}
    >
      <Column title='NickName' dataIndex='nickname' key='nickname' />
      <Column title='Valid' dataIndex='is_valid' key='is_valid' render={(text) => <Checkbox checked={text} />} />
      <Column title='Email' dataIndex='email' key='email' />
      <Column title='Slack Id' dataIndex='slackid' key='slackid' />

      <ColumnGroup title='Role'>
        <Column title='Sales' dataIndex='is_sales' key='is_sales' render={(text) => <Checkbox checked={text} />} />
        <Column title='PO' dataIndex='is_po' key='is_po' render={(text) => <Checkbox checked={text} />} />
        <Column
          title='PO Manager'
          dataIndex='is_po_mng'
          key='is_po_mng'
          render={(text) => <Checkbox checked={text} />}
        />
        <Column
          title='Product'
          dataIndex='is_product'
          key='is_product'
          render={(text) => <Checkbox checked={text} />}
        />
        <Column
          title='Product Manager'
          dataIndex='is_product_mng'
          key='is_product_mng'
          render={(text) => <Checkbox checked={text} />}
        />
        <Column
          title='Reception'
          dataIndex='is_reception'
          key='is_reception'
          render={(text) => <Checkbox checked={text} />}
        />
        <Column title='Admin' dataIndex='is_admin' key='is_admin' render={(text) => <Checkbox checked={text} />} />
      </ColumnGroup>
      {Access.hasAccess(role,EDIT_STAFF) && <Column
        title='Action'
        key='action'
        render={(_, rec) => (
          <Button onClick={() =>  navigate(`edit?id=${rec.id}`)} style={{marginRight: 5}} primary shape='round'>Edit</Button>
        )}
      />}
       
    </Table>
    </>
  )
}

export default Staff
