import { Tabs } from 'antd'
import GCodeNotUploaded from './NotUploaded'

const { TabPane } = Tabs

function ProductManager() {
  return (
    <Tabs defaultActiveKey='1' onChange={() => {}}>
      <TabPane tab='Prouct Manager Manufacture: G Code Not Approved' key='g-not-approved'>
        <GCodeNotUploaded />
      </TabPane>
    </Tabs>
  )
}

export default ProductManager
