import { Button, Col, Divider, Form, Row } from 'antd'
import { useNavigate } from 'react-router'
import { createOptions } from '../../../utils/helpers'
import FormRenderer from '../formRenderer'
import { basicForm, contactPersonForm } from '../forms'
import Access from '../../../permission/accessHelper'
import Store from '../../../utils/authHelper'
import { ACHILLI_OFFICE } from '../../../utils/constants'

const BasicForm = ({ form, tab, data, submitForm }) => {
  const navigate = useNavigate()

  const office = Store.getFromLocal(ACHILLI_OFFICE)

  const shouldRenderField = (field, idx) => {
    if (!Access.shouldShowPI(office) && field.name === 'phone_no') return null

    if(field.name === 'id_no' && Access.isIDrequired(office)){
      field.rules = [{
        required: true,
      }]
    }
    if(field.name === 'id_type' && Access.isIDrequired(office)){
      field.props = {...field.props, defaultValue: 3}
      field.rules = [{
        required: true,
      }]
    }

    return <Form.Item key={idx + field.name} {...field} valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}>
      {FormRenderer(field.type, {
        ...field.props,
        // value: valueFormatter(
        //   field.type,
        //   data?.basic?.[field.name],
        //   field.name,
        //   data?.basic
        // ),
        options: createOptions(data?.form_data?.[field?.optionKey]),
      })}
    </Form.Item>
  }
  return (
    <Form
      form={form}
      onFinish={(values) => submitForm(values, 'basic')}
      initialValues={data?.[tab]}
      labelCol={{ span: 4 }}
      labelAlign='left'
      wrapperCol={{ span: 16 }}
    >
      {basicForm?.map((field, idx) => {
        return shouldRenderField(field, idx)
      })}
      <Divider orientation='left'>Contact Person</Divider>
      {contactPersonForm?.map((field, idx) => (
        <Form.Item key={idx + field.name} {...field}>
          {FormRenderer(field.type, {
            ...field.props,
            // value: valueFormatter(field.type, data?.basic?.[field.name]),
            options: createOptions(data?.form_data?.[field?.optionKey]),
          })}
        </Form.Item>
      ))}
      <Row gutter={6} justify='end'>
        <Col>
          <Button onClick={() => navigate('/patients')}>Back to Patients</Button>
        </Col>
        <Col>
          <Button htmlType='submit' type='primary'>
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default BasicForm
