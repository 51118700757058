import { Row, Col, Input, Button, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import ColumnGroup from 'antd/lib/table/ColumnGroup'
import { useNavigate } from 'react-router'
import useTabViewData from '../../../../hooks/useView'
import { SIZE_CHANGER } from '../../../../utils/constants'
import { boardDateFormatter } from '../../../../utils/helpers'
import { useCallback, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'

function StumpNotDone() {
  const [filterControl, setFilters] = useState({})
  const { data, loading, totalPages, limit, setLimit, setPage, currentPage, tableData } = useTabViewData(
    '',
    '/sales/',
    'sales-stump-not-done',
    'post',
    filterControl
  )
  const navigate = useNavigate()

  const handleUpdateFilter = useCallback(
    (field, value) => {
      setFilters((existingFilters) => ({
        ...existingFilters,
        [field]: value,
      }))
    },
    [setFilters]
  )

  const getFilteredData = () => tableData('/sales/', 1, limit, filterControl)
  return (
    <>
      <Row style={{ margin: '10px 0' }} gutter={4}>
        <Col span={6}>
          <Input
            onChange={({ target }) => handleUpdateFilter('patient_name', target.value)}
            placeholder='Name'
            allowClear
          />
        </Col>
        <Col span={6}>
          <Input
            onChange={({ target }) => handleUpdateFilter('patient_no', target.value)}
            placeholder='No'
            allowClear
          />
        </Col>
        <Button onClick={getFilteredData} type='primary' shape='circle' icon={<SearchOutlined />} />
      </Row>
      <Table
        pagination={{
          total: totalPages,
          pageSize: limit,
          current: currentPage,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (_, b) => {
            setLimit(Number(b))
          },
          pageSizeOptions: SIZE_CHANGER,
        }}
        loading={loading}
        bordered
        dataSource={data}
      >
        <Column title='Id' dataIndex='id' key='id' />
        <Column title='Patient Name' dataIndex='patient_name' key='patient_name' />
        <Column title='Patient No' dataIndex='patient_no' key='patient_no' />
        <Column title='Type' dataIndex='pro_type_name' key='pro_type_name' />
        <Column title='Upload Staff' dataIndex='upload_staff' key='upload_staff' />

        <ColumnGroup title='Upload Date'>
          <Column
            title='Stump'
            dataIndex='upload_stump_date'
            key='upload_stump_date'
            render={(val) => boardDateFormatter(val)}
          />
          <Column
            title='Linear'
            dataIndex='upload_liner_date'
            key='upload_liner_date'
            render={(val) => boardDateFormatter(val)}
          />
          <Column
            title='Video'
            dataIndex='upload_video_date'
            key='upload_video_date'
            render={(val) => boardDateFormatter(val)}
          />
        </ColumnGroup>

        <Column title='Status' dataIndex='status_po' key='status_po' />
        <Column title='PO' dataIndex='approval_po_staff' key='approval_po_staff' />
        <Column title='Done' dataIndex='done' key='done' render={(val) => String(val)} />

        <Column
          title='Action'
          key='action'
          render={(_, rec) => (
            <Button
              onClick={() =>
                navigate(`/form?tab=amp&id=${rec.p}`, {
                  state: {
                    tab: 'stump',
                    url: `/editor/stump/${rec.p}/${rec.amp_no}/`,
                    selectedAmp: rec.amp_no,
                    redirection: true,
                  },
                })
              }
              primary
              shape='round'
            >
              Edit
            </Button>
          )}
        />
      </Table>
    </>
  )
}

export default StumpNotDone
