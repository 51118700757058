import { Col, Row, Statistic, Layout, Card, Spin } from 'antd'
import React from 'react'
import useTabViewData from '../../hooks/useView'
import { Spinner } from './style'

const { Content } = Layout

export default function OverView() {
    const { data, loading } = useTabViewData(
    '','/overview', '', 'get'
  )

  const {data: overViewData} = data
  const givenSet = overViewData?.[0] || {}

  const renderCards = () => {
    return Object.keys(givenSet)?.map((info, idx) => (
      <Col key={idx +'card'} span={6}>
        <Card style={{ minHeight: 135 }}>
          <Statistic title={givenSet[info].label} value={givenSet[info].count} />
        </Card>
      </Col>
    ))
  }

  return (
    <Spin wrapperClassName={Spinner} spinning={loading}>
    <Content style={{ padding: 10 }}>
      <Row align='middle' gutter={[12, 12]} justify='center'>
        {renderCards()}
      </Row>
    </Content>
    </Spin>
  )
}
