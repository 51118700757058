import { http } from '../../../network/axiosInstance'

export async function getCuraList() {
    try {
        const curaList = await http.get('/cura/list')
        return curaList?.data
    } catch (error) { }
}

export async function deleteCuraProfile(curaId) {
    try {
        const cura = await http.post(`/cura/delete/${curaId}/`)
        return cura.status === 200
    } catch (error) {
        return false
    }
}

export async function readCuraFile(formData, config) {
    try {
        const cura = await http.post(`/cura/upload/`, formData, config)
        return cura
    } catch (error) {
        return false
    }
}

export async function fetchCuraId(id) {
    try {
        const cura = await http.get(`/cura/get/${id}`)
        const {
            general_1,
            general_2,
            general_3,
            metadata_1,
            metadata_2,
            metadata_3,
            values_1,
            values_2,
            values_3,
            detail,
            name,
            s3_cura_file,
        } = cura?.data?.cura
        const formattedData = [
            {
                general_1,
                metadata_1,
                values_1,
            },
            {
                general_2,
                metadata_2,
                values_2,
            },
            {
                general_3,
                metadata_3,
                values_3,
            },
        ]
        const data = {
            cura: formattedData,
            name,
            detail,
            file: s3_cura_file?.[0],
        }

        return data
    } catch (error) {
        return false
    }
}

export async function saveCuraProf(formData) {
    try {
        const cura = await http.post(`/cura/add/`, formData)
        return cura
    } catch (error) {
        return false
    }
}

export async function updateCuraProf(formData, id) {
    try {
        const cura = await http.post(`/cura/update/${id}/`, formData)
        return cura
    } catch (error) {
        return false
    }
}