import { Button, Form, Input, Layout, message, Row } from 'antd'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { http } from '../../network/axiosInstance'

const { Content } = Layout

const ChangePassword = () => {
  const navigate = useNavigate()

  const changePasswordHandler = useCallback(
    async (values) => {
      const req = await http.post('/password/change/', values)
      if (req?.status === 200) {
        message.success(req.data?.message)
        navigate('/login')
      }
    },
    [navigate]
  )

  return (
    <Layout>
      <Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
          width: '80%',
        }}
      >
        <Form
          name='basic'
          labelCol={{ span: 6 }}
          labelAlign='left'
          // wrapperCol={{ span: 12 }}
          onFinish={changePasswordHandler}
          autoComplete='off'
        >
          <Form.Item
            label='Current Password'
            name='current_password'
            rules={[{ required: true, message: 'Please input current password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='New Password'
            name='new_password'
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='confirm_password'
            dependencies={['new_password']}
            hasFeedback
            rules={[{ required: true, message: 'Please confirm your new password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }
  
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
          >
            <Input.Password />
          </Form.Item>
          <Row justify='end'>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Row>
        </Form>
      </Content>
    </Layout>
  )
}

export default ChangePassword
