import { Button, Col, Form, Row } from 'antd'
import { useNavigate } from 'react-router'
import { createOptions, formatDateForBE } from '../../../utils/helpers'
import FormRenderer from '../formRenderer'
import { infoForm } from '../forms'
import Store from '../../../utils/authHelper'
import { ACHILLI_OFFICE } from '../../../utils/constants'
import Access from '../../../permission/accessHelper'

const AmputationInfoTab = ({ form, ampSelected, formData, nestData, id }) => {
  const navigate = useNavigate()
  const reasonVal = Form.useWatch('reason', form)
  const office = Store.getFromLocal(ACHILLI_OFFICE)
  // console.log(nestData?.form_data?.['reason_list'].Others)
  return (
    <Form
      form={form}
      onFinish={(values) => {
        if(reasonVal!==98)
        {
          values.other_reason=''
          form.setFields([{name:["other_reason"], value: '' }])
        }
        const body = { ...values }
        body.date = formatDateForBE(body.date)
        formData(`/editor/${id}/amp/${ampSelected}/update/`, body, 'post', true)
      }}
      initialValues={nestData?.current_amp}
      labelCol={{ span: 4 }}
      labelAlign='left'
      wrapperCol={{ span: 16 }}
    >
      {infoForm?.map((field) => {
        if((field.onlyForIndia && !Access.isIDrequired(office))) return null
        
        if(field.name === 'zoho_patient_record' && Access.isIDrequired(office)){
          field.rules = [{
            required: true,
          }]
        }

        return ((field.name!=='other_reason')||( reasonVal === 98 ))
        &&<Form.Item key={field.name} {...field} valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}>
          {FormRenderer(field.type, {
            ...field.props,
            // value: valueFormatter(field.type, data?.basic?.[field.name]),
            options: createOptions(nestData?.form_data?.[field?.optionKey]),
          })}
        </Form.Item>
})}
      <Row gutter={6} justify='end'>
        <Col>
          <Button onClick={() => navigate('/patients')}>Back to Patients</Button>
        </Col>
        <Col>
          <Button htmlType='submit' type='primary'>
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default AmputationInfoTab
