import { Row, Col, Select, Table, Input, Button, Form, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import useTabViewData from '../../hooks/useView'
import { ACHILLI_ROLE, DOWNLOAD_ALL, SIZE_CHANGER } from '../../utils/constants'
import { useCallback, useContext, useState } from 'react'
import { renderOptions } from '../../utils/optionRenderer'
import { SET_PATIENT_NAME } from '../../context/constants'
import GlobalContext from '../../context/global'
import { downloadFileData } from './__mock__data/download'
import moment from 'moment'
import { DatePicker } from 'antd'
import { http } from '../../network/axiosInstance'
import JSZip from 'jszip';
import Access from '../../permission/accessHelper'
import Store from '../../utils/authHelper'

const { Column } = Table
const { RangePicker } = DatePicker

function DownloadTab({ patientToSearch }) {
  const dispatch = useContext(GlobalContext.GlobalContextDispath)
  const role = Store.getObjectFromLocal(ACHILLI_ROLE)
  const [filterControl, setFilters] = useState({
    patient_name: patientToSearch || '',
  })
  const [isLoading, setLoading] = useState(false)
  const { data, loading, totalPages, limit, setLimit, setPage, currentPage, filters, tableData } =
    useTabViewData('', '/list/download-files/', 'files', 'post', filterControl)

  const handleUpdateFilter = useCallback(
    (field, value) => {
      setFilters((existingFilters) => ({
        ...existingFilters,
        [field]: value,
      }))
      if (field === 'patient_name') {
        dispatch({
          type: SET_PATIENT_NAME,
          payload: { patientName: value },
        })
      }
    },
    [setFilters, dispatch]
  )

  const dateRangeSelector = useCallback(
    (field, value) => {
      const [startDate, endDate] = value
      const dateRangeString = `${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format(
        'YYYY-MM-DD'
      )}`
      setFilters((existingFilters) => ({
        ...existingFilters,
        [field]: dateRangeString,
      }))
    },
    [setFilters]
  )

  const getFilteredData = () => tableData('/list/download-files/', 1, limit, filterControl)

  const submitForm = (e) => {
    if (e.keyCode === 13) {
      getFilteredData()
    }
  }

  const createZip = async (s3_files_list, zipFileName, isMultiDownload) => {
    const zip = new JSZip()

    for (var i = 0; i < s3_files_list?.length; i++) {
      const response = await fetch(s3_files_list[i]['object_url'])
      const blob = response.blob()
      const key_split = s3_files_list[i]['key'].split('/')
      const slice_count = isMultiDownload ? key_split[0].length + 1 : key_split[0].length + key_split[1].length + 2
      zip.file(s3_files_list[i]['key'].slice(slice_count), blob)
    }

    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(content);
        link.download = `${zipFileName}.zip`;
        link.click();
        setLoading(false)
      });
  }

  const downloadAll = async () => {
    const values = filterControl
    setLoading(true)
    try {
      message.info(<div>
        Your request has been recieved.
        <br />
        Downloading will take a while, Please do not close or refresh the tab!
      </div>,
        10)
      const req = await http.post(`/editor/list/download-multi-diagnosis/?page=${currentPage}&limit=${limit}`, values, { responseType: 'arraybuffer' })
      if (req?.status === 200) {
        message.destroy()
        const today = moment().format("DD-MM-YYYY")
        const blob = new Blob([req.data], { type: 'application/zip' })
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${today}.zip`;
        link.click();
        setLoading(false)
      }
      else {
        setLoading(false)
      }
    } catch (err) {
      console.log('Error: ', err)
      message.error('Something went wrong!')
    }
  }

  const downloadSingle = async (rec) => {
    const values = { "pid": rec.p, "dno": rec.dno, "kind": rec.kind }
    setLoading(true)
    const req = await http.post('/editor/list/download-single-diagnosis/', values)
    if (req.status === 200) {
      const s3_files_list = req?.data['s3_files_list']
      createZip(s3_files_list, `${rec.patient_no}_${rec.patient_name}`, false)
      // setLoading(false)
    }
  }

  return (
    <>
      <Form onKeyUp={submitForm}>
        <Row style={{ margin: '10px 0' }} justify='space-between' >
          <Col span={6}>
            <Input
              onChange={({ target }) => handleUpdateFilter('patient_no', target.value)}
              placeholder='No'
            />
          </Col>
          <Col span={6}>
            <Input
              value={patientToSearch || ''}
              onChange={({ target }) => handleUpdateFilter('patient_name', target.value)}
              placeholder='Name'
            />
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('proinfo_name', value)}
              placeholder='Type'
              allowClear
            >
              {renderOptions(filters, 'pro_info_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(v) => handleUpdateFilter('kind', v)}
              placeholder='File Type'
              allowClear
              dropdownStyle={{ minWidth: 'max-content' }}
            >
              {renderOptions(filters, 'kind_list')}
            </Select>
          </Col>
          <Col >
            <RangePicker
              allowClear={false}
              onChange={(val) => dateRangeSelector('search_date_register', val)}
              placeholder={['Register Start Date', 'Register End Date']}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment(), moment().endOf('month')],
                'Last Month': [
                  moment().subtract(1, 'month').startOf('month'),
                  moment().subtract(1, 'month').endOf('month'),
                ],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={getFilteredData}
              type='primary'
              shape='circle'
              icon={<SearchOutlined />}
            /></Col>
          {Access.hasAccess(role, DOWNLOAD_ALL) && <Button
            onClick={downloadAll}
            primary
            shape='round'
          >
            Download ALL
          </Button>}
        </Row>
      </Form>
      <Table
        style={{ width: '100%' }}
        pagination={{
          total: totalPages,
          pageSize: limit,
          current: currentPage,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (_, b) => {
            setLimit(Number(b))
          },
          pageSizeOptions: SIZE_CHANGER,
        }}
        loading={loading || isLoading}
        bordered
        dataSource={data}
      >
        {downloadFileData?.columns?.map((column) => (
          <Column
            title={column.title}
            dataIndex={column.dataIndex}
            key={column.key}
            {...(column.sorter && {
              sorter: true,
              sortDirections: ['descend']
            })}
          />
        ))}
        <Column
          title={'Action'}
          dataIndex={'action'}
          key={'action'}
          render={(_, rec) => (
            <>
              <Button
                onClick={() => downloadSingle(rec)}
                primary
                shape='round'
              >
                Download
              </Button>
            </>
          )}
        />
      </Table>
    </>
  )
}

export default DownloadTab
