import { Button, Col, Divider, Form, Input, Row, Select } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { createOptions } from '../../../../utils/helpers'
import FormRenderer from '../../formRenderer'

const { Option } = Select

const Parts = ({ nestData, handleDiagNestSubmission, diagnosisNestedForm, form }) => {
  const navigate = useNavigate()

  const [models, setModels] = useState({ models: {}, selectedModel: '' })
  const [socketList, setSocketList] = useState({
    selectedType: '',
    types: [],
    id: '',
    colors: [],
  })

  const [interfaceList, setInterfaceList] = useState({
    selectedInterface: '',
    selectedBrand: '',
    selectedColor: '',
    selectedSize: '',
    selectedCounterPart: '',
    types: [],
    brands: [],
    sizes: [],
    counterParts: [],
    colors: [],
  })

  const [footList, setFootList] = useState({
    selectedType: '',
    selectedBrand: '',
    selectedHeelHeight: '',
    selectedSize: '',
    types: [],
    brands: [],
    sizes: [],
    heelHeight: [],
  })
  const [prostheticCover, setProstheticCover] = useState({
    types: [],
    selectedOption: '',
    colors: [],
    options: [],
  })
  const [accessoryList, setAccessoryList] = useState({
    selectedAccessory: '',
    selectedBrand: '',
    selectedSize: '',
    types: [],
    brands: [],
    sizes: [],
  })
  const [kneeList, setKneeList] = useState({
    selectedBrand: '',
    selectedType: '',
    types: [],
    brands: [],
  })

  const initialiseData = useCallback(() => {
    if (!Object.keys(nestData?.form_data?.models_list || {})?.length) return

    const formData = nestData?.form_data
    setModels(() => ({
      models: formData?.models_list,
      selectedModel: '',
    }))

    setSocketList((sock) => {
      return {
        ...sock,
        types: formData?.sockets_list,
      }
    })
    setInterfaceList((prevList) => ({
      ...prevList,
      types: formData?.interfaces_list,
    }))
    setFootList((prevFoot) => ({
      ...prevFoot,
      types: formData?.foot_list,
    }))
    setAccessoryList((prevAcc) => ({
      ...prevAcc,
      types: formData?.accessaries_list,
    }))
    setKneeList((prevKneeList) => ({
      ...prevKneeList,
      types: formData?.knee_list,
    }))
    setProstheticCover((prevProstheticCover) => ({
      ...prevProstheticCover,
      types: formData?.prosthetic_covers_list,
    }))
  }, [nestData?.form_data])

  useEffect(() => {
    return () => {
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    initialiseData()
  }, [initialiseData, nestData])

  useEffect(() => {
    const {
      socket_type,
      interface_type,
      foot_type,
      foot_id,
      foot_carbon_size,
      prosthetic_cover_type,
      prosthetic_cover_id,
      accessary_type,
      accessary_id,
      knee_type,
      interface_id,
    } = nestData?.form_data?.current_part || {}

    // Set Socket dropdown as per coming value
    setSocketList((prevSock) => ({
      ...prevSock,
      colors: prevSock?.types?.[socket_type],
    }))
    // Set interface brand list as per incomg value
    setInterfaceList((prevIn) => {
      const selectedBrand = prevIn?.types?.[interface_type]?.find(
        (inte) => inte.id === interface_id
      )
      return {
        ...prevIn,
        brands: prevIn?.types?.[interface_type],
        sizes: selectedBrand?.sizes_available,
        colors: selectedBrand?.colors_available,
        counterParts: selectedBrand?.counter_parts_available,
      }
    })
    // Set Foot brand, size and strength
    setFootList((prevFoot) => {
      const selectedBrand = prevFoot?.types[foot_type]?.find((fb) => fb.id === Number(foot_id))
      const carbonSizeSel = selectedBrand?.Strength?.find(
        (cs) => cs?.carbon_size === foot_carbon_size
      )

      return {
        ...prevFoot,
        brands: prevFoot?.types?.[foot_type],
        sizes: selectedBrand?.sizes_available,
        heelHeight: selectedBrand?.heel_heights_available,
        carbonSizes: selectedBrand?.Strength,
        carbonStrength: carbonSizeSel?.carbon_strength,
      }
    })
    // Set prosthetic brand list based on incoming data
    setProstheticCover((prevProsList) => {
      const selectedColor = prostheticCover?.types?.[prosthetic_cover_type]?.find(
        (proCol) => proCol.id === prosthetic_cover_id
      )

      return {
        ...prevProsList,
        selectedType: prosthetic_cover_type,
        colors: prevProsList?.types?.[prosthetic_cover_type],
        options: selectedColor?.options_available,
      }
    })
    // Set Accessory brand list based on incoming data
    setAccessoryList((prevAcc) => {
      const selectedBrand = accessoryList?.types[accessary_type]?.find(
        (fb) => fb.id === accessary_id
      )

      return {
        ...prevAcc,
        selectedAccessory: accessary_type,
        brands: prevAcc?.types?.[accessary_type],
        sizes: selectedBrand?.sizes_available,
      }
    })

    // Set knee brands as per incomign selection
    setKneeList((prevKneeList) => {
      return {
        ...prevKneeList,
        brands: prevKneeList.types?.[knee_type],
      }
    })
  }, [accessoryList?.types, nestData, prostheticCover?.types])

  const onSocketSelect = (val) => {
    setSocketList((prevSock) => ({
      ...prevSock,
      selectedType: val,
      colors: prevSock?.types?.[val],
      sizes: [],
      counterParts: [],
    }))

    form.setFields([{ name: ['socket_id'], value: '' }])
  }

  const onInterfaceSelect = (val) => {
    setInterfaceList((prevInt) => ({
      ...prevInt,
      selectedInterface: val,
      brands: prevInt?.types?.[val],
    }))

    form.setFields([
      { name: ['interface_id'], value: '' },
      { name: ['interface_color'], value: '' },
      { name: ['interface_size'], value: '' },
      { name: ['interface_counter_parts'], value: '' },
    ])
  }

  const onInterfaceBrand = (val) => {
    const selectedBrand = interfaceList?.types[interfaceList?.selectedInterface].find(
      (inte) => inte.id === Number(val)
    )
    setInterfaceList((prevInt) => ({
      ...prevInt,
      sizes: selectedBrand?.sizes_available,
      colors: selectedBrand?.colors_available,
      counterParts: selectedBrand?.counter_parts_available,
      selectedBrand: selectedBrand.brand,
    }))

    form.setFields([
      { name: ['interface_color'], value: '' },
      { name: ['interface_size'], value: '' },
      { name: ['interface_counter_parts'], value: '' },
    ])
  }

  const onFootSelect = (val) => {
    setFootList((prevFoot) => ({
      ...prevFoot,
      selectedType: val,
      brands: prevFoot?.types?.[val],
      sizes: [],
      carbonStrength: [],
      carbonSizes: [],
      heelHeight: [],
    }))

    form.setFields([
      { name: ['foot_id'], value: '' },
      { name: ['foot_size'], value: '' },
      { name: ['interface_counter_parts'], value: '' },
      { name: ['foot_heel_height'], value: '' },
      { name: ['foot_carbon_size'], value: '' },
      { name: ['foot_carbon_strength'], value: '' },
    ])
  }

  const onFootBrand = (val) => {
    const selectedBrand = footList?.types[footList?.selectedType].find(
      (fb) => fb.id === Number(val)
    )

    setFootList((prevFoot) => ({
      ...prevFoot,
      selectedBrand: val,
      sizes: selectedBrand?.sizes_available,
      heelHeight: selectedBrand?.heel_heights_available,
      carbonSizes: selectedBrand?.Strength,
    }))

    form.setFields([
      { name: ['foot_size'], value: '' },
      { name: ['interface_counter_parts'], value: '' },
      { name: ['foot_heel_height'], value: '' },
      { name: ['foot_carbon_size'], value: '' },
      { name: ['foot_carbon_strength'], value: '' },
    ])
  }

  const onCarbonSizeChange = (val) => {
    const carbonSizeSel = footList?.carbonSizes.find((cs) => cs?.carbon_size === val)
    setFootList((prevFoot) => ({
      ...prevFoot,
      selectedCarbonSize: val,
      carbonStrength: carbonSizeSel?.carbon_strength,
    }))
  }

  const onAccessorySelect = (val) => {
    setAccessoryList((prevAcc) => ({
      ...prevAcc,
      selectedAccessory: val,
      brands: prevAcc?.types?.[val],
      sizes: [],
    }))

    form.setFields([
      { name: ['accessary_id'], value: '' },
      { name: ['accessary_size'], value: '' },
    ])
  }

  const onAccessoryBrand = (val) => {
    const selectedBrand = accessoryList?.types[accessoryList?.selectedAccessory].find(
      (fb) => fb.id === Number(val)
    )

    setAccessoryList((prevAcc) => ({
      ...prevAcc,
      sizes: selectedBrand?.sizes_available,
    }))

    form.setFields([{ name: ['accessary_size'], value: '' }])
  }

  const onKneeChange = (val) => {
    setKneeList((prevKneeLst) => ({
      ...prevKneeLst,
      brands: prevKneeLst.types?.[val],
    }))

    form.setFields([{ name: ['knee_id'], value: '' }])
  }

  const onProstheticCoverChange = (val) => {
    setProstheticCover((prevPros) => ({
      ...prevPros,
      selectedType: val,
      colors: prevPros?.types[val],
      options: [],
    }))

    form.setFields([
      { name: ['prosthetic_cover_id'], value: '' },
      { name: ['prosthetic_cover_option'], value: '' },
    ])
  }

  const onProstheticColor = (val) => {
    const selectedColor = prostheticCover?.types?.[prostheticCover?.selectedType].find(
      (proCol) => proCol.id === Number(val)
    )

    setProstheticCover((prevPros) => ({
      ...prevPros,
      options: selectedColor?.options_available,
    }))

    form.setFields([{ name: ['prosthetic_cover_option'], value: '' }])
  }

  const generalSelection = (val, field, fn) => {
    fn((prevVal) => ({
      ...prevVal,
      [field]: val,
    }))
  }

  return (
    <Form
      form={form}
      // initialValues={setInitialValues}
      onFinish={(values) => {
        handleDiagNestSubmission(values, diagnosisNestedForm)
      }}
      labelCol={{ span: 10 }}
      labelAlign='left'
      wrapperCol={{ span: 10 }}
    >
      <Form.Item label='Model' name='model_id'>
        {FormRenderer('select', {
          options: createOptions(models?.models),
          onChange: (val) => generalSelection(val, 'selectedModel', setModels),
        })}
      </Form.Item>
      <Divider orientation='left'>Socket</Divider>
      <Form.Item name='socket_type' label='Type'>
        <Select onChange={onSocketSelect}>
          {Object.keys(socketList?.types || {})?.map((sock) => (
            <Option key={sock} value={socketList?.types[sock]?.id}>
              {sock}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='socket_id' label='Color'>
        <Select>
          {socketList?.colors?.map((sock) => (
            <Option key={sock.id} value={sock.id}>
              {sock.color}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Divider orientation='left'>Interface</Divider>
      <Form.Item name='interface_type' label='Type'>
        <Select onChange={onInterfaceSelect}>
          {Object.keys(interfaceList?.types || {})?.map((int) => (
            <Option key={int}>{int}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='interface_id' label='Brand'>
        <Select onChange={onInterfaceBrand}>
          {interfaceList?.brands?.map((bran) => (
            <Option key={bran.id} value={bran.id}>
              {bran.brand}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='interface_color' label='Color'>
        <Select
          onChange={(val) => generalSelection(val, 'selectedColor', setInterfaceList)}
          value={interfaceList.selectedColor}
        >
          {interfaceList?.colors?.map((intCol) => (
            <Option key={intCol} value={intCol}>
              {intCol}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='interface_size' label='Size'>
        <Select onChange={(val) => generalSelection(val, 'selectedSize', setInterfaceList)}>
          {interfaceList?.sizes?.map((siz) => (
            <Option key={siz} val={siz}>
              {siz}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='interface_counter_parts' label='Counter Parts'>
        <Select onChange={(val) => generalSelection(val, 'selectedCounterPart', setInterfaceList)}>
          {interfaceList?.counterParts?.map((cp) => (
            <Option key={cp} value={cp}>
              {cp}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Divider orientation='left'>Foot</Divider>
      <Form.Item name='foot_type' label='Type'>
        <Select onChange={onFootSelect}>
          {Object.keys(footList?.types || {})?.map((int) => (
            <Option key={int}>{int}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='foot_id' label='Brand'>
        <Select onChange={onFootBrand}>
          {footList?.brands?.map((footBr) => (
            <Option key={footBr.id} value={footBr.id}>
              {footBr.brand}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='foot_size' label='Size'>
        <Select onChange={(val) => generalSelection(val, 'selectedSize', setFootList)}>
          {footList?.sizes?.map((fs) => (
            <Option key={fs}>{fs}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='foot_heel_height' label='Heel Height'>
        <Select onChange={(val) => generalSelection(val, 'selectedHeelHeight', setFootList)}>
          {footList?.heelHeight?.map((fhh) => (
            <Option key={fhh}>{fhh}</Option>
          ))}
        </Select>
      </Form.Item>
      {Boolean(footList?.carbonSizes?.length) && (
        <Form.Item name='foot_carbon_size' label='Carbon Size'>
          <Select onChange={onCarbonSizeChange}>
            {footList?.carbonSizes?.map((cs) => (
              <Option key={cs.carbon_size}>{cs.carbon_size}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {Boolean(footList?.carbonStrength?.length) && (
        <Form.Item name='foot_carbon_strength' label='Carbon Strength'>
          <Select>
            {footList?.carbonStrength?.map((cs) => (
              <Option key={cs}>{cs}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {Boolean(Object.keys(prostheticCover?.types || {})?.length) && (
        <>
          <Divider orientation='left'>Cover</Divider>
          <Form.Item name='prosthetic_cover_type' label='Material'>
            <Select onChange={onProstheticCoverChange}>
              {Object.keys(prostheticCover?.types || {})?.map((prosth) => (
                <Option key={prosth}>{prosth}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='prosthetic_cover_id' label='Color'>
            <Select onChange={onProstheticColor}>
              {prostheticCover?.colors?.map((prosCol) => (
                <Option key={prosCol.id} value={prosCol.id}>
                  {prosCol.color}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='prosthetic_cover_option' label='Cover Options'>
            <Select onChange={(val) => generalSelection(val, 'selectedOption', setProstheticCover)}>
              {prostheticCover?.options?.map((proOption) => (
                <Option key={proOption} value={proOption}>
                  {proOption}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}
      {Boolean(Object.keys(kneeList?.types || {})?.length) && (
        <>
          <Divider orientation='left'>Knee</Divider>
          <Form.Item name='knee_type' label='Type'>
            <Select onChange={onKneeChange}>
              {Object.keys(kneeList?.types || {})?.map((kl) => (
                <Option key={kl}>{kl}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='knee_id' label='Brand'>
            <Select onChange={(val) => generalSelection(val, 'selectedBrand', setKneeList)}>
              {kneeList?.brands?.map((kb) => (
                <Option key={kb.id} value={kb.id}>
                  {kb.brand}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}
      <Divider orientation='left'>Accessory</Divider>
      <Form.Item name='accessary_type' label='Type'>
        <Select onChange={onAccessorySelect}>
          {Object.keys(accessoryList?.types || {})?.map((acc) => (
            <Option key={acc}>{acc}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='accessary_id' label='Brand'>
        <Select onChange={onAccessoryBrand}>
          {accessoryList?.brands?.map((accBr) => (
            <Option key={accBr.id} value={accBr.id}>
              {accBr.brand}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='accessary_size' label='Size'>
        <Select onChange={(val) => generalSelection(val, 'selectedSize', setAccessoryList)}>
          {accessoryList?.sizes?.map((accS) => (
            <Option key={accS} value={accS}>
              {accS}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='register_staff' label='Register Staff'>
        <Input disabled />
      </Form.Item>
      <Form.Item name='updated_at' label='Updated At'>
        <Input disabled />
      </Form.Item>
      <Row gutter={6} justify='end'>
        <Col>
          <Button onClick={() => navigate('/patients')}>Back to Patients</Button>
        </Col>
        <Col>
          <Button htmlType='submit' type='primary'>
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default Parts
