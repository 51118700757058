import React, { useCallback, useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import getAnalytics, { formulateData, INIT_DATA } from './data.analytics'
import FormRenderer from '../Form/formRenderer'
import { Col, Row, Typography } from 'antd'
import { fullWidth, flexColumn, w45, graphAlign } from './style'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function Analytics() {
  const [firstStumpGraphData, setFirstStump] = useState(INIT_DATA)
  const [registeredPatientGraphData, setRegisteredPatint] = useState(INIT_DATA)
  const [stumpApprovalGraphData, setStumpApproval] = useState(INIT_DATA)

  const fetchGraph = useCallback(async (date) => {
    const { first_stump_check, registered_patients, stump_approval } = await getAnalytics(date)

    const firstStumpGraph = formulateData(first_stump_check, 'First Stump', '', '#00adb5')
    const registeredPatientGraph = formulateData(
      registered_patients,
      'Registered Patients',
      '',
      'rgb(0, 21, 41)'
    )
    const stumpApprovalGraph = formulateData(stump_approval, 'Stump Approvals')

    setFirstStump(firstStumpGraph)
    setRegisteredPatint(registeredPatientGraph)
    setStumpApproval(stumpApprovalGraph)
  }, [])

  useEffect(() => {
    fetchGraph()
  }, [fetchGraph])

  const getAnalyticsData = (_, dateString) => {
    fetchGraph(dateString)
  }
  return (
    <Row className={fullWidth}>
      <div className={flexColumn}>
        <Typography>Show Data From</Typography>
        {FormRenderer('date', { onChange: getAnalyticsData })}
      </div>
      <Row className={graphAlign}>
        <Col className={w45}>
          <Bar
            options={registeredPatientGraphData.options}
            data={registeredPatientGraphData.data}
          />
        </Col>
        <Col className={w45}>
          <Bar options={firstStumpGraphData.options} data={firstStumpGraphData.data} />
        </Col>
        <Col className={w45}>
          <Bar options={stumpApprovalGraphData.options} data={stumpApprovalGraphData.data} />
        </Col>
      </Row>
    </Row>
  )
}

export default Analytics
