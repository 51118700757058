import { Row, Col, Input, Select, Button, Table, Form } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { diagnosisData } from './__mock__data/diagnosis'
import useTabViewData from '../../hooks/useView'
import { SIZE_CHANGER } from '../../utils/constants'
import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { renderOptions } from '../../utils/optionRenderer'
import { SET_PATIENT_NAME } from '../../context/constants'
import GlobalContext from '../../context/global'

const { Column } = Table

function DiagnosisTab({ patientToSearch }) {
  const dispatch = useContext(GlobalContext.GlobalContextDispath)
  const [filterControl, setFilters] = useState({
    patient_name: patientToSearch || '',
  })
  const navigate = useNavigate()
  const { data, loading, totalPages, limit, setLimit, setPage, currentPage, filters, tableData } =
    useTabViewData('', '/list/diagnosis/', 'diagnosis', 'post', filterControl)

  const handleUpdateFilter = useCallback(
    (field, value) => {
      setFilters((existingFilters) => ({
        ...existingFilters,
        [field]: value,
      }))
      if (field === 'patient_name') {
        dispatch({
          type: SET_PATIENT_NAME,
          payload: { patientName: value },
        })
      }
    },
    [setFilters, dispatch]
  )

  const getFilteredData = () => tableData('/list/diagnosis/', 1, limit, filterControl)

  const submitForm = (e) => {
    if (e.keyCode === 13) {
      getFilteredData()
    }
  }

  return (
    <>
      <Form style={{ width: '100%' }} onKeyUp={submitForm}>
        <Row style={{ margin: '10px 0' }} gutter={4}>
          <Col span={6}>
            <Input
              onChange={({ target }) => handleUpdateFilter('patient_no', target.value)}
              placeholder='No'
            />
          </Col>
          <Col span={6}>
            <Input
              value={patientToSearch || ''}
              onChange={({ target }) => handleUpdateFilter('patient_name', target.value)}
              placeholder='Name'
            />
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('pro_info', value)}
              placeholder='Type'
              allowClear
              dropdownStyle={{ minWidth: 'max-content' }}
            >
              {renderOptions(filters, 'pro_info_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('stump_status', value)}
              placeholder='Stump Status'
              allowClear
              dropdownStyle={{ minWidth: 'max-content' }}
            >
              {renderOptions(filters, 'stump_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('done', value)}
              placeholder='Done'
              allowClear
            >
              {renderOptions(filters, 'done_list')}
            </Select>
          </Col>
          <Button
            onClick={getFilteredData}
            type='primary'
            shape='circle'
            icon={<SearchOutlined />}
          />
        </Row>
      </Form>
      <Table
        style={{ width: '100%' }}
        pagination={{
          total: totalPages,
          pageSize: limit,
          current: currentPage,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (_, b) => {
            setLimit(Number(b))
          },
          pageSizeOptions: SIZE_CHANGER,
        }}
        loading={loading}
        bordered
        // columns={diagnosisData.columns}
        dataSource={data}
      >
        {diagnosisData?.columns?.map((column) => (
          <Column
            render={column.key === 'done' ? (text) => String(text) : null}
            title={column.title}
            dataIndex={column.dataIndex}
            key={column.key}
          />
        ))}
        <Column
          title={'Action'}
          dataIndex={'action'}
          key={'action'}
          render={(_, rec) => (
            <>
              <Button
                onClick={() =>
                  navigate(`/form?tab=amp&id=${rec.p}`, {
                    state: {
                      tab: 'diagnosis',
                      selectedAmp: rec.amp_no,
                      dno: rec.dno,
                      url: `editor/dgns/stump/${rec.p}/${rec.amp_no}/${rec.dno}/`,
                      redirection: true,
                      stopEffect: true,
                    },
                  })
                }
                primary
                shape='round'
              >
                Edit
              </Button>
            </>
          )}
        />
      </Table>
    </>
  )
}

export default DiagnosisTab
