import React from 'react'
import { InfoCircleFilled } from '@ant-design/icons'
import { Button, Col, message, Tooltip, Upload } from 'antd'
import moment from 'moment'
import Store from './authHelper'

export const createOptions = (dataObj) => {
  const dataSet = []
  for (let key in dataObj) {
    dataSet.push({
      label: key,
      value: dataObj?.[key] || key,
    })
  }
  return dataSet
}

export const serializeQueryParams = function (params) {
  const paramSerial = {}
  const iterator = params?.entries()
  for (let [name, value] of iterator) {
    paramSerial[name] = value
  }

  return paramSerial
}

export const valueFormatter = function (type, value, multipleKey, data) {
  if (type === 'date') return moment(value, 'YYYY-MM-DD')
  else if (type === 'selectAndInput') {
    const keys = (data && multipleKey?.split(',')) || []
    const value = []
    for (let values of keys) {
      value.push(data[values])
    }
    return value
  }
  return value
}

export const multipleKeyResolver = function (keys) {
  //   const keysInArray = keys.split(keys)
}

export const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const formImageList = (data) => {
  return data?.map(({ file_name, key, object_url, upload_date }) => ({
    uid: key,
    name: file_name,
    status: 'done',
    url: object_url,
    upload_date: upload_date,
  }))
}

export const formatDateForBE = (date) => {
  if (typeof date !== 'undefined' && date !== null)
    return moment(date).format('YYYY-MM-DD')
}

export const isDateValid = (date) => {
  const v = moment(date)
  return v.isValid()
}

export const validNumber = (num) => {
  return !isNaN(num) ? num : 0
}

export const isNotManu = (selection) => {
  const manuList = [
    'sense',
    'eiscan',
    'testsocket',
    'final',
    'picture',
    'movie',
    'inspection',
    'ai',
    'softinsert',
    'cover',
    'lamination',
    'cushion',
  ]
  return !manuList?.includes(selection)
}

export const detectFilePath = (rec) => {
  const processField = rec?.kind_subno || rec?.kind_name
  if (!processField) return
  const [nestedForm = '', type = ''] = processField?.split('/')
  const nestFormDict = {
    Inspection: 'inspection',
    Movie: 'movie',
    Picture: 'picture',
    Final: 'final',
    Test: 'testsocket',
    Einscan: 'eiscan',
    Sense: 'sense',
    Softinsert: 'softinsert',
    'Prosthetic Cover': 'cover',
    'Positive Model for Lamination': 'lamination',
    'Cushion': 'cushion',
  }

  const typeDict = {
    circum: 3,
    height: 2,
    gcode: 2,
    'without SL': 2,
    'with SL': 1,
    strength: 1,
    front: '',
    cad: 1,
    finalsocket: 2,
    testsocket: 1,
  }

  return [nestFormDict[nestedForm], typeDict[type]]
}

export const constructNestApiUrl = (nestForm, id, ampSelected, lastDno) => {
  const manuRoutes = {
    sense: 'sense',
    eiscan: 'ein',
    testsocket: 'test',
    final: 'final',
    picture: 'pict',
    movie: 'movie',
    inspection: 'insp',
    ai: 'ai',
    softinsert: 'soft',
    cover: 'cover',
    lamination: 'lamination',
    cushion: 'cushion',
  }
  const url = `/editor/manu/${manuRoutes[nestForm]}/${id}/${ampSelected}/${lastDno}/`
  return url
}

const validImage = (fileType) => {
  const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png']
  const isAllowed = allowedTypes.includes(fileType)
  return isAllowed
}

const validSoftInsert = (fileType) => {
  const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf', 'gh', '3dm']
  const isAllowed = allowedTypes.includes(fileType)
  return isAllowed
}

const validCusion = (fileType) => {
  const allowedTypes = ['stl', 'ply', 'mqo', 'mqx', '3dm', 'gh', 'model/stl']
  const isAllowed = allowedTypes.includes(fileType)
  return isAllowed
}
// const validCad = (fileType) => {
//   const allowedTypes = ['stl', 'ply', 'mqo', 'mqx', '3dm', 'gh']
//   const isAllowed = allowedTypes.includes(fileType)
//   return isAllowed
// }

// const validGcode = (fileType) => {
//   const allowedTypes = ['factory', 'gcode', '3mf']
//   const isAllowed = allowedTypes.includes(fileType)
//   return isAllowed
// }

const validMovie = (fileType) => {
  const allowedTypes = ['mp4', 'video/mp4']
  const isAllowed = allowedTypes.includes(fileType)
  return isAllowed
}

export const beforeUpload = (file, form, uploadedList, type) => {
  const max = 1
  const fileType = file.type || file.name?.split('.')[1]
  switch (form) {
    case 'stump':
    case 'liner': {
      const isAllowed = validImage(fileType) || fileType === 'application/pdf'
      if (!isAllowed) {
        message.error(`${file.name} format not allowed`)
      }

      return isAllowed || Upload.LIST_IGNORE
    }
    case 'cushion': {
      const isAllowed = validCusion(fileType)
      if (!isAllowed) {
        message.error(`${file.name} format not allowed`)
      }

      return isAllowed || Upload.LIST_IGNORE
    }
    case 'softinsert': {
      const isAllowed = validSoftInsert(fileType)
      if (!isAllowed) {
        message.error(`${file.name} format not allowed`)
      }

      return isAllowed || Upload.LIST_IGNORE
    }
    case 'sense':
      const extensionExpected = '.ply'
      if (uploadedList?.length >= max && type !== 1) {
        message.error(`Cannot upload more than ${max}`)
        return false
      }

      if (!file?.name?.includes(extensionExpected) && (type === 1 || type === 2)) {
        message.error(`Please upload .ply files only`)
        return false
      }

      if (file?.name?.includes(extensionExpected) && (type === 1 || type === 2)) {
        return true
      }
      const valid = validImage(fileType)

      if (!valid) {
        message.error(`${file.name} format not allowed`)
      }

      return valid || Upload.LIST_IGNORE
    case 'eiscan': {
      if (uploadedList?.length >= max) {
        message.error(`Cannot upload more than ${max}`)
        return false
      }
      const extensionExpected = '.ply'
      if (file?.name?.includes(extensionExpected)) {
        return true
      } else {
        message.error('Upload only .ply file')
        return false
      }
    }
    case 'final':
      // Bypassing cases because of different file extensiona nd tyoe
      return true
    // if (type === 1 && validCad(fileType)) {
    //   return true
    // } else if (type === 2 && validGcode(fileType)) {
    //   return true
    // }
    // message.error('Invalid file selected')
    // return false
    case 'movie':
      const isValidMovie = validMovie(fileType)
      if (!isValidMovie) {
        message.error('Please upload .mp4 movie only')
        return false
      }
      return true
    case 'ai':
      if (uploadedList?.length >= max) {
        message.error(`Cannot upload more than ${max}`)
        return false
      }
      return true
    case 'inspection-oqc':
      const validate = validImage(fileType)
      if (!validate) {
        message.error(`${file.name} format not allowed`)
      }
      return validate || Upload.LIST_IGNORE
    case 'inspection':
    case 'picture':
      if (uploadedList?.length >= max) {
        message.error(`Cannot upload more than ${max}`)
        return false
      }
      const validImg = validImage(fileType)
      if (!validImg) {
        message.error(`${file.name} format not allowed`)
      }
      return validImg || Upload.LIST_IGNORE
    case 'testsocket':
      // Bypassing cases because of different file extensiona nd tyoe
      return true
    // if (type === 1 && validCad(fileType)) {
    //   return true
    // } else if (type === 2 && validGcode(fileType)) {
    //   return true
    // } else if (type === 3 && validImage(fileType)) {
    //   return true
    // } else {
    //   message.error('File Not Supported')
    //   return false
    // }
    case 'video':
      if (uploadedList?.length >= max) {
        message.error(`Cannot upload more than ${max}`)
        return false
      }
      const isValidVideo = validMovie(fileType)
      if (!isValidVideo) {
        message.error('Please upload .mp4 movie only')
        return false
      }
      return true
    case 'profilePic':
      const isImage = validImage(fileType)
      if (!isImage) {
        message.error(`${file.name} format not allowed`)
      }
      return isImage
    case 'prostheticCover': {
      const acceptedExtensions = ['3dm', 'gh', 'stl']
      const isValidFile = acceptedExtensions.includes(fileType)
      if (!isValidFile) {
        message.error(`${file.name} format not allowed`)
        return false
      }
      return true
    }
    case 'lamination': {
      const extensionExpected = '.stl'
      if (file?.name?.includes(extensionExpected)) {
        return true
      } else {
        message.error('Upload only .stl file')
        return false
      }
    }
    default:
      return Upload.LIST_IGNORE
  }
}

export const createURL = (path, id) => {
  let url = ''
  if (!!path) {
    url += path
    url += '/'
  }
  if (!!id) {
    url += id
    url += '/'
  }
  return url
}

export const formatHelper = (form, type) => {
  const cases = {
    sense: {
      1: 'Supports only .ply format',
      2: 'Supports only .ply format',
      3: 'Supports only .jpg, .jpeg, .png formats',
    },
    eiscan: {
      1: 'Supports only .ply format',
    },
    testsocket: {
      1: 'Supports only .stl .ply .mqo .mqx .3dm .gh .json',
      2: 'Supports only .factory, .gcode, .3mf',
      3: 'Supports only .jpg, .jpeg, .png formats',
    },
    final: {
      1: 'Supports only .stl .ply .mqo .mqx .3dm .gh .json',
      2: 'Supports only .factory, .gcode, .3mf',
    },
    picture: {
      [type]: 'Supports only .jpg, .jpeg, .png formats',
    },
    movie: {
      [type]: 'Supports only .mp4 format',
    },
    inspection: {
      [type]: 'Supports only .jpg, .jpeg, .png formats',
    },
    stump: {
      video: 'Supports only .mp4 format',
      liner: 'Supports only .jpg, .jpeg, .png formats',
    },
    softinsert: {
      [type]: 'Supports only .jpg, .jpeg, .png, .pdf, .gh, .3dm formats',
    },
    cushion: {
      [type]: 'Supports only .stl, .3dm,. gh, .mqo, .mqx formats',
    },
    prostheticCover: {
      [type]: 'Supports only .stl, .3dm, .gh formats',
    },
    lamination: {
      [type]: 'Supports only .stl format',
    },
  }

  return (
    <Tooltip title={cases?.[form]?.[type]}>
      <InfoCircleFilled style={{ marginLeft: 10 }} />
    </Tooltip>
  )
}

export const filterFileType = (form, type) => {
  const cases = {
    sense: {
      1: ['.ply'],
      2: ['.ply'],
      3: ['.jpg, .jpeg, .png'],
    },
    eiscan: {
      1: ['.ply'],
    },
    testsocket: {
      1: ['.stl, .ply, .mqo, .mqx, .3dm, .gh', '.json'],
      2: ['.factory, .gcode, .3mf'],
      3: ['.jpg, .jpeg, .png'],
    },
    final: {
      1: ['.stl, .ply, .mqo, .mqx, .3dm, .gh', '.json'],
      2: ['.factory, .gcode, .3mf'],
    },
    picture: {
      [type]: ['.jpg, .jpeg, .png'],
    },
    movie: {
      [type]: ['.mp4'],
    },
    inspection: {
      [type]: ['.jpg, .jpeg, .png'],
    },
    stump: {
      video: ['.mp4'],
      liner: ['.jpg, .jpeg, .png'],
    },
    softinsert: {
      [type]: ['.jpg', '.jpeg', '.png', '.pdf', '.gh', '.3dm'],
    },
    cushion: {
      [type]: ['.gh', '.3dm', '.stl', '.mqo', '.mqx'],
    },
    prostheticCover: {
      [type]: ['.stl', '.3dm', '.gh'],
    },
    lamination: {
      [type]: ['.stl'],
    },
    cura: {
      [type]: ['.curaprofile', '.zip']
    }
  }

  return cases[form][type].join()
}

export const infoHelper = (name, date) => {
  return (
    name && (
      <Tooltip title={`Uploaded by ${name} At ${boardDateFormatter(date)}`}>
        <Button disabled>{`Uploaded by ${name} At ${boardDateFormatter(date)}`}</Button>
      </Tooltip>
    )
  )
}

export const assessedBy = (name, date) => {
  return (
    name && (
      <Col push={10} span={8} style={{ marginBottom: 5 }}>
        <Tooltip title={`Assessed by ${name} At ${boardDateFormatter(date)}`}>
          <Button disabled>
            {' '}
            Assessed By: {name}
            <span style={{ paddingLeft: 8 }}>At </span> {boardDateFormatter(date)}
          </Button>
        </Tooltip>
      </Col>
    )
  )
}

export const boardDateFormatter = (date) => {
  if (!date || Number(date) === 0) return ''
  return moment(date).format('MMM. D, YYYY')
}

export const isPo = () => {
  const roles = Store.getRole
  return roles.includes('po')
}

export const isPoMng = () => {
  const roles = Store.getRole
  return roles.includes('po_mng')
}

export const isProductManager = () => {
  const roles = Store.getRole
  return roles.includes('product_mng')
}

export const isAdmin = () => {
  const roles = Store.getRole
  return roles.includes('admin')
}

export const isIndainOffice = () => {
  const office = Store.getOffice
  return office === 2
}

export const downloadHelper = (itemLink, fileName) => {
  const link = document.createElement('a')
  link.href = itemLink
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export async function createFile(path, fileName) {
  const response = await fetch(path);
  const data = await response.blob();

  const file = new File([data], fileName);
  return file
}