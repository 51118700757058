import { http } from "../../network/axiosInstance"

export const getAllOpenIssues = () => {
    const openIssues = http.get('/issues/open/')
    return openIssues
}

export const getAllAssignedIssues = () => {
    const resolvedIssues = http.get('/issues/assigned/')
    return resolvedIssues
}

export const getAllResolvedIssues = () => {
    const assignedIssues = http.get('/issues/resolved/')
    return assignedIssues
}

export const getInitialData = () => {
    const initialData = http.get('/issue/new/')
    return initialData
}

export const postNewIssue = async (body) => {
    const formBody = new FormData()
    try {

        for (let key in body) {
            if (!body[key]) body[key] = ''
            formBody.append(key, body[key])
        }
    } catch (error) {
        console.log('Error,', error)
    }
    const issueToPost = await http.post(`/issue/new/`, formBody)
    return issueToPost
}

export const getIssue = async (id) => {
    const issueData = await http.get(`issue/${id}`)
    return issueData
}

export const assignIssue = async (id, assignedStaff) => {
    const body = {
        assigned_to: assignedStaff
    }
    const issueData = await http.post(`issue/${id}/`, body)
    return issueData
}

export const resolveIssue = async (id) => {
    const body = {
        resolved_flag: 1
    }
    const issueData = await http.post(`resolve-issue/${id}/`, body)
    return issueData
}

export const assigendByAdmin = async () => {
    const assigendByAdminIssues = await http.get(`issues/assigned-by-admin/`)
    return assigendByAdminIssues
}