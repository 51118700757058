import { css } from '@emotion/css'

export const fullWidth = css`
  width: 100%;
`

export const flexColumn = css`
  display: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5% 0;
`
export const w45 = css`
  width: 45%;
`

export const graphAlign = css`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
