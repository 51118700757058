import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Form, Input, message, Row, Select, Typography } from 'antd'
import { container, officeSelection, form } from './loginStyle'
import { useLocation, useNavigate } from 'react-router-dom'
import { http } from '../../network/axiosInstance'
import Store from '../../utils/authHelper'
import {
  ACHILLI_ALLOFFICE,
  ACHILLI_ID,
  ACHILLI_NICKNAME,
  ACHILLI_OFFICE,
  ACHILLI_TOKEN,
  ACHILLI_ROLE,
} from '../../utils/constants'

const { Option } = Select

export default function Login() {
  const navigate = useNavigate()
  const [showOfficeSelection, setShowOffice] = useState(false)
  const [selectedOffice, setSelectedOffice] = useState(null)
  const [isForgotPassword, setForgotPassword] = useState(false)
  const { state } = useLocation()

  useEffect(() => {
    const previousOffice = Store.getFromLocal(ACHILLI_OFFICE)
    if (state?.switch) {
      const officesInStore = JSON.parse(Store.getFromLocal(ACHILLI_ALLOFFICE))
      setShowOffice(officesInStore)
    } else if (!state?.switch && previousOffice) {
      Store.clearLocal()
    }
  }, [showOfficeSelection, state?.switch])

  const onFinish = async (fields) => {
    const hideMessage = message.loading('Logging you in achilli', 0)
    try {
      const req = await http.post('/login/', {
        email: fields.email,
        password: fields.password,
      })
      if (req.status === 200) {
        // message.destroy()
        message.success('Succesfully Logged In')
        const { is_admin, is_sales, is_po_mng, is_po, is_product, is_product_mng } = req.data

        const role = [
          is_admin && 'admin',
          is_sales && 'sales',
          is_po_mng && 'po_mng',
          is_po && 'po',
          is_product && 'product',
          is_product_mng && 'product_mng',
        ].filter(Boolean)

        const { token, id, nickname, offices } = req.data
        Store.storeInLocal(ACHILLI_TOKEN, token)
        Store.storeInLocal(ACHILLI_ID, id)
        Store.storeInLocal(ACHILLI_NICKNAME, nickname)
        Store.storeObjectInLocal(ACHILLI_ALLOFFICE, offices)
        Store.storeInCookie(ACHILLI_TOKEN, token)

        //const role = {is_admin:is_admin, is_sales:is_sales, is_po_mng:is_po_mng, is_po:is_po, is_product:is_product, is_product_mng:is_product_mng}
        Store.storeObjectInLocal(ACHILLI_ROLE, role)

        if (req.data['multiple_office_flag']) {
          return setShowOffice(req.data.offices)
        }
        Store.storeInLocal(ACHILLI_OFFICE, req.data?.office?.office)
        Store.storeInCookie(ACHILLI_OFFICE, req.data?.office?.office)
        return navigate('/overview')
      }
      throw Error(req)
    } catch (error) {
      message.destroy()
      // message.error()
    } finally {
      hideMessage()
    }
  }
  const onFinishFailed = () => { }

  const goToDashboard = async () => {
    if (!selectedOffice) return message.warn('Cannot proceed without selecting office')

    Store.storeInLocal(ACHILLI_OFFICE, selectedOffice)
    Store.storeInCookie(ACHILLI_OFFICE, selectedOffice)
    navigate('/overview')
  }

  const setOfficeSelection = (selecetdValue) => {
    setSelectedOffice(selecetdValue)
  }

  const forgotPasswordToggle = () => {
    setForgotPassword(!isForgotPassword)
  }

  const sendResetLink = async (fields) => {
    const hideMessage = message.loading(`Sending reset link to ${fields.email}`, 0);
    try {
      const req = await http.post('/forgotPassword/', {
        email: fields.email
      })
      if (req.status === 200) {
        message.success(req.data.message)
        setForgotPassword(false)
      }
      throw Error(req)
    } catch (error) {
      console.log(error)
    }
    finally {
      hideMessage()
    }
  }

  return (
    <div className={container}>
      {isForgotPassword ? (
        <div style={{ flexDirection: 'column', padding: 2 }} className={form}>
          <Row style={{ margin: 10 }}>
            <Col className={officeSelection} span={24}>
              <Typography.Text>
                Please enter the registered email address
              </Typography.Text>
            </Col>
          </Row>
          <Form
            name='reset'
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 16,
            }}

            onFinish={sendResetLink}
          >
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{
              offset: 8,
              span: 16,
            }}>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      )
        : !!showOfficeSelection.length ? (
          <div style={{ flexDirection: 'column', padding: 2 }} className={form}>
            <Row>
              <Col className={officeSelection} span={24}>
                <Typography.Text>
                  Please select the office
                </Typography.Text>
              </Col>
            </Row>
            <Select
              value={selectedOffice}
              onChange={setOfficeSelection}
              style={{ width: '65%', margin: 7 }}
              defaultValue='Please Select Office'
            >
              {/* <Option value={"-1">{office.office}</Option> */}
              {showOfficeSelection?.map((office) => (
                <Option key={office.office} value={office.office}>{office.name}</Option>
              ))}
            </Select>
            <Button onClick={goToDashboard} type='primary' htmlType='submit'>
              Submit
            </Button>
          </div>
        ) : (
          <div className={form}>
            <Form
              name='basic'
              labelAlign='left'
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <Form.Item
                label='Email'
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              //autoComplete='false'
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='Password'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Row justify='space-between'>
                <Col span={12}>
                  <Form.Item
                    name='remember'
                    valuePropName='checked'
                    wrapperCol
                  >
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12} type='flex' align='end'>
                  <Button type='link' style={{ paddingInlineEnd: 0 }} onClick={forgotPasswordToggle}>
                    Forgot Password??
                  </Button>
                </Col>
              </Row>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type='primary' htmlType='submit'>
                  Proceed
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
    </div>
  )
}
