import { css } from '@emotion/css'

export const Container = css`
  max-height: 200px;
  overflow: auto;
`

export const MsgTitle = css`
  position: sticky;
  top: 0;
  /* background-color: #1f1f1f; */
`
export const customTab = (themeMode) => {
  const lightTheme = themeMode === 'light'
  return {
    tabLink: css({
      flex: 1,
      // 
      fontSize: 14,
      fontWeight: 500,
      color: lightTheme ? '#333' : 'rgba(255, 255, 255, 0.85)',
      textAlign: 'center',
      textDecoration: 'none',
      '&:hover': {
        color: '#00adb5',
      },
      padding: '0.6rem 0'
    }),
    active: css({
      color: '#00adb5 !important',
      borderBottom: '2px solid #00adb5',
      animationTimingFunction: 'ease-in'
    }),
    linkWrap: css({
      paddingLeft: 0,
      paddingRight: '2rem',
    })
  }
};
