import { css } from "@emotion/css";

export const EditIcon = css`
  position: absolute;
  bottom: 0;
  right: 20px;
  background: grey;
  padding: 6px;
  border-radius: 20px;
  color: white;
`

export const SpinClass = css`
  width: 100%
`