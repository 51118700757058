import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router'
import LayoutContainer from '../layout'
import { BOARD, FORM, OVERVIEW, PATIENTS, PROFILE, SETTINGS, USERPROFILE, ANALYTICS, CONTACT } from '../layout/constants'
import ChangePassword from '../pages/Account/ChangePassword'
import Board from '../pages/Board'
import SalesBoard from '../pages/Board/Sales'
import Login from '../pages/Login'
import OverView from '../pages/Overview'
import Patients from '../pages/Patients'
import PoManager from '../pages/Board/PoManager'
import Store from '../utils/authHelper'
import { ACHILLI_OFFICE, ACHILLI_TOKEN } from '../utils/constants'
import Staff from '../pages/Settings/Staff'
import PoStump from '../pages/Board/PoStump'
import PoManufacture from '../pages/Board/PoManufacture'
import ProductManager from '../pages/Board/ProductManager'
import ProductManufacture from '../pages/Board/ProductManufacture'
import Form from '../pages/Form'
import EditStaff from '../pages/Settings/StaffEdit'
import Notfound from '../pages/Error/Notfound'
import Error500 from '../pages/Error/Error500'
import ResetPassword from '../pages/Login/resetPassword'
import { useSearchParams } from 'react-router-dom'
import UserProfile from '../pages/Account/UserProfile'
import Analytics from '../pages/Analytics'
import Cura from '../pages/Settings/Cura'
import CuraAddView from '../pages/Settings/Cura/Add'
import ContactPage from '../pages/Contact'

const RenderWithLayout = (Component, page) => {
  const [params] = useSearchParams()

  const navigate = useNavigate()
  useEffect(() => {
    const token = Store.getFromLocal(ACHILLI_OFFICE)
    const office = Store.getFromLocal(ACHILLI_TOKEN)
    const resetToken = params.get('token')

    if ((!token || !office) && !resetToken) return navigate('/login')
  }, [navigate, params])

  return (
    <LayoutContainer active={page}>
      <Component />
    </LayoutContainer>
  )
}

const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/login' element={RenderWithLayout(Login, null)} />
      <Route path='/' element={RenderWithLayout(OverView, OVERVIEW)} />
      <Route path='overview' element={<Navigate to='/' replace />} />
      <Route path='analytics' element={RenderWithLayout(Analytics, ANALYTICS)} />
      <Route path='/board' element={RenderWithLayout(Board, BOARD)}>
        <Route path='sales' element={<SalesBoard />} />
        <Route path='po-manager' element={<PoManager />} />
        <Route path='po-stump' element={<PoStump />} />
        <Route path='po-manufacture' element={<PoManufacture />} />
        <Route path='product-manager' element={<ProductManager />} />
        <Route path='product-manufacture' element={<ProductManufacture />} />
      </Route>
      <Route path='/patients' element={RenderWithLayout(Patients, PATIENTS)} />

      <Route exact path='/settings' element={RenderWithLayout(Board, SETTINGS)}>
        <Route exact path='staff' element={<Staff />} />
        <Route exact path='staff/edit' element={<EditStaff />} />
        <Route exact path='staff/register' element={<EditStaff />} />
        <Route exact path='cura' element={<Cura />} />
        <Route exact path='cura/add' element={<CuraAddView />} />
        <Route exact path='cura/add/:id' element={<CuraAddView />} />
      </Route>

      {/* Profile */}
      <Route exact path='/profile' element={RenderWithLayout(UserProfile, USERPROFILE)} />

      <Route path='/change-password' element={RenderWithLayout(ChangePassword, PROFILE)} />

      <Route path='/reset-password' element={RenderWithLayout(ResetPassword, null)} />

      <Route path='/error-500' element={<Error500 />} />

      <Route path='*' element={<Notfound />} />

      {/* Form */}
      <Route path='/form' element={RenderWithLayout(Form, FORM)} />

      {/* Contact / Query page */}
      <Route path='/contact' element={RenderWithLayout(ContactPage, CONTACT)} />

      {/* Staff Edit
      <Route path='/settings/staff/edit' element={RenderWithLayout(Form, null)} /> */}
    </Routes>
  )
}

export default AllRoutes
