import { IS_STUMP, SET_ALL_AMPUTATION, SET_CURRENT_DIAGNOSIS, SET_DOWNLOAD_META_DATA, SET_IMAGE, SET_IMAGE_NAME, SET_PATIENT_INFO, SET_PATIENT_NAME, SHOW_IMAGE_MODAL } from './constants'

export const initialState = {
  imageContext: {
    previewVisible: false,
    previewImage: '',
    imageName: '',
    kind: '',
    dno: '',
    patientId: '',
    fromStump: false
  },
  amps: [],
  search: {
    patientName: ''
  },
  currentDiagnosis: {},
  patientInfo: {
    patientName: '',
    patientAge: '',
    showModal: false
  }
}

function globalReducer(state, action) {
  switch (action.type) {
    case SET_IMAGE:
      return {
        ...state,
        imageContext: {
          ...state.imageContext,
          previewImage: action.payload,
        },
      }
    case SHOW_IMAGE_MODAL:
      return {
        ...state,
        imageContext: {
          ...state.imageContext,
          previewVisible: action.payload,
        },
      }
    case SET_IMAGE_NAME:
      return {
        ...state,
        imageContext: {
          ...state.imageContext,
          imageName: action.payload,
        },
      }
    case SET_DOWNLOAD_META_DATA:
      return {
        ...state,
        imageContext: {
          ...state.imageContext,
          kind: action.payload.kind,
          dno: action.payload.dno,
          patientId: action.payload.patientId,
        },
      }
    case SET_ALL_AMPUTATION:
      return {
        ...state,
        amps: action.payload.all_amputations
      }
    case IS_STUMP:
      return {
        ...state,
        ...state,
        imageContext: {
          ...state.imageContext,
          fromStump: action.payload,
        },
      }
    case SET_PATIENT_NAME:
      return {
        ...state,
        search: {
          ...state.search,
          patientName: action.payload.patientName
        }
      }
    case SET_CURRENT_DIAGNOSIS:
      return {
        ...state,
        currentDiagnosis: action.payload.current_diagnosis
      }
    case SET_PATIENT_INFO:
      return {
        ...state,
        patientInfo: action.payload
      }
    default:
      return state
  }
}

export default globalReducer
