import Access from '../permission/accessHelper'
import SIDEBAR from './constants'

const SidebarItems = (role)=> {
    function deepClone(sidebar){
        const clonedSideBar = sidebar.map((object)=>{
            const newObject={};
            for(var key in object)
            {
                if(key==='children')
                newObject[key]=[...object[key]];
                else
                newObject[key]=object[key];
            }
            return newObject;
        });
        return clonedSideBar;
    }
    return deepClone(SIDEBAR).map(item => {
        if (item.children) {
            item.children = item.children.filter(child => Access.hasAccess(role, child.label))
        }

        return item;
    })
}

export default SidebarItems;