import { Tabs } from 'antd';
import StumpNotDone from './NotDone';

const {TabPane} = Tabs

function SalesBoard() {

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={() => { }}>
          <TabPane tab="Stump: Not Done" key="not-done">
           <StumpNotDone />
          </TabPane>
        </Tabs>
    </>
  )
}

export default SalesBoard
