import { css } from '@emotion/css'

export const logo = css`
  float: left;
  color: red;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  cursor: pointer;
`

export const main = css`
  &.ant-layout {
    min-height: calc(100vh);
  }
`
export const greet = css`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  margin: 1em
`

export const menuItem = css`
  li.ant-menu-item span {
    white-space: break-spaces;
    line-height: 20px;
  }
`
