import axios from 'axios'
import StorageHelper from '../utils/authHelper'
import { ACHILLI_OFFICE, ACHILLI_TOKEN } from '../utils/constants'
import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { message } from 'antd'

const hasToken = StorageHelper.getFromLocal(ACHILLI_TOKEN)
const hasOfficeSelected = StorageHelper.getFromLocal(ACHILLI_OFFICE)

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${hasToken}`,
    office: hasOfficeSelected,
  },
})

function ErrorHandler(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const isLoginPage = location.pathname === '/login'

  useEffect(() => {
    message.config({
      maxCount: 1,
    })
  }, [])

  useEffect(
    () => {
      instance.interceptors.request.use(function (config) {
        const hasToken = StorageHelper.getFromLocal(ACHILLI_TOKEN)
        const hasOfficeSelected = StorageHelper.getFromLocal(ACHILLI_OFFICE)

        if (hasToken) {
          config.headers.Authorization = `Bearer ${hasToken}`
          config.headers.office = hasOfficeSelected
        }
        else
        {
          config.headers = {}
        }

        return config
      })

      instance.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          message.error(
            error?.response?.data?.message || error?.response?.data?.detail || error?.message || error || 'Something went wrong'
          )
          if (error?.response?.status === 401) {
            //uselocation.pathname is not giving current path
            if (window.location.pathname === '/login' || isLoginPage) {
              return message.error('Invalid Credentials')
            }
            return navigate('/login')
          }
          if (error?.response?.status === 404) return navigate('/*')
          if(error?.response?.status === 400 && error?.config?.url === '/password/change/')
          {
            return message.error(error?.response?.data?.message || 'Invalid Credentials')
          }
          else if (error?.response?.status >= 500) {
            navigate('/error-500', {
              state: { errorStatus: error?.response?.status, errorData: error?.response?.data },
            })
          }
        }
      )
    }
    // [isLoginPage, navigate]
  )

  return props.children
}

export default ErrorHandler

export const http = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  patch: instance.patch,
}
