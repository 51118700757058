import { useReducer } from 'react'
import globalReducer, { initialState } from './context'
import GlobalContext from './context/global'
import AllRoutes from './routes/Routes'

function App() {
  const [state, dispatch] = useReducer(globalReducer, initialState)

  return (
    <GlobalContext.GlobalContextState.Provider value={state}>
      <GlobalContext.GlobalContextDispath.Provider value={dispatch}>
        <AllRoutes />
      </GlobalContext.GlobalContextDispath.Provider>
    </GlobalContext.GlobalContextState.Provider>
  )
}

export default App
