import { css } from "@emotion/css";

export const container = css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    color: black;
`

export const form = css`
    border: 1px solid #443f3fa6;
    border-radius: 8px;
    min-width: 425px;
    min-height: 325px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
`

export const officeSelection = css`
    color: #fff;
`