import { Button, Col, Row, Table } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { deleteCuraProfile, getCuraList } from './Cura.network'
import { DownloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { downloadHelper, isProductManager } from '../../../utils/helpers'

const { Column } = Table

const Cura = () => {
  const navigate = useNavigate()
  const [curaList, setCuraList] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchCuraList = useCallback(async () => {
    setLoading(true)
    const list = await getCuraList()
    setCuraList(list?.cura_list)
    setLoading(false)
  }, [])

  const deleteCura = async (curaId) => {
    setLoading(true)
    const deleteSuccess = await deleteCuraProfile(curaId)
    deleteSuccess && fetchCuraList()
  }

  const downloadCuraFile = (file) => {
    downloadHelper(file?.object_url, file?.file_name)
  }

  useEffect(() => {
    fetchCuraList()
  }, [fetchCuraList])

  return (
    <>
      {isProductManager() && <Row>
        <Col style={{ marginLeft: 'auto' }}>
          <Button
            onClick={() => navigate('add')}
            type='secondary'
          //   icon={<UserAddOutlined />}
          >
            Add Profile
          </Button>
        </Col>
      </Row>}
      <Table
        pagination={false}
        loading={loading}
        bordered
        dataSource={curaList}
      >
        <Column title='Name' dataIndex='name' key='name' />
        <Column title='Detail' dataIndex='detail' key='detail' />
        <Column
          title={'Action'}
          dataIndex={'action'}
          key={'action'}
          render={(_, rec) => (
            <div style={{ display: 'flex', gap: 5 }}>
              <Button
                icon={<DownloadOutlined />}
                onClick={() => downloadCuraFile(rec?.s3_cura_file?.[0])}
                primary
                shape='round'
              ></Button>
              <Button
                icon={<EditOutlined />}
                onClick={() => navigate(`add/${rec.id}`)}
                primary
                shape='round'
              ></Button>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => deleteCura(rec.id)}
                primary
                shape='round'
              ></Button>
            </div>
          )}
        />
      </Table>
    </>
  )
}

export default Cura
