import {css} from '@emotion/css'

export const container = css`
    display:flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 100vw; 
`
export const col1 = css`
    font-size: 12rem;
    display: table-cell;
    padding-inline-end:50px;
`

export const col2 = css`
    vertical-align: middle;
    display: table-cell;
    padding-block-start: 50px;
    margin-inline-start: 50px;
`
