import Store from '../utils/authHelper'
import { ACHILLI_ALLOFFICE } from '../utils/constants'
import { PERMISSION_LIST } from './constants'
class AccessHelper {
  hasAccess(role, element) {
    if (element in PERMISSION_LIST) {
      return role ? PERMISSION_LIST[element].some((val) => role.includes(val)) : false
    }
    return true
  }

  get canSwitchOffice() {
    const multipleOffices = Store.getObjectFromLocal(ACHILLI_ALLOFFICE)
    return multipleOffices
  }

  shouldShowPI(office) {
    if (office !== '1') return false
    return true
  }

  isIDrequired(office){
    if(office === '2') return true
    return false
  }
}

const Access = new AccessHelper()
export default Access
