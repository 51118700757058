import React, { useContext, useState } from 'react'
import { Layout, Row } from 'antd'
import { Tabs } from 'antd'
import PatientsTab from './PatientsTab'
import Stumptab from './StumpTab'
import DiagnosisTab from './DiagnosisTab'
import FilesTab from './FilesTab'
import BreadCrumb from '../../utils/breadCrumb'
import GlobalContext from '../../context/global'
import DownloadTab from './DownloadTab'
import { customTab } from './style'
import { useSearchParams } from 'react-router-dom'
import CustomTabBar from '../../components/CustomTabPanel'


const { Content } = Layout

export default function Patients() {
  const { search } = useContext(GlobalContext.GlobalContextState)
  const [params] = useSearchParams()
  const newContextTab = params.get('tab')
  const [tab, activeTab] = useState(newContextTab || 'patients')


  const changeTab = (t) => {
    activeTab(t)
  }

  const panes = [
    { key: 'patients', tab: 'All Patients', link: '/patients?tab=all-patients' },
    { key: 'stump', tab: 'Stump', link: '/patients?tab=stump' },
    { key: 'diagnosis', tab: 'Diagnosis', link: '/patients?tab=diagnosis' },
    { key: 'file', tab: 'File', link: '/patients?tab=file' },
    { key: 'download', tab: 'Download', link: '/patients?tab=download' },
  ];

  return (
    <Layout
      style={{
        padding: '0 24px 24px',
      }}
    >
      <BreadCrumb />
      <Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Tabs className={customTab.tabBar}
          
          renderTabBar={(props) => (
            <CustomTabBar onChange={changeTab} {...props} panes={panes} />
          )}
          defaultActiveKey={tab}
          activeKey={tab}
          onChange={changeTab}
        />
        <Row style={{ marginTop: 10, width: '100%' }}>
          {tab === 'patients' && <PatientsTab patientToSearch={search.patientName} />}
          {tab === 'stump' && <Stumptab patientToSearch={search.patientName} />}
          {tab === 'diagnosis' && <DiagnosisTab patientToSearch={search.patientName} />}
          {tab === 'file' && <FilesTab patientToSearch={search.patientName} />}
          {tab === 'download' && <DownloadTab patientToSearch={search.patientName} />}
        </Row>
      </Content>
    </Layout>
  )
}
