import { ACHILLI_OFFICE, ACHILLI_ROLE } from "./constants"

class StorageHelper {
  storeInLocal(key, data) {
    window.localStorage.setItem(key, data)
  }
  getFromLocal(key) {
    return window.localStorage.getItem(key)
  }
  clearLocal() {
    this.deleteAllCookies()
    return window.localStorage.clear()
  }
  storeObjectInLocal(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data))
  }
  getObjectFromLocal(key) {
    try {
      return JSON.parse(this.getFromLocal(key))
    } catch (error) {
      return false
    }
  }
  get getRole() {
    const roles = JSON.parse(this.getFromLocal(ACHILLI_ROLE) || '[]')
    return roles
  }

  get getOffice() {
    const office = JSON.parse(this.getFromLocal(ACHILLI_OFFICE) || '')
    return office
  }

  storeInCookie(name, value) {
    document.cookie = `${name}=${value}; domain=.instalimb.com`
  }

  deleteAllCookies() {
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split(".");
      while (d.length > 0) {
        var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        var p = window.location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        };
        d.shift();
      }
    }
  }
}

const Store = new StorageHelper()
export default Store

// TEST COMMIT
