export const ACHILLI_TOKEN = 'achilli_token'
export const ACHILLI_ID = 'achilli_id'
export const ACHILLI_NICKNAME = 'achilli_nickname'
export const ACHILLI_OFFICE = 'achilli_office'
export const ACHILLI_ALLOFFICE = 'achilli_all_office'
export const ACHILLI_ROLE = 'achilli_role'
export const EDIT_STAFF = 'edit_staff'
export const ADD_STAFF = 'add_staff'
export const PRIMARY_BTN = '#ffffffad'
export const DARK_MODE = 'DARK_MODE'
export const ADMIN_PANEL = 'admin_panel'
export const DOWNLOAD_ALL = 'download_all'


export const LIMIT = 40
export const SIZE_CHANGER = ['5', '10', '20', '30', '40']