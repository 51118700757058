import { Layout, Form, Button, Row, Col, Spin, Upload, Avatar, message } from 'antd'
import React, { useEffect, useState } from 'react'
import FormRenderer from '../Form/formRenderer'
import { userProfileForm } from '../Form/forms'
import { useNavigate } from 'react-router-dom'
import useFormData from '../../hooks/useForms'
import { beforeUpload } from '../../utils/helpers'
import { EditOutlined } from '@ant-design/icons'
import { http } from '../../network/axiosInstance'
import { EditIcon, SpinClass } from './style'

const { Content } = Layout

export default function UserProfile() {
  const [form] = Form.useForm();
  const [profileImageUrl, setProfileImageUrl] = useState()
  const [fullName, setFullName] = useState('')
  const [nickName, setNickName] = useState()
  const [profileImg, setProfileImg] = useState()
  const [percent, setPercent] = useState(0)
  const navigate = useNavigate()

  const { data, loading } = useFormData('profile', '', '', '', 'get')
  useEffect(() => {
    form.setFieldsValue(data?.user_profile)
    setFullName(`${data?.user_profile?.first_name || ''} ${data?.user_profile?.last_name || ''}`)
    setNickName(data?.user_profile?.nickname)
    if(data?.s3_profile_pic?.length)
        setProfileImageUrl(data?.s3_profile_pic[0]?.object_url)
  }, [form, data?.user_profile,data?.s3_profile_pic]);

  
  const onProgress = ({ percent: pcnt }) => {
    message.loading(`Upload in Progress ${pcnt || percent}% uploaded`, 0)
  }

  const submitForm = async (values) => {
    values.profile_pic=profileImg
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: ({ total, loaded }) => {
          onProgress({ percent: Math.floor((loaded / total) * 100) })
          setPercent(Math.floor((loaded / total) * 100))
        },
      }
      const saveFile = await http.post(`profile/`, values, config)
      
      if (saveFile.status === 200) {
        form.setFieldsValue(saveFile?.data?.user_profile)
        setFullName(`${saveFile?.data?.user_profile?.first_name} ${saveFile?.data?.user_profile?.last_name}`)
        setNickName(saveFile?.data?.user_profile?.nickname)
        if(saveFile?.data?.s3_profile_pic?.length)
          setProfileImageUrl(saveFile?.data?.s3_profile_pic[0]?.object_url)
        message?.destroy()
        message.success(saveFile?.data?.message)
      }
    } catch (err) {
      console.log('Error: ', err)
      message.error('Something went wrong!')
    }
  }
  return (
    <Spin wrapperClassName={SpinClass} spinning={loading} >
    <Content
      style={{
        padding: 50,
        margin: 0,
        minHeight: 280,
      }}
    >
      <Form
        form={form}
        onFinish={(values) => submitForm(values)}
        initialValues={data}
        labelCol={{span:5, style:{width:'100%'}}}
        labelAlign='left'
        wrapperCol={{ span: 19, style:{width:'100%'} }}
        layout='inline'
      >
        <Row justify='space-around' style={{marginBlockEnd:'10px', width:'100%'}} 
        >
        <Form.Item style={{position:'relative', display:'inline-flex', marginInlineEnd:'0'}} wrapperCol={{span:24}}> 
          <Avatar size={120} src={profileImageUrl} shape= "circle" alt='avatar'> </Avatar>
          <Upload
            showUploadList={false}
            accept={['.jpg, .jpeg, .png']}
            beforeUpload={(file) => {
              const isAllowed = beforeUpload(file, 'profilePic')
              if (isAllowed) {
                setProfileImageUrl(URL.createObjectURL(file))
                setProfileImg(file)
              }
              return false
              }
            }
          >
            <EditOutlined className={EditIcon} style={{color:'white'}}/>
          </Upload>    
        </Form.Item>
        </Row>
        <Row justify='space-around' style={{fontSize:25, fontWeight:600, width:'100%'}}>
           {fullName && fullName}
        </Row>
        <Row justify='space-around' style={{fontSize:25, marginBlockEnd:'30px', width:'100%'}}>
           {nickName && `(${nickName})`}
        </Row>
        <Row justify='space-around'>
        {userProfileForm?.map((field, idx) => (
          <Form.Item key={idx + field.name} {...field}>
            {FormRenderer(field.type, {
              ...field.props,
            })}
          </Form.Item>
        ))}
        </Row>

        <Row gutter={6} justify='end' style={{width:'100%'}}>
          <Col pull={2}>
            <Button onClick={() => navigate("/")}>
              Back
            </Button>
          </Col>
          <Col pull={2}>
            <Button htmlType='submit' type='primary'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Content>
    </Spin>
  )
}
