import { Button, Col, Divider, Form, Row, Upload } from 'antd'
import React from 'react'
import {
  assessedBy,
  beforeUpload,
  filterFileType,
  formatHelper,
  infoHelper,
} from '../../../../utils/helpers'
import FormRenderer from '../../formRenderer'
import usePreview from '../components/ImagePreview/usePreview'
import { UploadWrapper } from '../style'
import { UploadOutlined } from '@ant-design/icons'

export default function Inspection({
  showDownloadProps,
  onProgress,
  id,
  ampSelected,
  previewImage,
  dno,
  saveImage,
  lastDno,
  nestData,
  formData,
  triggerMultiDelete,
  getOptions,
  diagnosisNestedForm,
  form,
  formOne,
  formTwo,
  formThree,
  handleDiagNestSubmission,
  files,
  setFiles,
}) {
  const { onDownload } = usePreview()
  return (
    <>
      <Divider orientation='center'>STRENGTH</Divider>
      <Form
        form={formOne}
        onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '1')}
        labelCol={{ span: 10 }}
        labelAlign='left'
        wrapperCol={{ span: 10 }}
      >
        <Form.Item label='Upload' name={'sl'}>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              onProgress={onProgress}
              onPreview={(file) => previewImage(file, 8)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 8, filename: file.name, url: file.url })
              }}
              fileList={files?.strength}
              beforeUpload={(file) => beforeUpload(file, 'inspection', files?.strength)}
              accept={filterFileType('inspection', 1)}
              customRequest={(options) =>
                saveImage(
                  options,
                  `patientdata/${id}/${lastDno}/inspection/strength/`,
                  8,
                  () =>
                    formData(
                      `/editor/manu/insp/${id}/${ampSelected}/${lastDno}/`,
                      undefined,
                      'get'
                    ),
                  1
                )
              }
              listType='picture'
              onRemove={(image) =>
                triggerMultiDelete(image, () =>
                  setFiles((prev) => ({
                    ...prev,
                    strength: files.strength?.filter((img) => img.uid !== image?.uid),
                  }))
                )
              }
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('inspection', 1)}
            </Upload>
            {infoHelper(
              nestData?.form_data?.file1?.upload_staff,
              nestData?.form_data?.file1?.upload_date
            )}
          </div>
        </Form.Item>
        <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
          {FormRenderer('select', {
            options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
          })}
        </Form.Item>
        {assessedBy(
          nestData?.form_data?.file1?.approval_staff,
          nestData?.form_data?.file1?.approval_date
        )}
        <Form.Item label='Memo' name='memo'>
          {FormRenderer('textarea')}
        </Form.Item>
         <Row gutter={6} justify='end'>
          <Col>
            <Button disabled={!files?.strength?.length} htmlType='submit' type='primary'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider orientation='center'>HEIGHT</Divider>
      <Form
        form={formTwo}
        onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '2')}
        labelCol={{ span: 10 }}
        labelAlign='left'
        wrapperCol={{ span: 10 }}
      >
        <Form.Item label='Upload' name={'sl'}>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              onProgress={onProgress}
              onPreview={(file) => previewImage(file, 8)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 8, filename: file.name, url: file.url })
              }}
              fileList={files?.height}
              beforeUpload={(file) => beforeUpload(file, 'inspection', files?.height)}
              accept={filterFileType('inspection', 2)}
              customRequest={(options) =>
                saveImage(
                  options,
                  `patientdata/${id}/${lastDno}/inspection/height/`,
                  8,
                  () =>
                    formData(
                      `/editor/manu/insp/${id}/${ampSelected}/${lastDno}/`,
                      undefined,
                      'get'
                    ),
                  2
                )
              }
              listType='picture'
              onRemove={(image) =>
                triggerMultiDelete(image, () =>
                  setFiles((prev) => ({
                    ...prev,
                    height: files.height?.filter((img) => img.uid !== image?.uid),
                  }))
                )
              }
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('inspection', 2)}
            </Upload>
            {infoHelper(
              nestData?.form_data?.file2?.upload_staff,
              nestData?.form_data?.file2?.upload_date
            )}
          </div>
        </Form.Item>
        <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
          {FormRenderer('select', {
            options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
          })}
        </Form.Item>
        {assessedBy(
          nestData?.form_data?.file2?.approval_staff,
          nestData?.form_data?.file2?.approval_date
        )}
        <Form.Item label='Memo' name='memo'>
          {FormRenderer('textarea')}
        </Form.Item>
         <Row gutter={6} justify='end'>
          <Col>
            <Button disabled={!files?.height?.length} htmlType='submit' type='primary'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider orientation='center'>CIRCUMFERENTIAL</Divider>
      <Form
        form={form}
        onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '3')}
        labelCol={{ span: 10 }}
        labelAlign='left'
        wrapperCol={{ span: 10 }}
      >
        <Form.Item label='Upload' name={'sl'}>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              onProgress={onProgress}
              onPreview={(file) => previewImage(file, 8)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 8, filename: file.name, url: file.url })
              }}
              fileList={files?.circumferential}
              beforeUpload={(file) => beforeUpload(file, 'inspection', files?.circumferential)}
              accept={filterFileType('inspection', 3)}
              customRequest={(options) =>
                saveImage(
                  options,
                  `patientdata/${id}/${lastDno}/inspection/circumferential/`,
                  8,
                  () =>
                    formData(
                      `/editor/manu/insp/${id}/${ampSelected}/${lastDno}/`,
                      undefined,
                      'get'
                    ),
                  3
                )
              }
              listType='picture'
              onRemove={(image) =>
                triggerMultiDelete(image, () =>
                  setFiles((prev) => ({
                    ...prev,
                    circumferential: files.circumferential?.filter((img) => img.uid !== image?.uid),
                  }))
                )
              }
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('inspection', 3)}
            </Upload>
            {infoHelper(
              nestData?.form_data?.file3?.upload_staff,
              nestData?.form_data?.file3?.upload_date
            )}
          </div>
        </Form.Item>
        <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
          {FormRenderer('select', {
            options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
          })}
        </Form.Item>
        {assessedBy(
          nestData?.form_data?.file3?.approval_staff,
          nestData?.form_data?.file3?.approval_date
        )}
        <Form.Item label='Memo' name='memo'>
          {FormRenderer('textarea')}
        </Form.Item>
         <Row gutter={6} justify='end'>
          <Col>
            <Button disabled={!files?.circumferential?.length} htmlType='submit' type='primary'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider orientation='center'>OQC</Divider>
      <Form
        form={formThree}
        onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '4')}
        labelCol={{ span: 10 }}
        labelAlign='left'
        wrapperCol={{ span: 10 }}
      >
        <Form.Item label='Upload' name={'sl'}>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              onProgress={onProgress}
              onPreview={(file) => previewImage(file, 8)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 8, filename: file.name, url: file.url })
              }}
              fileList={files?.OQC}
              beforeUpload={(file) => beforeUpload(file, 'inspection-oqc', files?.OQC)}
              accept={filterFileType('inspection', 4)}
              customRequest={(options) =>
                saveImage(
                  options,
                  `patientdata/${id}/${lastDno}/inspection/OQC/`,
                  8,
                  () =>
                    formData(
                      `/editor/manu/insp/${id}/${ampSelected}/${lastDno}/`,
                      undefined,
                      'get'
                    ),
                  4
                )
              }
              listType='picture'
              onRemove={(image) =>
                triggerMultiDelete(image, () =>
                  setFiles((prev) => ({
                    ...prev,
                    OQC: files.OQC?.filter((img) => img.uid !== image?.uid),
                  }))
                )
              }
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('inspection', 3)}
            </Upload>
            {infoHelper(
              nestData?.form_data?.file4?.upload_staff,
              nestData?.form_data?.file4?.upload_date
            )}
          </div>
        </Form.Item>
        <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
          {FormRenderer('select', {
            options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
          })}
        </Form.Item>
        {assessedBy(
          nestData?.form_data?.file4?.approval_staff,
          nestData?.form_data?.file4?.approval_date
        )}
        <Form.Item label='Memo' name='memo'>
          {FormRenderer('textarea')}
        </Form.Item>
         <Row gutter={6} justify='end'>
          <Col>
            <Button disabled={!files?.OQC?.length} htmlType='submit' type='primary'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
