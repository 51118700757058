

const dataSource = [
  {
    key: '1',
    id: '112',
    patient_no: 'Patient 1',
    patient_name: 32,
    dno: '10',
    type: 'BK-EXO',
    stump_status: 'Not Selected',
    done: 'Yes',
    Action: '',
  },
]

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Patient Number',
    dataIndex: 'patient_no',
    key: 'patient_no',
  },
  {
    title: 'Patient Name',
    dataIndex: 'patient_name',
    key: 'patient_name',
  },
  {
    title: 'DNO',
    dataIndex: 'dno',
    key: 'dno',
  },
  {
    title: 'Type',
    dataIndex: 'pro_type',
    key: 'pro_type',
  },
  {
    title: 'Stmp Status',
    dataIndex: 'stump_status',
    key: 'stump_status',
  },
  {
    title: 'Done',
    dataIndex: 'done',
    key: 'done',
    render: (text) => String(text),
  },
  // {
  //   title: 'Action',
  //   dataIndex: 'action',
  //   key: 'action',
  //   render: (_) => (
  //     <>
  //       <Button primary shape='round'>
  //         Edit
  //       </Button>
  //     </>
  //   ),
  // },
]

export const diagnosisData = {
  dataSource,
  columns,
}
