import { Layout, Form, Button, Row, Col, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import FormRenderer from '../Form/formRenderer'
import {
  editStaffForm
} from '../Form/forms'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useFormData from '../../hooks/useForms'
import { createOptions } from '../../utils/helpers'

const { Content } = Layout


export default function EditStaff() {
  const [params] = useSearchParams()
  const id = params.get('id')
  const isRegister = !id
  const path = isRegister?'register':''
  const [form] = Form.useForm()
  const [optionList, setOptionList] = useState([]) 
  const [initialData, setInitialData] = useState({}) 
  const navigate = useNavigate()

  const { data, formData, loading } =  useFormData('staff', path , id,'','get')

  useEffect(() => {
     if(!isRegister)
      form.setFieldsValue(data)
     else
     {
      let obj={};
      editStaffForm.forEach((value)=>
      {
        obj[value.name]=value.newValue
      })
      setInitialData(obj)
      form.setFieldsValue(obj)
      setOptionList(data)
     }
      
  }, [form, data,isRegister])

  const submitForm = (values, form) => {
    values['office_id']=JSON.stringify(values['office_id'])
    isRegister?formData('register',values,'post',onSuccess):formData('update', values, 'put',onSuccess)
  }
  
  const onSuccess = () => {
    navigate('/settings/staff')
  } 

  return (
    
    <Spin spinning={loading}>
      <Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        
        <Form
                form={form}
                onFinish={(values) => submitForm(values)}
                initialValues={isRegister?initialData: data}
                labelCol={{ span: 4 }}
                labelAlign='left'
                wrapperCol={{ span: 6 }}
              >
        {editStaffForm?.map((field, idx) => (
          (isRegister||(field.label!=='Office'&&field.label!=='Password' && field.label!=='Company'))&&<Form.Item
            key={idx + field.name}
            {...field}
            value={field.type==='checkbox'??false}
            valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}
          >
            {FormRenderer(field.type, {
              ...field,
              options: createOptions(optionList?.[field?.optionKey]),
            })}

          </Form.Item>
        ))}
                <Row gutter={6} justify='end'>
                  <Col>
                    <Button onClick={() => navigate('/settings/staff')}>Back to Staff</Button>
                  </Col>
                  <Col>
                    <Button htmlType='submit' type='primary'>
                      {isRegister?'Add Staff':'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
      </Content>
    </Spin>  
    
  )
}
