import { Button, Space, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React from 'react'

const getColumnSearchProps = (title, key, handleUpdateFilter, getFilteredData, filterControl) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        placeholder={`Search ${title}`}
        value={filterControl[key]}
        onChange={(e) => {
          handleUpdateFilter(key, e.target.value)
        }}
        onPressEnter={() => {
          getFilteredData(null)
          confirm()
        }}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type='primary'
          onClick={() => {
            getFilteredData(null)
            confirm()
          }}
          icon={<SearchOutlined />}
          size='small'
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            handleUpdateFilter(key, '')
            const filters = { ...filterControl, [key]: '' }
            getFilteredData(filters)
            confirm()
          }}
          size='small'
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: () => {
    return <SearchOutlined
      style={{
        color: filterControl[key] ? '#1890ff' : undefined,
      }}
    />
    },
})

export default getColumnSearchProps
