import { Button, Form, Upload } from 'antd'
import React from 'react'
import {
  assessedBy,
  beforeUpload,
  filterFileType,
  formatHelper,
  infoHelper,
} from '../../../../utils/helpers'
import FormRenderer from '../../formRenderer'
import usePreview from '../components/ImagePreview/usePreview'
import { UploadWrapper } from '../style'
import { UploadOutlined } from '@ant-design/icons'

export default function Lamination({
  showDownloadProps,
  onProgress,
  id,
  ampSelected,
  previewImage,
  dno,
  saveImage,
  lastDno,
  nestData,
  formData,
  getOptions,
  diagnosisNestedForm,
  form,
  handleDiagNestSubmission,
  files,
  setInputFiles,
  deleteMedia,
}) {
  const { onDownload } = usePreview()

  return (
    <>
      <Form
        form={form}
        onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '0')}
        labelCol={{ span: 10 }}
        labelAlign='left'
        wrapperCol={{ span: 10 }}
      >
        <Form.Item label='Upload'>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              onProgress={onProgress}
              onPreview={(file) => previewImage(file, 9)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 11, filename: file.name, url: file.url })
              }}
              fileList={files}
              beforeUpload={(file) => beforeUpload(file, 'lamination', files)}
              accept={filterFileType('lamination', 1)}
              customRequest={(options) =>
                saveImage(
                  options,
                  `patientdata/${id}/${lastDno}/Postitive Model for Lamination/`,
                  11,
                  () =>
                    formData(
                      `/editor/manu/lamination/${id}/${ampSelected}/${lastDno}/`,
                      undefined,
                      'get'
                    ),
                  0
                )
              }
              listType='picture'
              onRemove={(image) => deleteMedia(image, setInputFiles)}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('lamination', 1)}
            </Upload>
            {infoHelper(
              nestData?.form_data?.file1?.upload_staff,
              nestData?.form_data?.file1?.upload_date
            )}
          </div>
        </Form.Item>
        <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
          {FormRenderer('select', {
            options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
          })}
        </Form.Item>
        {assessedBy(
          nestData?.form_data?.file1?.approval_staff,
          nestData?.form_data?.file1?.approval_date
        )}
        <Form.Item label='Memo' name='memo'>
          {FormRenderer('textarea')}
        </Form.Item>
      </Form>
    </>
  )
}
