import { Row, Col, Input, Button, Table, Select } from 'antd'
import Column from 'antd/lib/table/Column'
import { useNavigate } from 'react-router'
import useTabViewData from '../../../../hooks/useView'
import { SIZE_CHANGER } from '../../../../utils/constants'
import { boardDateFormatter, constructNestApiUrl, detectFilePath } from '../../../../utils/helpers'
import { useCallback, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { renderOptions } from '../../../../utils/optionRenderer'

function NotUploaded() {
  const [filterControl, setFilters] = useState({})
  const { data, loading, totalPages, limit, setLimit, setPage, currentPage, filters, tableData } = useTabViewData(
    '',
    '/po-manufacture/not-uploaded/',
    'po-manufacture-not-uploaded',
    'post',
    filterControl
  )

  const navigate = useNavigate()

  const handleUpdateFilter = useCallback(
    (field, value) => {
      setFilters((existingFilters) => ({
        ...existingFilters,
        [field]: value,
      }))
    },
    [setFilters]
  )

  const getFilteredData = () => tableData('/po-manufacture/not-uploaded/', 1, limit, filterControl)

  return (
    <>
      <Row style={{ margin: '10px 0' }} gutter={4}>
        <Col span={6}>
          <Input
            onChange={({ target }) => handleUpdateFilter('patient_name', target.value)}
            placeholder='Name'
            allowClear
          />
        </Col>
        <Col span={6}>
          <Input
            onChange={({ target }) => handleUpdateFilter('patient_no', target.value)}
            placeholder='No'
            allowClear
          />
        </Col>
        <Col>
          <Select
            onChange={(value) => handleUpdateFilter('kind', value)}
            placeholder='File Type'
            allowClear
            dropdownStyle={{ minWidth: 'max-content' }}
          >
            {renderOptions(filters, 'kind_list')}
          </Select>
        </Col>
        <Button onClick={getFilteredData} type='primary' shape='circle' icon={<SearchOutlined />} />
      </Row>
      <Table
        pagination={{
          total: totalPages,
          pageSize: limit,
          current: currentPage,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (_, b) => {
            setLimit(Number(b))
          },
          pageSizeOptions: SIZE_CHANGER,
        }}
        loading={loading}
        bordered
        dataSource={data}
      >
        <Column title='Id' dataIndex='id' key='id' />
        <Column title='Patient Name' dataIndex='patient_name' key='patient_name' />
        <Column title='Patient No' dataIndex='patient_no' key='patient_no' />
        <Column title='ANO' dataIndex='amp_no' key='amp_no' />
        <Column title='DNO' dataIndex='dno' key='dno' />
        <Column title='File Type' dataIndex='kind_subno' key='kind_subno' />
        <Column
          title='Upload date'
          dataIndex='upload_date'
          key='upload_date'
          render={(val) => boardDateFormatter(val)}
        />
        <Column title='Status' dataIndex='status' key='status' />

        <Column
          title='Action'
          key='action'
          render={(_, rec) => (
            <Button
              onClick={() => {
                const [nestedForm, typeValue] = detectFilePath(rec)
                const url = constructNestApiUrl(nestedForm, rec.p, rec.amp_no, rec.dno)
                navigate(`/form?tab=amp&id=${rec.p}`, {
                  state: {
                    tab: 'diagnosis',
                    nestedTab: nestedForm,
                    dno: rec.dno,
                    selectedAmp: rec.amp_no,
                    redirection: true,
                    url,
                    stopEffect: true,
                    typeValue,
                  },
                })
              }}
              primary
              shape='round'
            >
              Edit
            </Button>
          )}
        />
      </Table>
    </>
  )
}

export default NotUploaded
