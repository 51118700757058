import { boardDateFormatter } from '../../../utils/helpers'

const dataSource = [
  {
    key: '1',
    id: '112',
    patient_name: 'Patient 1',
    patient_no: 32,
    dno: '10',
    ano: '10',
    type: 'BK-EXO',
    upload_staff: 'Manager',
    upload_date: new Date().toLocaleDateString(),
    approval_staff: 'Manager',
    approval_date: new Date().toLocaleDateString(),
    status: 'Done',
    Action: '',
  },
]

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Patient Number',
    dataIndex: 'patient_no',
    key: 'patient_no',
  },
  {
    title: 'Patient Name',
    dataIndex: 'patient_name',
    key: 'patient_name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'ANO',
    dataIndex: 'amp_no',
    key: 'amp_no',
  },
  {
    title: 'DNO',
    dataIndex: 'dno',
    key: 'dno',
  },
  {
    title: 'Type',
    dataIndex: 'pro_type_name',
    key: 'pro_type_name',
  },
  {
    title: 'File Type',
    dataIndex: 'kind_subno',
    key: 'kind_subno',
  },
  {
    title: 'Upload Staff',
    dataIndex: 'upload_staff',
    key: 'upload_staff',
  },
  {
    title: 'Upload Date',
    dataIndex: 'upload_date',
    key: 'upload_date',
    render: (val) => boardDateFormatter(val),
    // sorter: true
  },
  {
    title: 'Approval Staff',
    dataIndex: 'approval_staff',
    key: 'approval_staff',
  },
  {
    title: 'Approval Date',
    dataIndex: 'approval_date',
    key: 'approval_date',
    render: (val) => boardDateFormatter(val),
  },
  //  {
  //   title: 'Action',
  //   dataIndex: 'action',
  //   key: 'action',
  //   render: (data) => <>
  //       <Button style={{width: 80}} primary shape='round'>Edit</Button>
  //   </>
  // }
]

export const fileData = {
  dataSource,
  columns,
}
