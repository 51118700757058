import {
    Button,
    Col,
    Input,
    Layout,
    message,
    Modal,
    Row,
    Select,
    Tabs,
    Typography,
    Upload,
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import RichTextEditor from 'react-rte'
import { beforeUpload, formImageList, isAdmin } from '../../utils/helpers'
import { renderOptions } from '../../utils/optionRenderer'
import {
    assigendByAdmin,
    assignIssue,
    getAllAssignedIssues,
    getAllOpenIssues,
    getAllResolvedIssues,
    getInitialData,
    getIssue,
    postNewIssue,
    resolveIssue,
} from './Contact.network'
import { EditOutlined } from '@ant-design/icons'
import IssueGrid from './components/IssueGrid/IssueGrid'
import { useSearchParams } from 'react-router-dom'
import Store from '../../utils/authHelper'
import { ACHILLI_ID } from '../../utils/constants'
import { Rte } from './style'

const { Content } = Layout
const { TabPane } = Tabs
const INITIAL_DETAILS = RichTextEditor.createEmptyValue()

const ContactPage = () => {
    const [params, setParams] = useSearchParams()
    const isViewMode = params.get('view')
    const issueId = params.get('id')
    const [showForm, setShowForm] = useState(false)
    const [details, setDetails] = useState(INITIAL_DETAILS)
    const [activeTab, setTab] = useState('open_issues')
    const [gridData, setGridData] = useState([])
    const [category, setCategory] = useState()
    const [subject, setSubject] = useState('')
    const [attachment, setAttachment] = useState()
    const [otherCategory, setOtherCategory] = useState()
    const [newInitialData, setInitialData] = useState({
        categories_list: {},
        status_list: {},
    })
    const [issuePosting, setPosting] = useState(false)
    const [staff, setStaff] = useState({})
    const [assignedStaff, setAssignedStaff] = useState()
    const [raisedBy, setRaisedBy] = useState()

    const setTabHandler = async (tabValue) => {
        let action
        let tabV = tabValue
        setParams((prev) => [...prev.entries(), ['tab', tabValue]])
        switch (tabValue) {
            case 'open_issues':
                action = getAllOpenIssues
                break
            case 'assigned_issues':
                action = getAllAssignedIssues
                break
            case 'assigned_by_admin_issues':
                action = assigendByAdmin
                break
            default:
                action = getAllResolvedIssues
        }

        const getTabData = await action()
        setGridData(getTabData?.data?.[tabV] || [])
        setTab(tabValue)
    }

    const resetForm = () => {
        setDetails(INITIAL_DETAILS)
        setSubject('')
        setAttachment()
        setOtherCategory()
        setStaff({})
        setAssignedStaff()
    }

    const saveIssue = async () => {
        try {
            const body = {
                category,
                subject,
                attachments: attachment?.[0],
                details: details.toString('html'),
                other_category_reason: otherCategory,
            }
            const res = await postNewIssue(body)
            setShowForm(false)
            message.success(res?.data?.Message)
            resetForm()
            const getTabData = await getAllOpenIssues()

            setGridData(getTabData?.data?.['open_issues'] || [])
            setTab('open_issues')
        } catch (error) { }
    }

    const assignIssueFn = async () => {
        await assignIssue(issueId, assignedStaff)
        message.success('Issue Successfuly Assigned')
        setShowForm(false)
        setParams({})
        const getTabData = await getAllOpenIssues()

        setGridData(getTabData?.data?.['open_issues'] || [])
        setTab('open_issues')
    }

    const onSave = async () => {
        setPosting(true)
        const fn = isViewMode ? assignIssueFn : saveIssue
        await fn()
        setPosting(false)
        resetForm()
    }

    const onResolve = async () => {
        setPosting(true)
        await resolveIssue(issueId)
        setParams({})
        setShowForm(false)
        resetForm()
        message.success('Issue has been marked as resolved')
        await setTabHandler(activeTab)
        setPosting(false)
    }

    const fetchIssueDetails = async (id) => {
        setParams([...params.entries(), ['view', true], ['id', id]])
        setPosting(true)
        const details = await getIssue(id)
        const { data } = details
        setDetails(RichTextEditor.createValueFromString(data?.issue?.details, 'html'))
        setCategory(data?.issue?.category_id)
        setOtherCategory(data?.issue?.other_category_reason)
        setSubject(data?.issue?.subject)
        setAttachment(formImageList(data?.s3_files))
        setStaff(data?.form_data)
        setShowForm(true)
        setPosting(false)
        setRaisedBy(data?.issue?.raised_by)
    }
    const initialData = useCallback(async () => {
        setPosting(true)
        const intialD = await getInitialData()
        setInitialData(intialD?.data)
        setPosting(false)
    }, [])

    const shouldSeeFooter = () => {
        if (
            isViewMode &&
            (activeTab === 'assigned_by_admin_issues' || activeTab === 'resolved_issues')
        ) {
            return false
        }
        return true
    }

    const shouldNotSave = () => activeTab !== 'assigned_issues'

    const isRaisedByMe = (raised_id) => Number(Store.getFromLocal(ACHILLI_ID)) === Number(raised_id)

    const shouldSeeResolve = () => {
        const raisedByMe = isRaisedByMe(raisedBy)
        const tabIsOnOpen = activeTab === 'open_issues' || activeTab === 'assigned_issues'
        return raisedByMe && issueId && isViewMode && tabIsOnOpen
    }

    const shouldAssign = () => isAdmin() && isViewMode && activeTab === 'open_issues'

    const shouldNotProceed = () => {
        if (!category || !subject || !details.getEditorState().getCurrentContent().hasText()) return true

        return false
    }

    useEffect(() => {
        setTabHandler(activeTab)
        initialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Content style={{ margin: '0 2rem' }}>
            <Row align='middle' justify='space-between' style={{ position: 'relative' }}>
                <Tabs  style={{ width: '100%' }} value={activeTab} onChange={setTabHandler}>
                    <TabPane tab='Open' key='open_issues'>
                        <Row>
                            <Col span={24}>
                                <IssueGrid
                                    loading={issuePosting}
                                    data={gridData}
                                    fetchIssueDetails={fetchIssueDetails}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab='Assigned' key='assigned_issues'>
                        <Row>
                            <Col span={24}>
                                <IssueGrid
                                    loading={issuePosting}
                                    data={gridData}
                                    fetchIssueDetails={fetchIssueDetails}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab='Resolved' key='resolved_issues'>
                        <Row>
                            <Col span={24}>
                                <IssueGrid
                                    loading={issuePosting}
                                    data={gridData}
                                    fetchIssueDetails={fetchIssueDetails}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab='Assignmed To Me' key='assigned_by_admin_issues'>
                        <Row>
                            <Col span={24}>
                                <IssueGrid
                                    loading={issuePosting}
                                    data={gridData}
                                    fetchIssueDetails={fetchIssueDetails}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
                <Button
                    style={{ position: 'absolute', top: 10, right: 0 }}
                    onClick={() => setShowForm(true)}
                    type='primary'
                >
                    Create New Issue
                </Button>
            </Row>

            <Modal
                width={'60%'}
                visible={showForm}
                onCancel={() => {
                    setShowForm(false)
                    setParams({})
                    resetForm()
                }}
                footer={
                    shouldSeeFooter() && [
                        <div>
                            {shouldSeeResolve() && (
                                <Button loading={issuePosting} type='primary' onClick={onResolve} key='1-resolve'>
                                    Resolve
                                </Button>
                            )}
                            {shouldNotSave() && (
                                <>
                                    <Button
                                        onClick={() => {
                                            setShowForm(false)
                                            setParams({})
                                            resetForm()
                                        }}
                                        key='2-cancel'
                                    >
                                        Cancel
                                    </Button>
                                    <Button disabled={shouldNotProceed()} type='primary' loading={issuePosting} onClick={onSave} key='3-save'>
                                        Save
                                    </Button>
                                </>
                            )}
                        </div>,
                    ]
                }
                confirmLoading={issuePosting}
            >
                <Row style={{ margin: '1rem 0' }}>
                    <Col span={12}>
                        <Typography.Text>Category</Typography.Text>
                    </Col>
                    <Col span={12}>
                        <Select
                            disabled={isViewMode}
                            value={category}
                            onChange={(v) => setCategory(v)}
                            style={{ width: '100%' }}
                        >
                            {renderOptions(newInitialData, 'categories_list')}
                        </Select>
                    </Col>
                    {category === 99 && (
                        <Row style={{ margin: '0.5rem 0', width: '100%' }}>
                            <Col span={12}>Other Category Reason</Col>
                            <Col span={12}>
                                <Input
                                    disabled={isViewMode}
                                    value={otherCategory}
                                    onChange={(e) => setOtherCategory(e.target.value)}
                                />
                            </Col>
                        </Row>
                    )}
                </Row>
                <Row style={{ margin: '0.5rem 0' }}>
                    <Col span={12}>
                        <Typography.Text>Subject</Typography.Text>
                    </Col>
                    <Col span={12}>
                        <Input
                            disabled={isViewMode}
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row style={{ margin: '0.5rem 0' }}>
                    <Col span={12}>
                        <Typography.Text>Attachments</Typography.Text>
                    </Col>
                    <Col span={12}>
                        <Upload
                            disabled={isViewMode}
                            onRemove={() => setAttachment()}
                            showUploadList={true}
                            fileList={attachment}
                            listType='picture-card'
                            accept={['.jpg, .jpeg, .png']}
                            beforeUpload={(file) => {
                                const isAllowed = beforeUpload(file, 'profilePic') //profilrPic type suits the need
                                if (isAllowed) {
                                    setAttachment([file])
                                }
                                return false
                            }}
                        >
                            <EditOutlined style={{ cursor: 'pointer' }} />
                        </Upload>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Typography.Text>Details</Typography.Text>
                    </Col>
                    <Col span={24}>
                        <RichTextEditor editorClassName={Rte} disabled={isViewMode} value={details} onChange={(v) => setDetails(v)} />
                    </Col>
                </Row>
                {shouldAssign() && (
                    <Row style={{ margin: '1rem 0' }}>
                        <Col span={12}>
                            <Typography.Text>Assign To</Typography.Text>
                        </Col>
                        <Col span={12}>
                            <Select
                                value={assignedStaff}
                                onChange={(v) => setAssignedStaff(v)}
                                style={{ width: '100%' }}
                            >
                                {renderOptions(staff, 'assign_staff_list')}
                            </Select>
                        </Col>
                    </Row>
                )}
            </Modal>
        </Content>
    )
}

export default ContactPage
