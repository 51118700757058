import { Row, Col, Input, Select, Table, Button, Form } from 'antd'
// import { stumpData } from './__mock__data/stump'
import { SearchOutlined } from '@ant-design/icons'
import useTabViewData from '../../hooks/useView'
import { SIZE_CHANGER } from '../../utils/constants'
import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { boardDateFormatter } from '../../utils/helpers'
import { DatePicker } from 'antd'
import moment from 'moment'
import { renderOptions } from '../../utils/optionRenderer'
import { SET_PATIENT_NAME } from '../../context/constants'
import GlobalContext from '../../context/global'

const { ColumnGroup, Column } = Table
const { RangePicker } = DatePicker

function Stumptab({ patientToSearch }) {
  const dispatch = useContext(GlobalContext.GlobalContextDispath)
  const [filterControl, setFilters] = useState({
    patient_name: patientToSearch || '',
  })
  const navigate = useNavigate()
  const { data, loading, totalPages, limit, setLimit, setPage, currentPage, filters, tableData } =
    useTabViewData('', '/list/stump/', 'stumps', 'post', filterControl)

  const handleUpdateFilter = useCallback(
    (field, value) => {
      setFilters((existingFilters) => ({
        ...existingFilters,
        [field]: value,
      }))
      if (field === 'patient_name') {
        dispatch({
          type: SET_PATIENT_NAME,
          payload: { patientName: value },
        })
      }
    },
    [setFilters, dispatch]
  )

  const dateRangeSelector = useCallback(
    (field, value) => {
      const [startDate, endDate] = value
      const dateRangeString = `${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format(
        'YYYY-MM-DD'
      )}`
      setFilters((existingFilters) => ({
        ...existingFilters,
        [field]: dateRangeString,
      }))
    },
    [setFilters]
  )

  const placeholder = useCallback((field) => {
    const holder = {
      search_date_sales: ['Sales Start Date', 'Sales End Date'],
      search_date_po: ['PO Start Date', 'PO End Date'],
      search_date_pomng: ['PO Manager Start Date', 'PO Manager End Date'],
    }
    return holder[field]
  }, [])

  const getFilteredData = () => tableData('/list/stump/', 1, limit, filterControl)

  const submitForm = (e) => {
    if (e.keyCode === 13) {
      getFilteredData()
    }
  }

  return (
    <>
      <Form style={{ width: '100%' }} onKeyUp={submitForm}>
        <Row style={{ margin: '10px 0' }} gutter={5}>
          <Col>
            <Input
              value={patientToSearch || ''}
              onChange={({ target }) => handleUpdateFilter('patient_name', target.value)}
              placeholder='Name'
            />
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('pro_type', value)}
              placeholder='Type'
              allowClear
            >
              {renderOptions(filters, 'pro_types')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('upload_staff', value)}
              placeholder='Upload Staff'
              allowClear
            >
              {renderOptions(filters, 'sales_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('po_status', value)}
              placeholder='PO Status'
              allowClear
              dropdownStyle={{ minWidth: 'max-content' }}
            >
              {renderOptions(filters, 'status_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('po_staff_approval', value)}
              placeholder='PO Staff'
              allowClear
            >
              {renderOptions(filters, 'po_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('po_mng_status', value)}
              placeholder='PO Manager Status'
              allowClear
            >
              {renderOptions(filters, 'status_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('po_mng_staff_approval', value)}
              placeholder='PO Manager Staff'
              allowClear
            >
              {renderOptions(filters, 'po_mng_list')}
            </Select>
          </Col>
          <Col>
            <Select
              onChange={(value) => handleUpdateFilter('done', value)}
              placeholder='Done'
              allowClear
            >
              {renderOptions(filters, 'done_list')}
            </Select>
          </Col>
          <Col style={{ display: 'flex' }}>
            {['search_date_sales', 'search_date_po', 'search_date_pomng'].map((field) => (
              <Col xs={6}>
                <RangePicker
                  allowClear={false}
                  onChange={(val) => dateRangeSelector(field, val)}
                  placeholder={placeholder(field)}
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [moment(), moment().endOf('month')],
                    'Last Month': [
                      moment().subtract(1, 'month').startOf('month'),
                      moment().subtract(1, 'month').endOf('month'),
                    ],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                  }}
                />
              </Col>
            ))}
            <Button
              onClick={getFilteredData}
              type='primary'
              shape='circle'
              icon={<SearchOutlined />}
            />
          </Col>
        </Row>
      </Form>
      <Table
        pagination={{
          total: totalPages,
          pageSize: limit,
          current: currentPage,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (_, b) => {
            setLimit(Number(b))
          },
          pageSizeOptions: SIZE_CHANGER,
        }}
        loading={loading}
        bordered
        dataSource={data}
        style={{ width: '100%' }}
      >
        <Column title='Id' dataIndex='id' key='id' />
        <Column title='Patient Name' dataIndex='patient_name' key='patient_name' />
        <Column title='No' dataIndex='amp_no' key='amp_no' />
        <Column title='Type' dataIndex='pro_type_name' key='pro_type_name' />
        <ColumnGroup title='Upload'>
          <Column title='Staff' dataIndex='upload_staff' key='upload_staff' />
          <Column
            title='Date'
            dataIndex='upload_stump_date'
            key='upload_stump_date'
            render={(val) => boardDateFormatter(val)}
          />
        </ColumnGroup>
        <ColumnGroup title='PO'>
          <Column title='Status' dataIndex='status_po' key='status_po' />
          <Column title='Staff' dataIndex='approval_po_staff' key='approval_po_staff' />
          <Column
            title='Date'
            dataIndex='approval_po_date'
            key='approval_po_date'
            render={(val) => boardDateFormatter(val)}
          />
        </ColumnGroup>
        <ColumnGroup title='PO Manager'>
          <Column title='Status' dataIndex='status_mng' key='status_mng' />
          <Column title='Staff' dataIndex='approval_mng_staff' key='approval_mng_staff' />
          <Column
            title='Date'
            dataIndex='approval_mng_date'
            key='approval_mng_date'
            render={(val) => boardDateFormatter(val)}
          />
        </ColumnGroup>
        <Column title='Done' dataIndex='done' key='done' render={(val) => String(val)} />
        <Column
          title='Action'
          key='action'
          render={(_, rec) => (
            <Button
              onClick={() =>
                navigate(`/form?tab=amp&id=${rec.p}`, {
                  state: {
                    tab: 'stump',
                    url: `/editor/stump/${rec.p}/${rec.amp_no}/`,
                    selectedAmp: rec.amp_no,
                    redirection: true,
                  },
                })
              }
              primary
              shape='round'
            >
              Edit
            </Button>
          )}
        />
      </Table>
    </>
  )
}

export default Stumptab
