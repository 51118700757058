import { Breadcrumb, Layout, Tabs, Form, Row, Col, Spin, Button, Divider } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import FormRenderer from './formRenderer'
import { mainForm } from './forms'
import { useSearchParams } from 'react-router-dom'
import {
  createOptions,
  formatDateForBE,
  isDateValid,
  serializeQueryParams,
  valueFormatter,
} from '../../utils/helpers'
import useFormData from '../../hooks/useForms'
import moment from 'moment'
import BasicForm from './Basic'
import SalesForm from './Sales'
import SubscriptionForm from './Subscription'
import AmputationForm from './Amputation'
import Store from '../../utils/authHelper'
import { ACHILLI_OFFICE, ACHILLI_ROLE } from '../../utils/constants'
import GaitAnalysis from './GaitAnalysis'
import CustomTabBar from '../../components/CustomTabPanel'
import { EditOutlined } from '@ant-design/icons'
import { http } from '../../network/axiosInstance'
import GlobalContext from '../../context/global'
import { SET_PATIENT_INFO } from '../../context/constants'
import AddPatient from '../Patients/AddPatient'

const { Content } = Layout
const { TabPane } = Tabs

export default function Forms() {
  const [params, setParams] = useSearchParams()
  const [patientDetais, setPatientDetails] = useState({})
  const tab = params.get('tab')
  const id = params.get('id')
  const dispatch = useContext(GlobalContext.GlobalContextDispath)

  const [activeTab, setActiveTab] = useState(tab ?? 'sales')

  const [form] = Form.useForm()

  const { data, formData, loading } = useFormData(activeTab === 'gaitanalysis' ? 'ai' : '', tab, id)

  const role = Store.getObjectFromLocal(ACHILLI_ROLE)
  const office = Store.getFromLocal(ACHILLI_OFFICE)

  useEffect(() => {
    if (tab === 'sub') {
      return form.setFieldsValue({
        is_subscription: data?.[tab]?.is_subscription,
        sub_end_date: isDateValid(data?.[tab]?.sub_end_date)
          ? moment(data?.[tab]?.sub_end_date)
          : undefined,
        sub_start_date: isDateValid(data?.[tab]?.sub_start_date)
          ? moment(data?.[tab]?.sub_start_date)
          : undefined,
      })
    }
    if (tab === 'basic') {
      const basicData = { ...data?.basic }
      basicData.birthday = isDateValid(data?.basic?.birthday) ? data?.basic?.birthday : undefined
      return form.setFieldsValue(basicData)
    }
    form.setFieldsValue(data?.[tab])
  }, [form, data, tab])

  // Use this effect to store pat ingo in state var, needed because getting emptioed in nested forms
  useEffect(() => {
    if (!data?.patient_info) return
    setPatientDetails(data?.patient_info)
  }, [data?.patient_info])

  const submitForm = (values, form, onSuccess) => {
    if (form === 'sub') {
      values.sub_end_date = formatDateForBE(values.sub_end_date)
      values.sub_start_date = formatDateForBE(values.sub_start_date)
    } else if (form === 'basic') {
      values.birthday = formatDateForBE(values.birthday)
    } else if (form === 'amp') {
      values.date = formatDateForBE(values.date)
    }
    formData(tab, values, 'post', onSuccess)
  }

  const showPatientModalWithDetails = async () => {
    const patientDetails = await http.get(`/editor/patient/${id}`)
    const payload = {
      patientName: patientDetails?.data?.patient?.patient_name,
      patientAge: patientDetails?.data?.patient?.age,
      showModal: true
    }

    dispatch({
      type: SET_PATIENT_INFO,
      payload
    })
  }


  const panes = [
    { key: 'sales', tab: 'Sales', link: `/form?tab=sales&id=${id}` },
    { key: 'basic', tab: 'Basic', link: `/form?tab=basic&id=${id}` },
    { key: 'amp', tab: 'Amputation', link: `/form?tab=amp&id=${id}` },
    { key: 'sub', tab: 'Subscription', link: `/form?tab=sub&id=${id}` },
    { key: 'gaitanalysis', tab: 'Gait Analysis', link: `/form?tab=gaitanalysis&id=${id}` },
  ];

  return (
    <Layout
      style={{
        padding: '0 24px 24px',
      }}
    >
      <Spin spinning={loading}>
        <Breadcrumb
          separator='>'
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Form</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <Form labelAlign='left' wrapperCol={{ span: 14 }}>
            <Row>
              {mainForm?.map((field) => (
                <Col span={10}>
                  <Form.Item key={field.name} rules={field.rules} label={field.label}>
                    {FormRenderer(field.type, {
                      ...field,
                      value: valueFormatter(field.type, patientDetais?.[field.name]),
                      options: createOptions(data?.form_data?.[field?.optionKey]),
                    })}
                  </Form.Item>
                </Col>
              ))}
              <Button onClick={showPatientModalWithDetails} shape='circle' icon={<EditOutlined />} />
            </Row>
          </Form>
          <Divider />
          <Tabs
            
            defaultActiveKey={activeTab}
            renderTabBar={(props) => (
              <CustomTabBar onChange={(tab) => {
                setParams({
                  ...serializeQueryParams(params),
                  tab,
                })
                setActiveTab(tab)
              }} {...props} panes={panes} />
            )}
            activeKey={activeTab}
          >
            <TabPane tab='Sales' key='sales'>
              {activeTab === 'sales' && (
                <SalesForm form={form} tab={tab} data={data} submitForm={submitForm} />
              )}
            </TabPane>
            {!(office === '2' && role?.includes('po')) && (
              <TabPane tab='Basic' key='basic'>
                {activeTab === 'basic' && (
                  <BasicForm form={form} tab={tab} data={data} submitForm={submitForm} />
                )}
              </TabPane>
            )}
            <TabPane tab='Amputation' key='amp'>
              {activeTab === 'amp' && (
                <AmputationForm
                  setPatientDetails={setPatientDetails}
                  id={id}
                  form={form}
                  tab={tab}
                  data={data}
                  submitForm={submitForm}
                />
              )}
            </TabPane>
            <TabPane tab='Subscription' key='sub'>
              {activeTab === 'sub' && (
                <SubscriptionForm form={form} tab={tab} data={data} submitForm={submitForm} />
              )}
            </TabPane>
            <TabPane tab='Gait Analysis' key='gaitanalysis'>
              {activeTab === 'gaitanalysis' && (
                <GaitAnalysis form={form} tab={tab} data={data} submitForm={formData} />
              )}
            </TabPane>
          </Tabs>
        </Content>
      </Spin>
      <AddPatient />
    </Layout>
  )
}
