import { Tabs } from 'antd'
import NotChecked from './NotChecked'
import NotDone from './NotDone'

const { TabPane } = Tabs
function PoStump() {
  return (
    <Tabs  defaultActiveKey='1' onChange={() => {}}>
      <TabPane tab='PO Stump: Not Checked' key='not-checked'>
        <NotChecked />
      </TabPane>
      <TabPane tab='PO Stump: Not Done' key='not-done'>
        <NotDone />
      </TabPane>
      
    </Tabs>
  )
}

export default PoStump
