import { Button, Col, Divider, Form, Row, Upload } from 'antd'
import React from 'react'
import {
  assessedBy,
  beforeUpload,
  filterFileType,
  formatHelper,
  infoHelper,
} from '../../../../utils/helpers'
import FormRenderer from '../../formRenderer'
import usePreview from '../components/ImagePreview/usePreview'
import { UploadWrapper } from '../style'
import { UploadOutlined } from '@ant-design/icons'

export default function TestSocket({
  showDownloadProps,
  onProgress,
  id,
  ampSelected,
  previewImage,
  dno,
  saveImage,
  lastDno,
  nestData,
  formData,
  triggerMultiDelete,
  getOptions,
  diagnosisNestedForm,
  formOne,
  formTwo,
  handleDiagNestSubmission,
  files,
  setFiles,
}) {
  const { onDownload } = usePreview()

  return (
    <>
      <Divider orientation='center'>CAD</Divider>
      <Form
        form={formOne}
        onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '1')}
        labelCol={{ span: 10 }}
        labelAlign='left'
        wrapperCol={{ span: 10 }}
      >
        <Form.Item label='Upload' name={'sl'}>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              onProgress={onProgress}
              onPreview={(file) => previewImage(file, 3)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 3, filename: file.name, url: file.url })
              }}
              fileList={files?.cad}
              accept={filterFileType('testsocket', 1)}
              beforeUpload={(file) => beforeUpload(file, 'testsocket', files?.cad, 1)}
              customRequest={(options) =>
                saveImage(
                  options,
                  `patientdata/${id}/${lastDno}/testsocket/cad/`,
                  3,
                  () =>
                    formData(
                      `/editor/manu/test/${id}/${ampSelected}/${lastDno}/`,
                      undefined,
                      'get'
                    ),
                  1
                )
              }
              multiple
              listType='picture'
              onRemove={(image) =>
                triggerMultiDelete(image, () =>
                  setFiles((prev) => ({
                    ...prev,
                    cad: files.cad?.filter((img) => img.uid !== image?.uid),
                  }))
                )
              }
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('testsocket', 1)}
            </Upload>
            {infoHelper(
              nestData?.form_data?.file1?.upload_staff,
              nestData?.form_data?.file1?.upload_date
            )}
          </div>
        </Form.Item>
        <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
          {FormRenderer('select', {
            options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
          })}
        </Form.Item>
        {assessedBy(
          nestData?.form_data?.file1?.approval_staff,
          nestData?.form_data?.file1?.approval_date
        )}
        <Form.Item label='Memo' name='memo'>
          {FormRenderer('textarea')}
        </Form.Item>
        <Row gutter={6} justify='end'>
          <Col>
            <Button disabled={!files?.cad?.length} htmlType='submit' type='primary'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider orientation='center'>G-CODE</Divider>
      <Form
        form={formTwo}
        onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '2')}
        labelCol={{ span: 10 }}
        labelAlign='left'
        wrapperCol={{ span: 10 }}
      >
        <Form.Item label='Upload' name={'sl'}>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              onProgress={onProgress}
              onPreview={(file) => previewImage(file, 3)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 3, filename: file.name, url: file.url })
              }}
              fileList={files?.gcode}
              accept={filterFileType('testsocket', 2)}
              beforeUpload={(file) => beforeUpload(file, 'testsocket', files?.gcode, 2)}
              customRequest={(options) =>
                saveImage(
                  options,
                  `patientdata/${id}/${lastDno}/testsocket/gcode/`,
                  3,
                  () =>
                    formData(
                      `/editor/manu/test/${id}/${ampSelected}/${lastDno}/`,
                      undefined,
                      'get'
                    ),
                  2
                )
              }
              multiple
              listType='picture'
              onRemove={(image) =>
                triggerMultiDelete(image, () =>
                  setFiles((prev) => ({
                    ...prev,
                    gcode: files.gcode?.filter((img) => img.uid !== image?.uid),
                  }))
                )
              }
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('testsocket', 2)}
            </Upload>
            {infoHelper(
              nestData?.form_data?.file2?.upload_staff,
              nestData?.form_data?.file2?.upload_date
            )}
          </div>
        </Form.Item>
        <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
          {FormRenderer('select', {
            options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
          })}
        </Form.Item>
        {assessedBy(
          nestData?.form_data?.file2?.approval_staff,
          nestData?.form_data?.file2?.approval_date
        )}
        <Form.Item label='Memo' name='memo'>
          {FormRenderer('textarea')}
        </Form.Item>
        <Row gutter={6} justify='end'>
          <Col>
            <Button disabled={!files?.gcode?.length} htmlType='submit' type='primary'>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider orientation='center'>CHECKSHEET</Divider>
      <Form labelCol={{ span: 10 }} labelAlign='left' wrapperCol={{ span: 10 }}>
        <Form.Item label='Upload' name={'sl'}>
          <div className={UploadWrapper}>
            <Upload
              {...showDownloadProps}
              onProgress={onProgress}
              onPreview={(file) => previewImage(file, 3)}
              onDownload={(file) => {
                onDownload({ id, dno, kind: 3, filename: file.name, url: file.url })
              }}
              fileList={files?.check_sheet}
              accept={filterFileType('testsocket', 3)}
              beforeUpload={(file) => beforeUpload(file, 'testsocket', files?.check_sheet, 3)}
              customRequest={(options) =>
                saveImage(
                  options,
                  `patientdata/${id}/${lastDno}/testsocket/Check Sheet/`,
                  3,
                  () =>
                    formData(
                      `/editor/manu/test/${id}/${ampSelected}/${lastDno}/`,
                      undefined,
                      'get'
                    ),
                  3
                )
              }
              multiple
              listType='picture'
              onRemove={(image) =>
                triggerMultiDelete(image, () =>
                  setFiles((prev) => ({
                    ...prev,
                    check_sheet: files.check_sheet?.filter((img) => img.uid !== image?.uid),
                  }))
                )
              }
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
              {formatHelper('testsocket', 3)}
            </Upload>
            {infoHelper(
              nestData?.form_data?.file3?.upload_staff,
              nestData?.form_data?.file3?.upload_date
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  )
}
