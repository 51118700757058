export const mainForm = [
    {
        label: 'Patient Number',
        type: 'input',
        name: 'patient_number',
        disabled: true,
    },
    {
        label: 'Patient Name',
        type: 'input',
        name: 'patient_name',
        disabled: true,
    },
]

export const salesForm = [
    {
        label: 'Sales Staff',
        type: 'select',
        name: 'sales_staff',
        optionKey: 'sales_staff_list',
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        label: 'Sales Channel',
        type: 'select',
        name: 'channel',
        optionKey: 'sales_channel_list',
    },
    {
        label: 'With Flyer',
        type: 'checkbox',
        name: 'flyer',
    },
    {
        label: 'With Prescription',
        type: 'checkbox',
        name: 'prescription',
    },
    {
        label: 'Commission price',
        type: 'number',
        name: 'kickback_price',
    },
]

export const basicForm = [
    {
        label: 'Birthday',
        type: 'date',
        name: 'birthday',
    },
    {
        label: 'Gender',
        type: 'select',
        name: 'gender',
        optionKey: 'gender_list',
    },
    {
        label: 'Living Area',
        type: 'input',
        name: 'area',
    },
    {
        label: 'Complete Address',
        type: 'textarea',
        name: 'address',
    },
    {
        label: 'Phone Number',
        type: 'input',
        name: 'phone_no',
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        label: 'Email',
        type: 'input',
        name: 'email',
    },
    {
        label: 'Facebook Account',
        type: 'input',
        name: 'fb_name',
    },
    {
        label: 'Facebook Link',
        type: 'input',
        name: 'fb_link',
    },
    {
        label: 'ID Type',
        type: 'select',
        name: 'id_type',
        optionKey: 'proof_id_list',
    },
    {
        label: 'ID Number',
        type: 'input',
        name: 'id_no',
    },
]

export const contactPersonForm = [
    {
        label: 'Relation',
        type: 'select',
        name: 'contact_relation',
        optionKey: 'relation',
    },
    {
        label: 'Name',
        type: 'input',
        name: 'contact_person',
    },
    {
        label: 'Phone',
        type: 'input',
        name: 'contact_phone',
    },
]

export const subscriptionForm = [
    {
        label: 'Subscription',
        type: 'checkbox',
        name: 'is_subscription',
    },
    {
        label: 'Start Date',
        type: 'date',
        name: 'sub_start_date',
    },
    {
        label: 'End Date',
        type: 'date',
        name: 'sub_end_date',
    },
]

export const gaitanalysisForm = [
    {
        label: 'Filter Options',
        type: 'select',
        name: 'filter_option',
        optionKey: 'filter_list',
    },
    {
        label: 'KPTS Options',
        type: 'select',
        name: 'kpts_option',
        optionKey: 'kpts_list',
    },
    {
        label: 'Is Vertical',
        type: 'checkbox',
        name: 'is_vertical',
    },
    {
        label: 'Heel Toe',
        type: 'checkbox',
        name: 'heel_toe',
    },
    {
        label: 'Video Output',
        type: 'checkbox',
        name: 'video_output',
    },
]

export const infoForm = [
    {
        label: 'Region',
        name: 'region',
        type: 'input',
        rules: [
            {
                required: true,
            },
        ],
        onlyForIndia: true
    },
    {
        label: 'Location',
        name: 'location',
        type: 'input',
        rules: [
            {
                required: true,
            },
        ],
        onlyForIndia: true
    },
    {
        label: 'Reason',
        name: 'reason',
        type: 'select',
        optionKey: 'reason_list',
    },
    {
        label: 'Other Reason',
        name: 'other_reason',
        //span: 16,
        type: 'textarea',
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        label: 'Date',
        name: 'date',
        type: 'date',
    },
    {
        label: 'Muscle Suture',
        name: 'muscle_suture',
        type: 'select',
        optionKey: 'muscle_suture_list',
    },
    {
        label: 'Skin Flap',
        name: 'skin_flap',
        type: 'select',
        optionKey: 'skin_flap_list',
    },
    {
        label: 'Side',
        name: 'side',
        type: 'select',
        rules: [
            {
                required: true,
            },
            {
                validator: (rule, value, cb) => {
                    value === 0 ? cb('Please Select Side') : cb()
                },
            },
        ],
        optionKey: 'side_list',
    },
    {
        label: 'Prosthesis Type',
        name: 'pro_type',
        type: 'select',
        rules: [
            {
                required: true,
            },
            {
                validator: (rule, value, cb) => {
                    value === 0 ? cb('Please Select Prosthesis') : cb()
                },
            },
        ],
        optionKey: 'pro_type_list',
    },
    {
        label: 'Zoho Patient Record ID',
        name: 'zoho_patient_record',
        type: 'input',
    },
]

export const stumpForm = [
    {
        label: 'Photos of stump',
        type: 'upload',
        name: 'stump_media',
        span: 16,
        props: {
            maxCount: 1,
            // disabled: true,
        },
    },
    {
        label: 'Memo',
        name: 'memo',
        span: 16,
        type: 'textarea',
        props: {
            autoSize: true,
            // disabled: true,
        },
    },
    {
        label: 'Done',
        name: 'done',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Check When Result Is Told To Patient',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Weight',
        name: 'weight',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Check if weight is within acceptable range',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Current Prosthesis',
        name: 'current_prosthesis',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Check if patient is currently using any prosthesis',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Dialysis',
        name: 'dialysis',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Check if patient is undergoing dialysis',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Paralysis',
        name: 'paralysis',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Check when patient has paralysis',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Stump Length',
        name: 'stump_length',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Checked = Okay',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Stump Width',
        name: 'stump_width',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Checked = Okay',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Stump Volume',
        name: 'stump_volume',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Checked = Okay',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Bony Distal End',
        name: 'bony_distal_end',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Checked = Okay',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Knee Flexion',
        name: 'knee_flexion',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Checked = Okay',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Hip Abduction',
        name: 'hip_abduction',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Checked = Okay',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Hip Flexion',
        name: 'hip_flexion',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Checked = Okay',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Thigh Width',
        name: 'thigh_width',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Checked = Okay',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Skin Condition',
        name: 'skin_condition',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Checked = Okay',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Bandaging',
        name: 'bandaging',
        span: 16,
        type: 'select',
        optionKey: 'bandage_list',
        props: {
            // disabled: true,
        },
    },
    {
        label: 'Bulb',
        name: 'bulb',
        span: 16,
        type: 'checkbox',
        hasTool: true,
        toolLabel: 'Checked = Need Video',
        props: {
            disabled: true,
        },
    },
    {
        label: 'Video of bulb',
        // type: 'upload',
        name: 'video',
        span: 13,

        props: {
            maxCount: 1,
            // disabled: true,
        },
    },
    {
        label: 'Photos of liner',
        // type: 'upload',
        name: 'liner',
        span: 13,

        props: {
            maxCount: 1,
            // disabled: true,
        },
    },
    {
        label: 'Status By PO',
        name: 'status_po',
        span: 24,
        subSpan: 11,
        type: 'select',
        optionKey: 'status_list',
        props: {
            // disabled: true,
        },
        assessed: true,
    },
    {
        label: 'Reject Reason',
        name: 'po_reject_reason',
        // type: 'textarea',
        span: 24,
        subSpan: 11,
        props: {
            autoSize: true,
            // disabled: true,
        },
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        label: 'Status By PO Manager',
        name: 'status_mng',
        span: 24,
        subSpan: 11,
        type: 'select',
        optionKey: 'status_list',
        props: {
            // disabled: true,
        },
        assessed: true,
    },
    {
        label: 'Reject Reason',
        name: 'po_mng_reject_reason',
        // type: 'textarea',
        span: 24,
        subSpan: 11,
        props: {
            autoSize: true,
            // disabled: true,
        },
        rules: [
            {
                required: true,
            },
        ],
    },
]

export const diagnosisForms = {
    d_stump: [
        {
            label: 'Stump Status',
            type: 'select',
            name: 'stump_status',
            optionKey: 'diagnosis_stump_status_list',
        },
        {
            label: 'Reject Reason',
            type: 'textarea',
            name: 'reject_reason',
            props: {
                autoSize: true,
            },
        },
    ],
    d_physical: {
        AK: [
            {
                label: 'Height (cm)',
                type: 'Decimal',
                name: 'height',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Weight (kg)',
                type: 'Decimal',
                name: 'weight',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Body Fat Ratio',
                type: 'Decimal',
                name: 'fat',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Stump Length (cm)',
                type: 'Decimal',
                name: 'stump',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Femur Length (cm)',
                type: 'Decimal',
                name: 'femur',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Length: MTP Lvel To Floor (cm)',
                type: 'Decimal',
                name: 'length_mtp',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Bare Foot Size (cm)',
                type: 'Decimal',
                name: 'foot_size',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Heel Height',
                type: 'Decimal',
                name: 'heel_height',
            },
            {
                label: 'IT Level (cm)',
                type: 'Decimal',
                name: 'it',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: '5cm Below IT Level (cm)',
                type: 'Decimal',
                name: 'it_5',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: '10cm Below IT Level (cm)',
                type: 'Decimal',
                name: 'it_10',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: '15cm Below IT Level (cm)',
                type: 'Decimal',
                name: 'it_15',
            },
            {
                label: '20cm Below IT Level (cm)',
                type: 'Decimal',
                name: 'it_20',
            },
            {
                label: '25cm Below IT Level (cm)',
                type: 'Decimal',
                name: 'it_25',
            },
            {
                label: 'Length: IT Level To MTP Level (cm)',
                type: 'Decimal',
                name: 'length_it',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Length: Adductor Longus To Rectus Femoris (cm)',
                type: 'Decimal',
                name: 'length_femoris',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Length: Ischial Tuberosity To Adductor Longus (cm)',
                type: 'Decimal',
                name: 'length_ischial',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Flexion Angle (degree)',
                type: 'Decimal',
                name: 'angle_flexion',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Abduction Angle (degree)',
                type: 'Decimal',
                name: 'angle_abduction',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Gluteal Pocket Depth (cm)',
                type: 'Decimal',
                name: 'gluteal',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Special Shape',
                type: 'textarea',
                name: 'special_shape',
            },
            {
                label: 'Note',
                type: 'textarea',
                name: 'physical_note',
            },
        ],
        BK: [
            {
                label: 'Height (cm)',
                type: 'Decimal',
                name: 'height',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Weight (kg)',
                type: 'Decimal',
                name: 'weight',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Body Fat Ratio',
                type: 'Decimal',
                name: 'fat',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Stump Length (cm)',
                type: 'Decimal',
                name: 'stump',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Peripheral-MPT Level (cm)',
                type: 'Decimal',
                name: 'peri_mtp',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Peripheral-Frontal Tibia (cm)',
                type: 'Decimal',
                name: 'peri_tibia',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Length: MTP Level To Floor (cm)',
                type: 'Decimal',
                name: 'length_mtp',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Bare Foot Size (cm)',
                type: 'Decimal',
                name: 'foot_size',
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: 'Heel Height (cm)',
                type: 'Decimal',
                name: 'heel_height',
            },
            {
                label: 'Special Shape',
                type: 'Decimal',
                name: 'special_shape',
            },
            {
                label: 'Note',
                type: 'textarea',
                name: 'physical_note',
            },
        ],
    },
    sense: [
        {
            label: 'With SL',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'file_status_list',
            optionKey: 'file_status_list',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'memo',
        },
        {
            label: 'No SL',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'file_status_list',
            optionKey: 'file_status_list',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'reject_reason',
        },
        {
            label: 'CheckSheet',
            type: 'upload',
            name: 'reject_reason',
        },
    ],
    eiscan: [
        {
            label: 'Upload',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'status',
            optionKey: 'file_status_list',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'memo',
        },
    ],
    testsocket: [
        {
            label: 'CAD',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'status',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'memo',
        },
        {
            label: 'G Code',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'status',
            optionKey: 'file_status_list',
        },
        {
            label: 'Check Sjeet',
            type: 'upload',
            name: 'memo',
        },
    ],
    final: [
        {
            label: 'CAD',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'status',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'memo',
        },
        {
            label: 'G Code',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'status',
            optionKey: 'file_status_list',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'memo',
        },
    ],
    picture: [
        {
            label: 'Front',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Side',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'status',
            optionKey: 'file_status_list',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'memo',
        },
    ],
    movie: [
        {
            label: 'Upload',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'status',
            optionKey: 'file_status_list',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'memo',
        },
    ],
    inspection: [
        {
            label: 'Strength Upload',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'memo',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'reject_reason',
        },
        {
            label: 'Height Upload',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'memo',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'reject_reason',
        },
        {
            label: 'Circumferential Upload',
            type: 'upload',
            name: 'reject_reason',
        },
        {
            label: 'Status',
            type: 'select',
            name: 'memo',
        },
        {
            label: 'Memo',
            type: 'textarea',
            name: 'reject_reason',
        },
    ],
    ai: [
        {
            label: 'Input',
            type: 'upload',
        },
        {
            label: 'Output',
            type: 'upload',
        },
    ],
    d_parts: {
        AK: [
            {
                label: 'Model',
                type: 'select',
                optionKey: 'models_list',
                name: 'model_id',
            },
            {
                label: 'Socket',
                type: 'divider',
                orientation: 'center',
            },
            {
                label: 'Type',
                type: 'select',
                name: 'socket_id',
            },
            {
                label: 'Color',
                type: 'input',
                name: 'socket_color',
            },
            {
                label: 'Interface',
                type: 'divider',
            },
            {
                label: 'Type',
                type: 'input',
                name: 'interface_id',
            },
            {
                label: 'Brand',
                type: 'input',
                name: 'brand',
            },
            {
                label: 'Size',
                type: 'input',
                name: 'interface_size',
            },
            {
                label: 'Counter Parts',
                type: 'input',
                name: 'interface_counter_parts',
            },
            {
                label: 'Foot',
                type: 'divider',
            },
            {
                label: 'Type',
                type: 'input',
                name: 'foot_id',
            },
            {
                label: 'Size',
                type: 'input',
                name: 'foot_size',
            },
            {
                label: 'Heel Height',
                type: 'input',
                name: 'foot_heel_height',
            },
            {
                label: 'Carbon Size',
                type: 'input',
                name: 'foot_carbon_size',
            },
            {
                label: 'Carbon Strength',
                type: 'input',
                name: 'foot_carbon_strength',
            },
            {
                label: 'Prosthetic',
                type: 'divider',
            },
            {
                label: 'Cover',
                type: 'input',
                name: 'prosthetic_cover_id',
            },
            {
                label: 'Cover option',
                type: 'input',
                name: 'prosthetic_cover_option',
            },
            {
                label: 'Knee',
                type: 'divider',
            },
            {
                label: 'Type',
                type: 'input',
                name: 'knee_id',
            },
            {
                label: 'Brand',
                type: 'input',
                name: 'knee_brand',
            },
            {
                label: 'Accessory',
                type: 'divider',
            },
            {
                label: 'Type',
                type: 'input',
                name: 'accessary_id',
            },
            {
                label: 'Size',
                type: 'input',
                name: 'accessary_size',
            },
        ],
        BK: [
            {
                label: 'Model',
                type: 'select',
                optionKey: 'models_list',
                name: 'model_id',
            },
            {
                label: 'Socket',
                type: 'divider',
            },
            {
                label: 'Type',
                type: 'select',
                name: 'socket_id',
                optionKey: 'sockets_custom_list',
            },
            {
                label: 'Color',
                type: 'input',
                name: 'socket_color',
            },
            {
                label: 'Interface',
                type: 'divider',
            },
            {
                label: 'Type',
                type: 'input',
                name: 'interface_id',
            },
            {
                label: 'Brand',
                type: 'input',
                name: 'brand',
            },
            {
                label: 'Size',
                type: 'input',
                name: 'interface_size',
            },
            {
                label: 'Counter Parts',
                type: 'input',
                name: 'interface_counter_parts',
            },
            {
                label: 'Foot',
                type: 'divider',
            },
            {
                label: 'Type',
                type: 'input',
                name: 'foot_id',
            },
            {
                label: 'Size',
                type: 'input',
                name: 'foot_size',
            },
            {
                label: 'Heel Height',
                type: 'input',
                name: 'foot_heel_height',
            },
            {
                label: 'Carbon Size',
                type: 'input',
                name: 'foot_carbon_size',
            },
            {
                label: 'Carbon Strength',
                type: 'input',
                name: 'foot_carbon_strength',
            },
            {
                label: 'Prosthetic',
                type: 'divider',
            },
            {
                label: 'Cover',
                type: 'input',
                name: 'prosthetic_cover_id',
            },
            {
                label: 'Cover option',
                type: 'input',
                name: 'prosthetic_cover_option',
            },
            {
                label: 'Knee',
                type: 'divider',
            },
            {
                label: 'Type',
                type: 'input',
                name: 'knee_id',
            },
            {
                label: 'Accessory',
                type: 'divider',
            },
            {
                label: 'Type',
                type: 'input',
                name: 'accessary_id',
            },
            {
                label: 'Size',
                type: 'input',
                name: 'accessary_size',
            },
        ],
    },
}

export const editStaffForm = [
    {
        label: 'Nickname',
        type: 'input',
        name: 'nickname',
        newValue: '',
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        label: 'Email',
        type: 'input',
        name: 'email',
        newValue: '',
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        label: 'Password',
        type: 'password',
        name: 'password',
        newValue: '',
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        label: 'First Name',
        type: 'input',
        name: 'first_name',
        newValue: '',
    },
    {
        label: 'Last Name',
        type: 'input',
        name: 'last_name',
        newValue: '',
    },
    {
        label: 'Slack ID',
        type: 'input',
        name: 'slackid',
        newValue: '',
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        label: 'Valid',
        type: 'checkbox',
        name: 'is_valid',
        newValue: true,
    },
    {
        label: 'Sales',
        type: 'checkbox',
        name: 'is_sales',
        newValue: false,
    },
    {
        label: 'PO',
        type: 'checkbox',
        name: 'is_po',
        newValue: false,
    },
    {
        label: 'PO Manager',
        type: 'checkbox',
        name: 'is_po_mng',
        newValue: false,
    },
    {
        label: 'Product',
        type: 'checkbox',
        name: 'is_product',
        newValue: false,
    },
    {
        label: 'Product Manager',
        type: 'checkbox',
        name: 'is_product_mng',
        newValue: false,
    },
    {
        label: 'Account',
        type: 'checkbox',
        name: 'is_account',
        newValue: false,
    },
    {
        label: 'Manager',
        type: 'checkbox',
        name: 'is_manager',
        newValue: false,
    },
    {
        label: 'Reception',
        type: 'checkbox',
        name: 'is_reception',
        newValue: false,
    },
    {
        label: 'Admin',
        type: 'checkbox',
        name: 'is_admin',
        newValue: false,
    },
    {
        label: 'Office',
        type: 'select',
        name: 'office_id',
        mode: 'multiple',
        optionKey: 'office_list',
        allowClear: true,
        newValue: [],
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        label: 'Company',
        type: 'select',
        name: 'company_id',
        optionKey: 'company_list',
        allowClear: true,
        newValue: null,
        rules: [
            {
                required: true,
            },
        ],
    },
]

export const userProfileForm = [
    {
        label: 'Email',
        type: 'input',
        name: 'email',
        props: { disabled: true },
        style: { width: '33.33%', marginBlockEnd: '24px' },
        rules: [
            {
                required: false,
            },
        ],
    },
    {
        label: 'Company',
        type: 'input',
        name: 'company',
        props: { disabled: true },
        style: { width: '33.33%', marginBlockEnd: '24px' },
        rules: [
            {
                required: false,
            },
        ],
    },
    {
        label: 'Address',
        type: 'textarea',
        name: 'address',
        style: { width: '83.33%', marginBlockEnd: '24px' },
        labelCol: { span: 2, style: { width: '100%' } },
        wrapperCol: { span: 22 },
        rules: [
            {
                required: false,
            },
        ],
    },
    {
        label: 'Phone',
        type: 'input',
        name: 'phone',
        style: { width: '33.33%', marginBlockEnd: '24px' },
        rules: [
            {
                required: false,
            },
        ],
    },
    {
        label: 'Pin-Code',
        type: 'input',
        name: 'pincode',
        style: { width: '33.33%', marginBlockEnd: '24px' },
        rules: [
            {
                required: false,
            },
        ],
    },
    {
        label: 'City',
        type: 'input',
        name: 'city',
        style: { width: '33.33%', marginBlockEnd: '24px' },
        rules: [
            {
                required: false,
            },
        ],
    },
    {
        label: 'State',
        type: 'input',
        name: 'state',
        style: { width: '33.33%', marginBlockEnd: '24px' },
        rules: [
            {
                required: false,
            },
        ],
    },
    {
        label: 'Country',
        type: 'input',
        name: 'country',
        style: { width: '33.33%', marginBlockEnd: '24px' },
        labelCol: { span: 5, pull: 18, style: { width: '100%' } },
        wrapperCol: { span: 19, pull: 18 },
        rules: [
            {
                required: false,
            },
        ],
    },
]
