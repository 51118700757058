import { boardDateFormatter } from '../../../utils/helpers'

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Patient Number',
    dataIndex: 'patient_no',
    key: 'patient_no',
  },
  {
    title: 'Patient Name',
    dataIndex: 'patient_name',
    key: 'patient_name',
  },
  {
    title: 'Register Date',
    dataIndex: 'register_date',
    key: 'register_date',
    render: (val) => boardDateFormatter(val),
    sorter: true
  },
  {
    title: 'ANO',
    dataIndex: 'ano',
    key: 'ano',
  },
  {
    title: 'DNO',
    dataIndex: 'dno',
    key: 'dno',
  },
  {
    title: 'Type',
    dataIndex: 'proinfo_name',
    key: 'proinfo_name',
  },
  {
    title: 'File Type',
    dataIndex: 'kind_subno',
    key: 'kind_subno',
  }
]

export const downloadFileData = {
  columns,
}
