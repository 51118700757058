import { Button, Checkbox, Col, DatePicker, Divider, Input, InputNumber, Row, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const { Option } = Select
const { TextArea } = Input

const dateFormat = ['YYYY-MM-DD']

const FormRenderer = (formEntry, props, value) => {
  switch (formEntry) {
    case 'input':
      return <Input {...props} />
    case 'number':
      return <InputNumber {...props} />
    case 'password':
      return <Input.Password {...props} />
    case 'checkbox':
      return <Checkbox {...props} />
    case 'select':
      return (
        <Select {...props}>
          {props?.options?.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      )
    case 'date':
      return (
        <DatePicker
          allowClear={false}
          // defaultValue={moment(new Date())}
          format={dateFormat}
          {...props}
        />
      )
    case 'textarea':
      return <TextArea showCount {...props} />
    case 'selectAndInput':
      return (
        <Row>
          <Col span={6}>
            <Select {...props} value={props.value?.[0]}>
              {props?.options?.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col offset={1} span={6}>
            <Input {...props} value={props.value?.[1]} />
          </Col>
        </Row>
      )
    case 'upload':
      return (
        <Upload {...props} listType='picture'>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      )
    case 'button':
      return <Button icon={<UploadOutlined />}>{JSON.stringify(props)}</Button>
    case 'divider':
      return <Divider orientation="left" >{props.label}</Divider>
    default:
      return <Input />
  }
}

export default FormRenderer
