import React from 'react'
import {
  UserOutlined,
  SettingOutlined,
  NumberOutlined,
  UsergroupAddOutlined,
  AccountBookOutlined,
  BarChartOutlined,
} from '@ant-design/icons'

export const ANALYTICS = 'Analytics'
export const OVERVIEW = 'Overview'
export const BOARD = 'Board'
export const SALES = 'Sales'
export const PO_MANAGER = 'PO-Manager'
export const PO_STUMP = 'PO-Stump'
export const PO_MANUFACTURE = 'PO-Manufacture'
export const PRODUCT_MANAGER = 'Product Manager'
export const PRODUCT_MANUFACTURE = 'Product Manufacture'
export const PATIENTS = 'Patients'
export const SETTINGS = 'Settings'
export const STAFF = 'Staff'
export const FORM = 'Form'
export const PROFILE = 'Profile'
export const USERPROFILE = 'User Profile'
export const CURA = 'Cura'
export const CONTACT = 'Contact'

const SIDEBAR = [
  {
    key: OVERVIEW.toLowerCase(),
    label: OVERVIEW,
    icon: React.createElement(AccountBookOutlined),
  },
  {
    key: BOARD.toLowerCase(),
    label: BOARD,
    icon: React.createElement(NumberOutlined),
    children: [
      {
        key: `board/${SALES.toLowerCase()}`,
        label: SALES,
        icon: React.createElement(UsergroupAddOutlined),
        children: null,
      },
      {
        key: `board/${PO_MANAGER.toLowerCase()}`,
        label: 'PO-Manager',
        icon: React.createElement(UsergroupAddOutlined),
        children: null,
      },
      {
        key: `board/${PO_STUMP.toLowerCase()}`,
        label: PO_STUMP,
        icon: React.createElement(UsergroupAddOutlined),
        children: null,
      },
      {
        key: `board/${PO_MANUFACTURE.toLowerCase()}`,
        label: PO_MANUFACTURE,
        icon: React.createElement(UsergroupAddOutlined),
        children: null,
      },
      {
        key: `board/${PRODUCT_MANAGER.replace(' ', '-').toLowerCase()}`,
        label: PRODUCT_MANAGER,
        icon: React.createElement(UsergroupAddOutlined),
        children: null,
      },
      {
        key: `board/${PRODUCT_MANUFACTURE.replace(' ', '-').toLowerCase()}`,
        label: PRODUCT_MANUFACTURE,
        icon: React.createElement(UsergroupAddOutlined),
        children: null,
      },
    ],
  },
  {
    key: PATIENTS.toLowerCase(),
    label: PATIENTS,
    icon: React.createElement(UserOutlined),
  },
  {
    key: SETTINGS.toLowerCase(),
    label: SETTINGS,
    icon: React.createElement(SettingOutlined),
    children: [
      {
        key: `${SETTINGS.toLowerCase()}/${STAFF.toLowerCase()}`,
        label: STAFF,
        icon: React.createElement(UsergroupAddOutlined),
        children: null,
      },
      {
        key: `${SETTINGS.toLowerCase()}/${CURA.toLowerCase()}`,
        label: CURA,
        icon: React.createElement(UsergroupAddOutlined),
        children: null,
      },
    ],
  },
  {
    key: ANALYTICS.toLowerCase(),
    label: ANALYTICS,
    icon: React.createElement(BarChartOutlined),
  },
]

export default SIDEBAR
