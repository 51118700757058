import React from 'react'
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { Link } from 'react-router-dom';
import { customTab } from '../pages/Patients/style';

function CustomTabBar(props) {
    const { currentTheme } = useThemeSwitcher()

    const handleTabClick = (event, tabKey) => {
        event.preventDefault();
        props.onChange(tabKey);
    };

    const tabList = props.panes.map((pane) => (
        <Link
            key={pane.key}
            to={pane.link}
            onClick={(event) => handleTabClick(event, pane.key)}
            className={customTab(currentTheme).linkWrap}
        >
            <span className={props.activeKey === pane.key ? `${customTab(currentTheme).active} ${customTab(currentTheme).tabLink}` : customTab(currentTheme).tabLink}>
                {pane.tab}
            </span>
        </Link>
    ));

    return <div style={{ marginBottom: '1.6rem' }}>{tabList}</div>;
}

export default CustomTabBar