import { Button, Modal } from 'antd'
import React, { useContext } from 'react'
import { SHOW_IMAGE_MODAL } from '../../../../../context/constants'
import GlobalContext from '../../../../../context/global'
// import { downloadFile } from './downloadApi'
import NoImage from '../../../../../assets/images/No_Image_Available.jpg'
import usePreview from './usePreview'

const ImagePreview = () => {
  const { imageContext } = useContext(GlobalContext.GlobalContextState)
  const dispatch = useContext(GlobalContext.GlobalContextDispath)
  const { onDownload } = usePreview()

  const handleCancel = () => {
    dispatch({
      type: SHOW_IMAGE_MODAL,
      payload: false,
    })
  }

  return (
    <Modal
      footer={<Button onClick={onDownload}>Download</Button>}
      visible={imageContext.previewVisible}
      onCancel={handleCancel}
    >
      <img
        alt='preview'
        style={{
          width: '100%',
        }}
        src={imageContext.previewImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = NoImage
          currentTarget.height = 280
        }}
      />
    </Modal>
  )
}

export default ImagePreview
