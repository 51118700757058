import { css } from '@emotion/css'

export const UploadWrapper = css`
  display: flex;
`
export const stumpImg = css`
  height: 25rem !important;
  width: 25rem !important;
  & span {
    height: 100%;
    & img {
      height: 90% !important;
      width: 100% !important;
    }
  }
`

export const listSpan = css`
  flex-wrap: wrap;
`
