import { Button, Col, Form, message, Modal, Row, Tooltip, Typography, Upload } from 'antd'
import { useNavigate } from 'react-router'
import {
  beforeUpload,
  boardDateFormatter,
  createOptions,
  filterFileType,
  formatHelper,
  formImageList,
  isAdmin,
  isPo,
  isPoMng,
} from '../../../utils/helpers'
import FormRenderer from '../formRenderer'
import { stumpForm } from '../forms'
import { InfoCircleFilled } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
// import Store from '../../../utils/authHelper'
import { UploadOutlined } from '@ant-design/icons'
import { http } from '../../../network/axiosInstance'
import usePreview from './components/ImagePreview/usePreview'
import { listSpan, stumpImg, UploadWrapper } from './style'
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'

const StumpInfoTab = ({ form, ampSelected, formData, nestData, id }) => {
  const navigate = useNavigate()
  const { onPreview, onDownload } = usePreview()
  const [fileList, setFileList] = useState([])
  const [videoList, setVideoList] = useState([])
  const [linerList, setLinerList] = useState([])
  const [percent, setPercent] = useState(0)
  const [uploading, setUploading] = useState(false)

  const stumpWidth = Form.useWatch('stump_width', form)
  const stumpVolume = Form.useWatch('stump_volume', form)
  const bulbVal = Form.useWatch('bulb', form)
  const poStatus = Form.useWatch('status_po', form)
  const managerStatus = Form.useWatch('status_mng', form)
  const statusPoValue = Form.useWatch('status_po', form)

  const proType = useMemo(
    () => nestData?.form_data?.stump?.pro_type,
    [nestData?.form_data?.stump?.pro_type]
  )

  useEffect(() => {
    const stumpFormValues = {
      ...nestData?.form_data?.stump,
      status_po: nestData?.form_data?.stump?.status_po_id,
      status_mng: nestData?.form_data?.stump?.status_mng_id,
    }
    form.setFieldsValue(stumpFormValues)

    setFileList(formImageList(nestData?.s3_files?.stump))
    setVideoList(formImageList(nestData?.s3_files?.video))
    setLinerList(formImageList(nestData?.s3_files?.liner))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, nestData?.form_data?.stump])

  // Need to introduce timer because automatic upload feature is asked for which is not supported by default and it is not desired to hit BE multiple times for automatic upload (one by one)
  useEffect(() => {
    if (!fileList?.length) return
    uploadStumpImages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList?.length])

  const validateAndSubmit = (values) => {
    // Uncomment line below if stump images need to be made mandatory
    // if (fileList.length < 1) return message.error(`Please Upload Stump Photos`)
    formData(`/editor/stump/${id}/${ampSelected}/`, values, 'post')
  }

  const isDisabled = ({ name }) => {
    const fields = [
      'stump_width',
      'stump_volume',
      'stump_length',
      'bony_distal_end',
      'knee_flexion',
      'hip_abduction',
      'thigh_width',
      'skin_condition',
      'bandaging',
      'bulb',
      'status_po',
      'status_mng',
      'hip_flexion',
      'done',
    ]

    // if (name === 'status_po' && (poStatus < 1 || poStatus > 3)) return false
    if (name === 'done' && !(statusPoValue < 1 || statusPoValue > 3)) return true
    if (name === 'bandaging' && (stumpWidth || stumpVolume)) return true

    if (name === 'status_mng' && (isPoMng() || isAdmin())) return false

    if ((isPo() || isAdmin()) && name === 'stump_width' && proType === 1) return true
    if ((isPo() || isAdmin()) && name === 'stump_volume' && proType === 2) return true
    if ((isPo() || isAdmin()) && name === 'knee_flexion' && proType === 1) return true
    if ((isPo() || isAdmin()) && name === 'hip_abduction' && proType === 2) return true
    if ((isPo() || isAdmin()) && name === 'hip_flexion' && proType === 2) return true
    if ((isPo() || isAdmin()) && name === 'thigh_width' && proType === 1) return true

    return fields?.includes(name) && !(isPo() || isAdmin())
  }

  // const poManagerCheck = ({ name }) => {
  //   const roles = Store.getRole

  //   if (name === 'status_mng') {
  //     if (roles?.includes('po_mng') || roles?.includes('admin')) return true
  //     return false
  //   }
  //   return true
  // }

  const onProgress = ({ percent: pcnt }, file) => {
    message.loading(`Upload in Progress ${pcnt || percent}% uploaded`, 0)
  }

  const isUploadDisabeld = () => {
    if (!fileList?.length) return true
    return fileList?.every((file) => !(file instanceof File))
  }
  // below function is used to upload multiple images of stump without calling the API multiple times
  async function uploadStumpImages() {
    if (isUploadDisabeld()) return
    setUploading(true)
    const form = new FormData()
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i] instanceof File) {
        form.append('file', fileList[i])
      }
    }
    form.append('stump_flag', true)
    form.append('path', `patientdata/${id}/stump/${ampSelected}/stump/`)
    form.append('kind', 1)
    form.append('subno', 1)
    form.append('pid', id)
    form.append('ano', ampSelected)
    form.append('dno', null)

    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: ({ total, loaded }) => {
          // onProgress({ percent: Math.floor((loaded / total) * 100) }, fileList[0])
          // setPercent(Math.floor((loaded / total) * 100))
        },
      }
      const saveFile = await http.post(`editor/upload/files/`, form, config)
      if (saveFile.status === 200) {
        const fetchSavedImage = await http.get(`editor/stump/${id}/${ampSelected}`)
        setFileList(formImageList(fetchSavedImage?.data?.s3_files?.stump))
        message?.destroy()
      }
      // formData(`editor/stump/${id}/${ampSelected}`, undefined, 'get')
    } catch (err) {
      console.log('Error: ', err)
    } finally {
      setUploading(false)
    }
  }
  // below function is only used for uploading files of liner and video
  const saveMedia = async (options, kind) => {
    const { onSuccess, onError, file, onProgress } = options
    let pathSuffix = {
      1: 'stump',
      2: 'liner',
      3: 'video',
    }

    const form = new FormData()
    form.append('file', file)
    form.append('stump_flag', true)
    form.append('path', `patientdata/${id}/stump/${ampSelected}/${pathSuffix[kind]}/`)
    form.append('kind', kind)
    form.append('subno', 1)
    form.append('pid', id)
    form.append('ano', ampSelected)
    form.append('dno', null)
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: ({ total, loaded }) => {
          onProgress({ percent: Math.floor((loaded / total) * 100) }, file)
          setPercent(Math.floor((loaded / total) * 100))
        },
      }
      const saveFile = await http.post(`editor/upload/files/`, form, config)
      if (saveFile.status === 200) {
        const listToBeSavedIn = kind === 1 ? setFileList : kind === 2 ? setLinerList : setVideoList
        const fetchSavedImage = await http.get(`editor/stump/${id}/${ampSelected}`)
        listToBeSavedIn(formImageList(fetchSavedImage?.data?.s3_files?.[pathSuffix[kind]]))
        onSuccess('Ok')
        message?.destroy()
      }
      // formData(`editor/stump/${id}/${ampSelected}`, undefined, 'get')
    } catch (err) {
      console.log('Error: ', err)
      onError({ err })
    }
  }

  const deleteMedia = (image, cb) =>
    Modal.warn({
      closable: true,
      title: 'Are you sure, you want to delete this media?',
      onOk: () => deleteMediaConfirm(image, cb),
    })

  const deleteMediaConfirm = async (image, cb) => {
    // e.stopPropagation()
    const delRequest = await http.post('editor/delete/files/', { key: image?.uid })
    if (delRequest?.status === 200) {
      cb((prevList) => prevList?.filter((img) => img.uid !== image?.uid))
    }
  }

  const previewImage = (file) => {
    onPreview(file, 'stump')
  }

  const handlePreview = (file, field) => {
    if (field === 'video') return
    previewImage(file)
  }

  const showAssessedInfo = (field) => {
    const poAssess = nestData?.form_data?.stump?.approval_po_staff
    const poAssessDate = boardDateFormatter(nestData?.form_data?.stump?.approval_po_date) || 'NA'
    const poMngAssess = nestData?.form_data?.stump?.approval_mng_staff
    const poMngAssessDate =
      boardDateFormatter(nestData?.form_data?.stump?.approval_mng_date) || 'NA'

    const infoData = {
      status_po: {
        name: poAssess,
        date: poAssessDate,
      },
      status_mng: {
        name: poMngAssess,
        date: poMngAssessDate,
      },
    }
    return (
      field?.assessed &&
      infoData[field.name]?.name && (
        <Col span={8}>
          <Typography.Text style={{ marginLeft: 8 }} keyboard>
            Assessed By: {infoData[field.name]?.name}
            <span style={{ paddingLeft: 8 }}>At</span> {infoData[field.name]?.date}
          </Typography.Text>
        </Col>
      )
    )
  }

  const renderStumpForm = (field) => {
    // const isPoMng = poManagerCheck(field)
    // if (!isPoMng) return null

    if (bulbVal === true && (field.name === 'liner' || field.name === 'video')) {
      return (
        <Col span={20}>
          <Form.Item {...field} valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}>
            <div className={UploadWrapper}>
              <Upload
                fileList={field.name === 'video' ? videoList : linerList}
                // onChange={handleUpload}
                onPreview={(file) => handlePreview(file, field.name)}
                showUploadList={{ showDownloadIcon: true }}
                // onDownload={(file) => {
                //   onDownload({ id, dno: null, kind: 1, filename: file.name, url: file.url })
                // }}
                onProgress={onProgress}
                accept={filterFileType('stump', field.name)}
                customRequest={(options) => saveMedia(options, field.name === 'liner' ? 2 : 3)}
                beforeUpload={(file) =>
                  beforeUpload(file, field.name, field.name === 'liner' ? linerList : videoList)
                }
                {...field.props}
                listType='picture'
                onRemove={(img) =>
                  deleteMedia(img, field.name === 'liner' ? setLinerList : setVideoList)
                }
                multiple={field.name === 'liner'}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
                {formatHelper('stump', field.name)}
              </Upload>
            </div>
          </Form.Item>
        </Col>
      )
    }

    if (field?.type === 'upload') {
      return (
        <Col span={24}>
          <Form.Item
            labelCol={{ span: 4 }}
            {...field}
            valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}
          >
            <div className={UploadWrapper}>
              <Upload
                fileList={fileList}
                // onChange={handleUpload}
                showUploadList={{
                  showDownloadIcon: true,
                }}
                itemRender={(originNode, file, fileList, actions) => {
                  return (
                    <Row>
                      <Col className={`ant-upload-list-item ${stumpImg}`}>
                        <div style={{ display: 'flex', height: '100%' }}>
                          <span className={`ant-upload-span ${listSpan}`} onClick={actions.preview}>
                            <img
                              src={file.url}
                              // className='ant-upload-list-item-thumbnail'
                              alt={file.name}
                              style={{ color: 'transparent' }}
                            />
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              className='ant-upload-list-item-name'
                              title={file.name}
                              href={file.url}
                              style={{
                                width: '200px',
                              }}
                            >
                              {file.name}
                            </a>
                          </span>
                          <span className='ant-upload-list-item-card-actions picture'>
                            <button
                              title='Remove file'
                              type='button'
                              onClick={actions.remove}
                              class='ant-btn-text ant-btn-icon-only'
                            >
                              <DeleteOutlined />
                            </button>
                            <button
                              title='Remove file'
                              type='button'
                              onClick={() =>
                                onDownload({
                                  id,
                                  dno: null,
                                  kind: 1,
                                  filename: file.name,
                                  url: file.url,
                                  skip: true,
                                })
                              }
                              class='ant-btn-text ant-btn-icon-only'
                            >
                              <DownloadOutlined />
                            </button>
                          </span>
                        </div>
                      </Col>
                      {boardDateFormatter(file.upload_date) && (
                        <Button disabled style={{ marginInline: '5px' }}>
                          {`Uploaded on ${boardDateFormatter(file.upload_date)}`}
                        </Button>
                      )}
                    </Row>
                  )
                }}
                onPreview={previewImage}
                // onDownload={(file) => {
                // alert('hi')
                // onDownload({ id, dno: null, kind: 1, filename: file.name, url: file.url })
                // }}
                onProgress={onProgress}
                accept={['.jpg, .jpeg, .png']}
                // customRequest={(options) => saveStumpImage(options, 1)}
                beforeUpload={(file) => {
                  const isAllowed = beforeUpload(file, 'stump')
                  if (isAllowed) {
                    setFileList((prevFiles) => [...prevFiles, file])
                  }
                  return false
                }}
                {...field.props}
                listType='picture'
                onRemove={(img) => deleteMedia(img, setFileList)}
                multiple
              >
                <Button loading={uploading} icon={<UploadOutlined />}>
                  {' '}
                  {uploading ? 'Uploading' : 'Upload'}
                </Button>
                <Tooltip title='Supports only .JPG, .JPEG, .PNG'>
                  <InfoCircleFilled style={{ marginLeft: 10 }} />
                </Tooltip>
              </Upload>
              {/* This is manually uploaded because of slack messages on BE, should triogger just once */}
              {/* <Button
                onClick={uploadStumpImages}
                loading={uploading}
                disabled={isUploadDisabeld()}
                style={{ marginTop: 5 }}
                icon={<UploadOutlined />}
              >
                {uploading ? 'Uploading' : 'Upload'}
              </Button> */}
            </div>
          </Form.Item>
        </Col>
      )
    }
    if (poStatus === 2 && field.name === 'po_reject_reason') {
      return (
        <Col span={field.subSpan}>
          <Form.Item {...field}>
            {FormRenderer('textarea', {
              autoSize: true
            })}
          </Form.Item>
        </Col>
      )
    }
    if (managerStatus === 2 && field.name === 'po_mng_reject_reason') {
      return (
        <Col span={field.subSpan}>
          <Form.Item {...field}>
            {FormRenderer('textarea', {
              autoSize: true
            })}
          </Form.Item>
        </Col>
      )
    }
    if (!field.type) return null
    return (
      <Row align='stretch'>
        <Col span={field.subSpan || field.span}>
          <Form.Item {...field} valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}>
            {FormRenderer(field.type, {
              ...field.props,
              disabled: isDisabled(field),
              // value: valueFormatter(field.type, data?.basic?.[field.name]),
              options: createOptions(nestData?.form_data?.[field?.optionKey]),
            })}
          </Form.Item>
        </Col>
        {showAssessedInfo(field)}
      </Row>
    )
  }

  return (
    <Form
      onFinish={validateAndSubmit}
      form={form}
      initialValues={nestData?.form_data?.stump}
      labelCol={{ span: 8 }}
      labelAlign='left'
    // wrapperCol={{ span: 16 }}
    >
      {stumpForm?.map((field, idx) => (
        <Row key={idx + 'stumpf'} align='middle'>
          <Col span={field.span}>{renderStumpForm(field)}</Col>
          {field.hasTool && (
            <Col pull={12}>
              <div style={{ marginLeft: 10, marginBottom: 24 }}>
                <Tooltip title={field.toolLabel}>
                  <InfoCircleFilled />
                </Tooltip>
              </div>
            </Col>
          )}
        </Row>
      ))}
      <Row gutter={6} justify='end'>
        <Col>
          <Button onClick={() => navigate('/patients')}>Back to Patients</Button>
        </Col>
        <Col>
          <Button htmlType='submit' type='primary'>
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default StumpInfoTab
