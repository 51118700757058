import { useContext } from 'react'
import {
  IS_STUMP,
  SET_IMAGE,
  SET_IMAGE_NAME,
  SHOW_IMAGE_MODAL,
} from '../../../../../context/constants'
import GlobalContext from '../../../../../context/global'
import { getBase64 } from '../../../../../utils/helpers'
import { downloadFile } from './downloadApi'

const usePreview = () => {
  const dispatch = useContext(GlobalContext.GlobalContextDispath)
  const { imageContext } = useContext(GlobalContext.GlobalContextState)

  const onPreview = async (file, isStump) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    dispatch({
      type: SET_IMAGE,
      payload: file.url || file.preview,
    })

    dispatch({
      type: SET_IMAGE_NAME,
      payload: file.name,
    })

    dispatch({
      type: SHOW_IMAGE_MODAL,
      payload: true,
    })

    dispatch({
      type: IS_STUMP,
      payload: Boolean(isStump),
    })
  }

  const onDownload = async ({ id, dno, kind, filename, url, skip }) => {
    const patientId = +id || +imageContext.patientId
    const dnoClicked = dno || imageContext.dno
    const kindClicked = kind || imageContext.kind
    const fileName = filename || imageContext.imageName
    const itemLink = url || imageContext.previewImage

    const link = document.createElement('a')
    link.href = itemLink
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()

    if(imageContext.fromStump || skip) return
    
    await downloadFile({
      p_id: patientId,
      dno: dnoClicked,
      kind: kindClicked,
      filename: fileName,
    })
  }

  return { onPreview, onDownload }
}

export default usePreview
