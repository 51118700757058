import { Button, Col, Divider, Form, Row, Upload } from 'antd'
import React from 'react'
import {
    assessedBy,
    beforeUpload,
    filterFileType,
    formatHelper,
    infoHelper,
} from '../../../../utils/helpers'
import FormRenderer from '../../formRenderer'
import usePreview from '../components/ImagePreview/usePreview'
import { UploadWrapper } from '../style'
import { UploadOutlined } from '@ant-design/icons'

export default function Cushion({
    showDownloadProps,
    onProgress,
    id,
    ampSelected,
    previewImage,
    dno,
    saveImage,
    lastDno,
    nestData,
    formData,
    triggerMultiDelete,
    getOptions,
    diagnosisNestedForm,
    formOne,
    formTwo,
    handleDiagNestSubmission,
    files,
    setFiles,
}) {
    const { onDownload } = usePreview()
    return (
        <>
            <Divider orientation='center'>TEST SOCKET</Divider>
            <Form
                form={formOne}
                onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '1')}
                labelCol={{ span: 9 }}
                labelAlign='left'
                wrapperCol={{ span: 9 }}
            >
                <Form.Item label='Upload' name={'sl'}>
                    <div className={UploadWrapper}>
                        <Upload
                            {...showDownloadProps}
                            onProgress={onProgress}
                            onPreview={(file) => previewImage(file, 12)}
                            onDownload={(file) => {
                                onDownload({ id, dno, kind: 9, filename: file.name, url: file.url })
                            }}
                            fileList={files?.testsocket}
                            beforeUpload={(file) => beforeUpload(file, 'cushion', files?.testsocket)}
                            accept={filterFileType('cushion', 1)}
                            customRequest={(options) =>
                                saveImage(
                                    options,
                                    `patientdata/${id}/${lastDno}/cushion/testsocket/`,
                                    12,
                                    () =>
                                        formData(
                                            `/editor/manu/cushion/${id}/${ampSelected}/${lastDno}/`,
                                            undefined,
                                            'get'
                                        ),
                                    1
                                )
                            }
                            listType='picture'
                            onRemove={(image) =>
                                triggerMultiDelete(image, () =>
                                    setFiles((prev) => ({
                                        ...prev,
                                        testsocket: files.testsocket?.filter((img) => img.uid !== image?.uid),
                                    }))
                                )
                            }
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                            {formatHelper('cushion', 1)}
                        </Upload>
                        {infoHelper(
                            nestData?.form_data?.file1?.upload_staff,
                            nestData?.form_data?.file1?.upload_date
                        )}
                    </div>
                </Form.Item>
                <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
                    {FormRenderer('select', {
                        options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
                    })}
                </Form.Item>
                {assessedBy(
                    nestData?.form_data?.file1?.approval_staff,
                    nestData?.form_data?.file1?.approval_date
                )}
                <Form.Item label='Memo' name='memo'>
                    {FormRenderer('textarea')}
                </Form.Item>
                <Row gutter={6} justify='end'>
                    <Col>
                        <Button disabled={!files?.testsocket?.length} htmlType='submit' type='primary'>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Divider orientation='center'>FINAL SOCKET</Divider>
            <Form
                form={formTwo}
                onFinish={(values) => handleDiagNestSubmission(values, diagnosisNestedForm, '2')}
                labelCol={{ span: 9 }}
                labelAlign='left'
                wrapperCol={{ span: 9 }}
            >
                <Form.Item label='Upload' name={'sl'}>
                    <div className={UploadWrapper}>
                        <Upload
                            {...showDownloadProps}
                            onProgress={onProgress}
                            onPreview={(file) => previewImage(file, 12)}
                            onDownload={(file) => {
                                onDownload({ id, dno, kind: 9, filename: file.name, url: file.url })
                            }}
                            fileList={files?.finalsocket}
                            beforeUpload={(file) => beforeUpload(file, 'cushion', files?.finalsocket)}
                            accept={filterFileType('cushion', 2)}
                            customRequest={(options) =>
                                saveImage(
                                    options,
                                    `patientdata/${id}/${lastDno}/cushion/finalsocket/`,
                                    12,
                                    () =>
                                        formData(
                                            `/editor/manu/cushion/${id}/${ampSelected}/${lastDno}/`,
                                            undefined,
                                            'get'
                                        ),
                                    2
                                )
                            }
                            listType='picture'
                            onRemove={(image) =>
                                triggerMultiDelete(image, () =>
                                    setFiles((prev) => ({
                                        ...prev,
                                        finalsocket: files.finalsocket?.filter((img) => img.uid !== image?.uid),
                                    }))
                                )
                            }
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                            {formatHelper('cushion', 2)}
                        </Upload>
                        {infoHelper(
                            nestData?.form_data?.file2?.upload_staff,
                            nestData?.form_data?.file2?.upload_date
                        )}
                    </div>
                </Form.Item>
                <Form.Item style={{ paddingBottom: 0, marginBottom: 5 }} label='Status' name='status'>
                    {FormRenderer('select', {
                        options: getOptions({ optionKey: 'file_status_list' }, diagnosisNestedForm),
                    })}
                </Form.Item>
                {assessedBy(
                    nestData?.form_data?.file2?.approval_staff,
                    nestData?.form_data?.file2?.approval_date
                )}
                <Form.Item label='Memo' name='memo'>
                    {FormRenderer('textarea')}
                </Form.Item>
                <Row gutter={6} justify='end'>
                    <Col>
                        <Button disabled={!files?.finalsocket?.length} htmlType='submit' type='primary'>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
