import React, { useMemo } from 'react'
import { Layout, Menu } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { main, menuItem } from './style'
import { useNavigate } from 'react-router'
import Store from '../utils/authHelper'
import { ACHILLI_ROLE, ADMIN_PANEL } from '../utils/constants'
import SidebarItems from './sidebar'
import Access from '../permission/accessHelper'
import HEADER from '../utils/header'
const { Sider } = Layout

const LayoutContainer = ({ children, active }) => {
  const navigate = useNavigate()
  const loggedInUser = Store.getFromLocal('achilli_nickname')
  const role = Store.getObjectFromLocal(ACHILLI_ROLE)
  const menu = useMemo(
    () => (
      <Menu
        onClick={({ key }) => {
          if (key === 'switch') {
            return navigate('/login', { state: { switch: true } })
          }
          if (key === 'admin_panel') return window.open(process.env.REACT_APP_ADMIN_PANEL, '_blank')
          navigate(`/${key}`)
        }}
        items={[
          {
            label: 'Change Password',
            key: 'change-password',
            icon: <UserOutlined />,
          },
          Access.canSwitchOffice && {
            label: 'Switch Office',
            key: 'switch',
            icon: <UserOutlined />,
          },
          Access.hasAccess(role, ADMIN_PANEL) && {
            label: 'Admin Panel',
            key: 'admin_panel',
            icon: <UserOutlined />,
          },
          {
            label: 'Profile',
            key: 'profile',
            icon: <UserOutlined />,
          },
          {
            label: 'Logout',
            key: 'login',
            icon: <UserOutlined />,
          },
        ]}
      />
    ),
    [navigate, role]
  )

  return (
    <Layout className={main}>
      <HEADER menu={menu} loggedInUser={loggedInUser}></HEADER>
      <Layout>
        {active && (
          <Sider defaultCollapsed={true} collapsible width={200}>
            <Menu
              mode='inline'
              defaultSelectedKeys={[active]}
              style={{
                height: '100%',
                borderRight: 0,
              }}
              className={menuItem}
              onClick={(item) => navigate(`/${item.key}`)}
              items={SidebarItems(role)}
            />
          </Sider>
        )}
        {children}
      </Layout>
    </Layout>
  )
}

export default LayoutContainer
