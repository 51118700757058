import { Button, Table } from "antd";
import React from "react";

const IssueGrid = ({ data, fetchIssueDetails, loading }) => {
    return (
        <Table
            loading={loading}
            style={{ width: '100%' }}
            bordered
            columns={[
                {
                    title: 'Id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: 'Category',
                    dataIndex: 'category',
                    key: 'category',
                },
                {
                    title: 'Subject',
                    dataIndex: 'subject',
                    key: 'subject',
                },
                {
                    title: 'Other Reason',
                    dataIndex: 'other_category_reason',
                    key: 'other_category_name',
                },
                {
                    title: 'Raised By',
                    dataIndex: 'raised_by_name',
                    key: 'raised_by_name',
                },
                {
                    title: 'Email',
                    dataIndex: 'raised_by_email',
                    key: 'raised_by_email',
                },
                {
                    title: 'Created By',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    render: (_, row) => (
                        <>
                            <Button onClick={() => fetchIssueDetails(row.id)} primary shape='round'>
                                View Details
                            </Button>
                        </>
                    ),
                },
            ]}
            dataSource={data}
            pagination={false}
        />
    )
}

export default IssueGrid