import { Breadcrumb } from 'antd'
import { useLocation } from 'react-router-dom'

export default function BreadCrumb() {
  const location = useLocation()
  const path = location.pathname.split('/')
  const breadCrumbItems = path.map((item, index) => {
    item = item.charAt(0).toUpperCase() + item.substring(1)
    return <Breadcrumb.Item key={index+item}>{item}</Breadcrumb.Item>
  })
  return (
    <Breadcrumb
      separator='>'
      style={{
        margin: '16px 0',
      }}
    >
      {breadCrumbItems}
    </Breadcrumb>
  )
}
