export const SET_IMAGE = 'SET_IMAGE'
export const SHOW_IMAGE_MODAL = 'SHOW_IMAGE_MODAL'
export const SET_IMAGE_NAME = 'SET_IMAGE_NAME'
export const SET_DOWNLOAD_META_DATA = 'SET_DOWNLOAD_META_DATA'
export const SET_ALL_AMPUTATION = 'SET_ALL_AMPUTATION'
export const IS_STUMP = 'IS_STUMP'
export const SET_PATIENT_NAME = 'SET_PATIENT_NAME'
export const SET_CURRENT_DIAGNOSIS = 'SET_CURRENT_DIAGNOSIS'
export const SET_PATIENT_INFO = 'SET_PATIENT_INFO'

