import {
    Button,
    Col,
    Divider,
    Form,
    message,
    Modal,
    Radio,
    Row,
    Space,
    Tree,
    Upload,
} from "antd"
import { useLocation, useNavigate } from "react-router"
import {
    assessedBy,
    beforeUpload,
    constructNestApiUrl,
    createOptions,
    filterFileType,
    formatHelper,
    formImageList,
    infoHelper,
    isAdmin,
    isIndainOffice,
    isNotManu,
} from "../../../utils/helpers"
import FormRenderer from "../formRenderer"
import { diagnosisForms } from "../forms"
import { FileAddOutlined, FormOutlined } from "@ant-design/icons"
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { UploadOutlined } from "@ant-design/icons"
import { http } from "../../../network/axiosInstance"
import usePreview from "./components/ImagePreview/usePreview"
import GlobalContext from "../../../context/global"
import { SET_DOWNLOAD_META_DATA } from "../../../context/constants"
import { UploadWrapper } from "./style"
import Sense from "./diagnosis/Sense"
import TestSocket from "./diagnosis/TestSocket"
import Final from "./diagnosis/Final"
import Inspection from "./diagnosis/Inspection"
import SoftInsert from "./diagnosis/SoftInsert"
import Parts from "./diagnosis/Parts"
import ProstheticCover from "./diagnosis/ProstheticCover"
import Lamination from "./diagnosis/Lamination"
import Cushion from "./diagnosis/Cushion"

const treeData = (dno = "", ProType = "", currentDiagnosis) => {
    const mainChildren = [
        {
            title: "Stump",
            key: "d_stump",
            icon: <FormOutlined />,
        },
        {
            title: "Physical",
            key: "d_physical",
            icon: <FormOutlined />,
        },
        {
            title: "Manufacture",
            key: "d_manufacture",
            icon: <FormOutlined />,
            children: [
                {
                    title: "Sense",
                    key: "sense",
                    icon: <FormOutlined />,
                },
                {
                    title: "Einscan",
                    key: "eiscan",
                    icon: <FormOutlined />,
                },
                {
                    title: "Test Socket",
                    key: "testsocket",
                    icon: <FormOutlined />,
                },
                {
                    title: "Final",
                    key: "final",
                    icon: <FormOutlined />,
                },
                {
                    title: "Picture",
                    key: "picture",
                    icon: <FormOutlined />,
                },
                {
                    title: "Movie",
                    key: "movie",
                    icon: <FormOutlined />,
                },
                {
                    title: "Inspection",
                    key: "inspection",
                    icon: <FormOutlined />,
                },
                {
                    title: "SoftInsert",
                    key: "softinsert",
                    icon: <FormOutlined />,
                },
                {
                    title: "Prosthetic Cover",
                    key: "cover",
                    icon: <FormOutlined />,
                },
                {
                    title: "Cushion",
                    key: "cushion",
                    icon: <FormOutlined />,
                },
            ],
        },
        {
            title: "Parts",
            key: "d_parts",
            icon: <FormOutlined />,
        },
    ]

    if (isAdmin()) {
        mainChildren.push({
            title: "AI",
            key: "ai",
            icon: <FormOutlined />,
        })
    }

    if (
        isIndainOffice() &&
        (currentDiagnosis?.proinfo === 6 || currentDiagnosis?.proinfo === 7)
    ) {
        mainChildren?.[2]?.children.push({
            title: "Lamination Tab",
            key: "lamination",
            icon: <FormOutlined />,
        })
    }

    return [
        {
            title: `${dno} (${ProType})` ?? "N-A",
            // key: '0-0-0',
            children: mainChildren,
        },
    ]
}

const multipleForm = [
    "sense",
    "final",
    "inspection",
    "testsocket",
    "softinsert",
    "d_parts",
    "cushion",
    "cover",
]

const DiagnosisInfoTab = ({
    form,
    ampSelected,
    formData,
    nestData,
    id,
    lastDno,
    dno,
    proType,
    diagnosisNestedForm,
    setDiagnosisNestedForm,
    setSelectedForm,
    loading,
    selectedNestedForm,
    setLastDno,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [diagnosisModal, setDiagModal] = useState(false)
    const [type, setType] = useState(location?.state?.typeValue || 1)
    const [inputFiles, setInputFiles] = useState([])
    const [multiFiles, setMultiFiles] = useState({})
    const [outputFiles, setOutputFiles] = useState([])
    const [percent, setPercent] = useState(0)
    const newProForm = useRef()
    const { onPreview, onDownload } = usePreview()
    const dispatch = useContext(GlobalContext.GlobalContextDispath)
    const contextStore = useContext(GlobalContext.GlobalContextState)

    const [formOne] = Form.useForm()
    const [formTwo] = Form.useForm()
    const [formThree] = Form.useForm()

    const showDownloadProps = {
        showUploadList: {
            showDownloadIcon: true,
            showPreviewIcon: false,
        },
    }
    Form.useWatch("stump_status", form)
    Form.useWatch("stump_status", form)
    const proTypeDetect = useMemo(
        () => (proType?.includes("BK") ? "BK" : "AK"),
        [proType]
    )

    const isRequired = (fieldName) => {
        const fieldToCheck = fieldName === "reject_reason"
        // For Stump make reject reason mandatory if status is rejected, 2 is reject value from BE
        return fieldToCheck && form.getFieldsValue()["stump_status"] === 2
    }

    const urlDict = useMemo(
        () => ({
            d_stump: `/editor/dgns/stump/${id}/${ampSelected}/${lastDno}/`,
            d_physical: `/editor/dgns/phy/${id}/${ampSelected}/${lastDno}/`,
            ai: `/editor/manu/ai/${id}/${ampSelected}/${lastDno}/`,
            sense: `/editor/manu/sense/${id}/${ampSelected}/${lastDno}/`,
            eiscan: `/editor/manu/ein/${id}/${ampSelected}/${lastDno}/`,
            final: `/editor/manu/final/${id}/${ampSelected}/${lastDno}/`,
            picture: `/editor/manu/pict/${id}/${ampSelected}/${lastDno}/`,
            movie: `/editor/manu/movie/${id}/${ampSelected}/${lastDno}/`,
            inspection: `/editor/manu/insp/${id}/${ampSelected}/${lastDno}/`,
            testsocket: `/editor/manu/test/${id}/${ampSelected}/${lastDno}/`,
            softinsert: `/editor/manu/soft/${id}/${ampSelected}/${lastDno}/`,
            d_parts: `/editor/dgns/parts/${id}/${ampSelected}/${lastDno}/`,
            cover: `/editor/manu/cover/${id}/${ampSelected}/${lastDno}/`,
            lamination: `/editor/manu/lamination/${id}/${ampSelected}/${lastDno}/`,
            cushion: `/editor/manu/cushion/${id}/${ampSelected}/${lastDno}/`,
        }),
        [ampSelected, id, lastDno]
    )

    const checkAndProceed = () => {
        if (nestData?.all_diagnosis?.length) {
            return Modal.warn({
                closable: true,
                title: "Are you sure, the patient appeared again for remodelling?",
                onOk: getProsthesisType,
                okText: "Yes, Proceed",
            })
        }
        getProsthesisType()
    }

    const getProsthesisType = useCallback(() => {
        setDiagModal(true)
        formData(
            `/editor/dgns/stump/${id}/${ampSelected}/new/`,
            undefined,
            "get"
        )
    }, [ampSelected, formData, id])

    useEffect(() => {
        form.setFieldsValue({ type })
        if (diagnosisNestedForm === "d_stump") {
            const diagStumpValues = {
                ...nestData?.form_data?.current_diagnosis,
                stump_status:
                    nestData?.form_data?.current_diagnosis?.stump_status_id,
            }
            return form.setFieldsValue(diagStumpValues)
        }
        if (diagnosisNestedForm === "sense") {
            formOne.setFieldsValue({
                file_status_list: nestData?.form_data?.file1?.status,
                status: nestData?.form_data?.file1?.status_id,
                memo: nestData?.form_data?.file1?.memo,
            })
            formTwo.setFieldsValue({
                file_status_list: nestData?.form_data?.file2?.status,
                status: nestData?.form_data?.file2?.status_id,
                memo: nestData?.form_data?.file2?.memo,
            })
            const files = {
                sl: formImageList(nestData?.s3_files?.with_sl),
                no_sl: formImageList(nestData?.s3_files?.no_sl),
                check_sheet: formImageList(nestData?.s3_files?.check_sheet),
            }
            return setMultiFiles(files)
        }
        if (diagnosisNestedForm === "eiscan") {
            form.setFieldsValue({
                file_status_list: nestData?.form_data?.status,
                status: nestData?.form_data?.file1?.status_id,
                memo: nestData?.form_data?.file1?.memo,
            })
            return setInputFiles(formImageList(nestData?.s3_files?.ein))
        }
        if (diagnosisNestedForm === "final") {
            formOne.setFieldsValue({
                file_status_list: nestData?.form_data?.file1?.status,
                status: nestData?.form_data?.file1?.status_id,
                memo: nestData?.form_data?.file1?.memo,
            })
            formTwo.setFieldsValue({
                file_status_list: nestData?.form_data?.file2?.status,
                status: nestData?.form_data?.file2?.status_id,
                memo: nestData?.form_data?.file2?.memo,
            })
            const files = {
                cad: formImageList(nestData?.s3_files?.cad),
                gcode: formImageList(nestData?.s3_files?.gcode),
            }
            return setMultiFiles(files)
        }
        if (diagnosisNestedForm === "picture") {
            form.setFieldsValue({
                file_status_list: nestData?.form_data?.file1?.status,
                status: nestData?.form_data?.file1?.status_id,
                memo: nestData?.form_data?.file1?.memo,
            })
            setInputFiles(formImageList(nestData?.s3_files?.front))
            return setOutputFiles(formImageList(nestData?.s3_files?.side))
        }
        if (diagnosisNestedForm === "movie") {
            form.setFieldsValue({
                file_status_list: nestData?.form_data?.file1?.status,
                status: nestData?.form_data?.file1?.status_id,
                memo: nestData?.form_data?.file1?.memo,
            })
            return setInputFiles(formImageList(nestData?.s3_files?.movie))
        }
        if (diagnosisNestedForm === "inspection") {
            form.setFieldsValue({
                file_status_list: nestData?.form_data?.file3?.status,
                status: nestData?.form_data?.file3?.status_id,
                memo: nestData?.form_data?.file3?.memo,
            })
            formOne.setFieldsValue({
                file_status_list: nestData?.form_data?.file1?.status,
                status: nestData?.form_data?.file1?.status_id,
                memo: nestData?.form_data?.file1?.memo,
            })
            formTwo.setFieldsValue({
                file_status_list: nestData?.form_data?.file2?.status,
                status: nestData?.form_data?.file2?.status_id,
                memo: nestData?.form_data?.file2?.memo,
            })
            formThree.setFieldsValue({
                file_status_list: nestData?.form_data?.file4?.status,
                status: nestData?.form_data?.file4?.status_id,
                memo: nestData?.form_data?.file4?.memo,
            })
            const files = {
                strength: formImageList(nestData?.s3_files?.strength),
                height: formImageList(nestData?.s3_files?.height),
                circumferential: formImageList(
                    nestData?.s3_files?.circumferential
                ),
                OQC: formImageList(nestData?.s3_files?.OQC),
            }
            return setMultiFiles(files)
        }
        if (diagnosisNestedForm === "testsocket") {
            formOne.setFieldsValue({
                file_status_list: nestData?.form_data?.file1?.status,
                status: nestData?.form_data?.file1?.status_id,
                memo: nestData?.form_data?.file1?.memo,
            })
            formTwo.setFieldsValue({
                file_status_list: nestData?.form_data?.file2?.status,
                status: nestData?.form_data?.file2?.status_id,
                memo: nestData?.form_data?.file2?.memo,
            })

            const files = {
                cad: formImageList(nestData?.s3_files?.cad),
                gcode: formImageList(nestData?.s3_files?.gcode),
                check_sheet: formImageList(nestData?.s3_files?.check_sheet),
            }
            return setMultiFiles(files)
        }
        if (diagnosisNestedForm === "ai") {
            setOutputFiles(formImageList(nestData?.s3_files?.output))
            return setInputFiles(formImageList(nestData?.s3_files?.input))
        }
        if (
            diagnosisNestedForm === "softinsert" ||
            diagnosisNestedForm === "cushion"
        ) {
            formOne.setFieldsValue({
                file_status_list: nestData?.form_data?.file1?.status,
                status: nestData?.form_data?.file1?.status_id,
                memo: nestData?.form_data?.file1?.memo,
            })
            formTwo.setFieldsValue({
                file_status_list: nestData?.form_data?.file2?.status,
                status: nestData?.form_data?.file2?.status_id,
                memo: nestData?.form_data?.file2?.memo,
            })

            const files = {
                finalsocket: formImageList(nestData?.s3_files?.finalsocket),
                testsocket: formImageList(nestData?.s3_files?.testsocket),
            }
            return setMultiFiles(files)
        }
        if (diagnosisNestedForm === "d_parts") {
            return form.setFieldsValue(nestData?.form_data?.current_part)
        }
        if (diagnosisNestedForm === "cover") {
            form.setFieldsValue({
                file_status_list: nestData?.form_data?.file1?.status,
                status: nestData?.form_data?.file1?.status_id,
                memo: nestData?.form_data?.file1?.memo,
            })
            formOne.setFieldsValue({
                file_status_list: nestData?.form_data?.file2?.status,
                status: nestData?.form_data?.file2?.status_id,
                memo: nestData?.form_data?.file2?.memo,
            })
            const files = {
                cad: formImageList(nestData?.s3_files?.cad),
                gcode: formImageList(nestData?.s3_files?.gcode),
            }
            return setMultiFiles(files)
        }
        if (diagnosisNestedForm === "lamination") {
            form.setFieldsValue({
                file_status_list: nestData?.form_data?.file1?.status,
                status: nestData?.form_data?.file1?.status_id,
                memo: nestData?.form_data?.file1?.memo,
            })
            return setInputFiles(
                formImageList(nestData?.s3_files?.postive_model_for_lamination)
            )
        }

        form.setFieldsValue(nestData?.form_data?.current_diagnosis)
    }, [
        diagnosisNestedForm,
        form,
        formOne,
        formTwo,
        formThree,
        nestData,
        nestData?.form_data?.current_diagnosis,
        type,
    ])

    const addNewDiagnosis = async (values) => {
        // await formData(`/editor/dgns/stump/${id}/${ampSelected}/new/`, values, 'post')
        const newDiagnosis = await http.post(
            `/editor/dgns/stump/${id}/${ampSelected}/new/`,
            values
        )
        formData(
            `/editor/dgns/stump/${id}/${ampSelected}/${newDiagnosis.data.current_diagnosis?.dno}/`,
            undefined,
            "get"
        )
        setLastDno(newDiagnosis.data.current_diagnosis?.dno)
        setNestedForm("d_stump", newDiagnosis.data.current_diagnosis?.dno)
        setDiagModal(false)
    }

    const renderAllDiagnosis = useCallback(() => {
        return nestData?.all_diagnosis?.map(({ dno, pro_type }) => (
            <Radio.Button value={dno}>
                {dno} ({pro_type})
            </Radio.Button>
        ))
    }, [nestData?.all_diagnosis])

    const getOptions = (field, form) => {
        const { options } = field
        if (options) {
            return options
        }
        if (form === "sense") {
            return createOptions(nestData?.form_data?.[field?.optionKey])
        }
        return createOptions(nestData?.form_data?.[field?.optionKey])
    }

    const setInitialValues = () => {
        return nestData?.form_data?.current_diagnosis
    }

    const onProgress = ({ percent: pcnt }, file) => {
        message.loading(`Upload in Progress ${percent}% uploaded`, 0)
    }

    const saveImage = async (options, path, kind, cb, customType) => {
        const { onSuccess, onError, file, onProgress } = options

        const form = new FormData()
        const config = {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: ({ total, loaded }) => {
                onProgress(
                    { percent: Math.floor((loaded / total) * 100) },
                    file
                )
                setPercent(Math.floor((loaded / total) * 100))
            },
        }
        form.append("file", file)
        form.append("stump_flag", false)
        form.append("path", path)
        form.append("kind", kind)
        form.append("subno", customType ?? type)
        form.append("pid", id)
        form.append("ano", ampSelected)
        form.append("dno", lastDno)
        try {
            const upload = await http.post(`editor/upload/files/`, form, config)

            if (upload?.status === 200) {
                onSuccess("Ok")
                await cb?.()
                message?.destroy()
            }
        } catch (err) {
            console.log("Eroor: ", err)
            onError({ err })
        }
    }

    const showWarning = (onOk) => {
        Modal.warn({
            closable: true,
            title: "Are you sure, you want to delete this media?",
            onOk: onOk,
        })
    }

    const deleteMedia = (image, cb) =>
        showWarning(() => deleteMediaConfirm(image, cb))

    const deleteRequest = async (image) =>
        await http.post("editor/delete/files/", { key: image?.uid })

    const deleteMediaConfirm = async (image, fn) => {
        const delRequest = await deleteRequest(image)
        if (delRequest?.status === 200) {
            fn((prevList) => prevList?.filter((img) => img.uid !== image?.uid))
        }
    }

    const triggerMultiDelete = (image, cb) =>
        showWarning(() => deleteNestMediaConfirm(image, cb))

    const deleteNestMediaConfirm = async (image, cb) => {
        const delRequest = await deleteRequest(image)
        if (delRequest?.status === 200) {
            cb()
        }
    }

    const previewImage = (file, kind) => {
        onPreview(file)
        dispatch({
            type: SET_DOWNLOAD_META_DATA,
            payload: {
                kind: kind,
                dno: dno,
                patientId: id,
            },
        })
    }

    const setNestedForm = (name, newDnoSelected) => {
        // passed in newDno later as epr new requirement, there was no provision of changign DNO initially
        const dnoInfoToGet = newDnoSelected || lastDno
        if (name === "d_manufacture") name = "sense"
        setDiagnosisNestedForm(name)
        setType(1)
        setInputFiles([])
        if (!isNotManu(name)) {
            const url = constructNestApiUrl(name, id, ampSelected, dnoInfoToGet)
            formData(url, undefined, "get")
        }
        if (
            selectedNestedForm === "diagnosis" &&
            dnoInfoToGet &&
            isNotManu(name)
        ) {
            const nestDict = {
                d_stump: "stump",
                d_physical: "phy",
                d_parts: "parts",
            }
            formData(
                `/editor/dgns/${nestDict[name]}/${id}/${ampSelected}/${dnoInfoToGet}/`,
                undefined,
                "get"
            )
        }
    }

    const renderSeparateForm = () => {
        switch (diagnosisNestedForm) {
            case "sense":
                return (
                    <Sense
                        showDownloadProps={showDownloadProps}
                        nestData={nestData}
                        previewImage={previewImage}
                        saveImage={saveImage}
                        lastDno={lastDno}
                        dno={dno}
                        formData={formData}
                        ampSelected={ampSelected}
                        getOptions={getOptions}
                        diagnosisNestedForm={diagnosisNestedForm}
                        onProgress={onProgress}
                        setPercent={setPercent}
                        id={id}
                        files={multiFiles}
                        setFiles={setMultiFiles}
                        handleDiagNestSubmission={handleDiagNestSubmission}
                        formOne={formOne}
                        formTwo={formTwo}
                        deleteMedia={deleteMedia}
                        triggerMultiDelete={triggerMultiDelete}
                    />
                )
            case "testsocket":
                return (
                    <TestSocket
                        showDownloadProps={showDownloadProps}
                        onProgress={onProgress}
                        id={id}
                        ampSelected={ampSelected}
                        previewImage={previewImage}
                        dno={dno}
                        saveImage={saveImage}
                        lastDno={lastDno}
                        nestData={nestData}
                        formData={formData}
                        triggerMultiDelete={triggerMultiDelete}
                        getOptions={getOptions}
                        diagnosisNestedForm={diagnosisNestedForm}
                        formOne={formOne}
                        formTwo={formTwo}
                        handleDiagNestSubmission={handleDiagNestSubmission}
                        files={multiFiles}
                        setFiles={setMultiFiles}
                    />
                )
            case "final":
                return (
                    <Final
                        showDownloadProps={showDownloadProps}
                        id={id}
                        ampSelected={ampSelected}
                        onProgress={onProgress}
                        saveImage={saveImage}
                        lastDno={lastDno}
                        formData={formData}
                        nestData={nestData}
                        previewImage={previewImage}
                        dno={dno}
                        getOptions={getOptions}
                        diagnosisNestedForm={diagnosisNestedForm}
                        formOne={formOne}
                        formTwo={formTwo}
                        files={multiFiles}
                        triggerMultiDelete={triggerMultiDelete}
                        handleDiagNestSubmission={handleDiagNestSubmission}
                        setFiles={setMultiFiles}
                    />
                )
            case "inspection":
                return (
                    <Inspection
                        form={form}
                        showDownloadProps={showDownloadProps}
                        id={id}
                        ampSelected={ampSelected}
                        onProgress={onProgress}
                        saveImage={saveImage}
                        lastDno={lastDno}
                        formData={formData}
                        nestData={nestData}
                        previewImage={previewImage}
                        dno={dno}
                        getOptions={getOptions}
                        diagnosisNestedForm={diagnosisNestedForm}
                        formOne={formOne}
                        formTwo={formTwo}
                        formThree={formThree}
                        files={multiFiles}
                        triggerMultiDelete={triggerMultiDelete}
                        handleDiagNestSubmission={handleDiagNestSubmission}
                        setFiles={setMultiFiles}
                    />
                )
            case "softinsert":
                return (
                    <SoftInsert
                        form={form}
                        showDownloadProps={showDownloadProps}
                        id={id}
                        ampSelected={ampSelected}
                        onProgress={onProgress}
                        saveImage={saveImage}
                        lastDno={lastDno}
                        formData={formData}
                        nestData={nestData}
                        previewImage={previewImage}
                        dno={dno}
                        getOptions={getOptions}
                        diagnosisNestedForm={diagnosisNestedForm}
                        formOne={formOne}
                        formTwo={formTwo}
                        formThree={formThree}
                        files={multiFiles}
                        triggerMultiDelete={triggerMultiDelete}
                        handleDiagNestSubmission={handleDiagNestSubmission}
                        setFiles={setMultiFiles}
                    />
                )
            case "cushion":
                return (
                    <Cushion
                        form={form}
                        showDownloadProps={showDownloadProps}
                        id={id}
                        ampSelected={ampSelected}
                        onProgress={onProgress}
                        saveImage={saveImage}
                        lastDno={lastDno}
                        formData={formData}
                        nestData={nestData}
                        previewImage={previewImage}
                        dno={dno}
                        getOptions={getOptions}
                        diagnosisNestedForm={diagnosisNestedForm}
                        formOne={formOne}
                        formTwo={formTwo}
                        formThree={formThree}
                        files={multiFiles}
                        triggerMultiDelete={triggerMultiDelete}
                        handleDiagNestSubmission={handleDiagNestSubmission}
                        setFiles={setMultiFiles}
                    />
                )
            case "d_parts":
                return (
                    <Parts
                        form={form}
                        nestData={nestData}
                        handleDiagNestSubmission={handleDiagNestSubmission}
                        diagnosisNestedForm={diagnosisNestedForm}
                    />
                )
            case "cover":
                return (
                    <ProstheticCover
                        form={form}
                        formOne={formOne}
                        showDownloadProps={showDownloadProps}
                        id={id}
                        ampSelected={ampSelected}
                        onProgress={onProgress}
                        saveImage={saveImage}
                        lastDno={lastDno}
                        formData={formData}
                        nestData={nestData}
                        previewImage={previewImage}
                        dno={dno}
                        getOptions={getOptions}
                        diagnosisNestedForm={diagnosisNestedForm}
                        files={multiFiles}
                        handleDiagNestSubmission={handleDiagNestSubmission}
                        setFiles={setMultiFiles}
                        triggerMultiDelete={triggerMultiDelete}
                    />
                )
            default:
                return null
        }
    }

    const renderForm = () => {
        switch (diagnosisNestedForm) {
            case "eiscan":
                return (
                    <>
                        <Form.Item label="Upload" name={"sl"}>
                            <div className={UploadWrapper}>
                                <Upload
                                    {...showDownloadProps}
                                    onProgress={onProgress}
                                    onPreview={(file) => previewImage(file, 2)}
                                    onDownload={(file) => {
                                        onDownload({
                                            id,
                                            dno,
                                            kind: 2,
                                            filename: file.name,
                                            url: file.url,
                                        })
                                    }}
                                    fileList={inputFiles}
                                    accept={filterFileType("eiscan", type)}
                                    beforeUpload={(file) =>
                                        beforeUpload(file, "eiscan", inputFiles)
                                    }
                                    customRequest={(options) =>
                                        saveImage(
                                            options,
                                            `patientdata/${id}/${lastDno}/einscan/`,
                                            2,
                                            () =>
                                                formData(
                                                    `/editor/manu/ein/${id}/${ampSelected}/${lastDno}/`,
                                                    undefined,
                                                    "get"
                                                ),
                                            0
                                        )
                                    }
                                    listType="picture"
                                    onRemove={(image) =>
                                        deleteMedia(image, setInputFiles)
                                    }
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Upload
                                    </Button>
                                    {formatHelper("eiscan", type)}
                                </Upload>
                                {infoHelper(
                                    nestData?.form_data?.file1?.upload_staff,
                                    nestData?.form_data?.file1?.upload_date
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item
                            style={{ paddingBottom: 0, marginBottom: 5 }}
                            label="Status"
                            name="status"
                        >
                            {FormRenderer("select", {
                                options: getOptions(
                                    { optionKey: "file_status_list" },
                                    diagnosisNestedForm
                                ),
                            })}
                        </Form.Item>
                        {assessedBy(
                            nestData?.form_data?.file1?.approval_staff,
                            nestData?.form_data?.file1?.approval_date
                        )}
                        <Form.Item label="Memo" name="memo">
                            {FormRenderer("textarea")}
                        </Form.Item>
                    </>
                )
            case "ai":
                return (
                    <>
                        <Form.Item label="Input">
                            <Upload
                                {...showDownloadProps}
                                onProgress={onProgress}
                                onPreview={(file) => previewImage(file, 7)}
                                onDownload={(file) => {
                                    onDownload({
                                        id,
                                        dno,
                                        kind: 7,
                                        filename: file.name,
                                        url: file.url,
                                    })
                                }}
                                fileList={inputFiles}
                                beforeUpload={(file) =>
                                    beforeUpload(file, "ai", inputFiles)
                                }
                                customRequest={(options) =>
                                    saveImage(
                                        options,
                                        `patientdata/${id}/${lastDno}/ai/input/`,
                                        7,
                                        () =>
                                            formData(
                                                `/editor/manu/ai/${id}/${ampSelected}/${lastDno}/`,
                                                undefined,
                                                "get"
                                            )
                                    )
                                }
                                listType="picture"
                                onRemove={(image) =>
                                    deleteMedia(image, setInputFiles)
                                }
                            >
                                <Button icon={<UploadOutlined />}>
                                    Upload
                                </Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item label="Output">
                            <Upload
                                {...showDownloadProps}
                                onProgress={onProgress}
                                onPreview={(file) => previewImage(file, 7)}
                                onDownload={(file) => {
                                    onDownload({
                                        id,
                                        dno,
                                        kind: 7,
                                        filename: file.name,
                                        url: file.url,
                                    })
                                }}
                                fileList={outputFiles}
                                beforeUpload={(file) =>
                                    beforeUpload(file, "ai", outputFiles)
                                }
                                customRequest={(options) =>
                                    saveImage(
                                        options,
                                        `patientdata/${id}/${lastDno}/ai/output/`,
                                        7,
                                        () =>
                                            formData(
                                                `/editor/manu/ai/${id}/${ampSelected}/${lastDno}/`,
                                                undefined,
                                                "get"
                                            )
                                    )
                                }
                                listType="picture"
                                onRemove={(image) =>
                                    deleteMedia(image, setOutputFiles)
                                }
                            >
                                <Button icon={<UploadOutlined />}>
                                    Upload
                                </Button>
                            </Upload>
                        </Form.Item>
                    </>
                )
            case "picture":
                return (
                    <>
                        <Form.Item label="Front">
                            <div className={UploadWrapper}>
                                <Upload
                                    onProgress={onProgress}
                                    onPreview={(file) => previewImage(file, 5)}
                                    fileList={inputFiles}
                                    accept={filterFileType("picture", type)}
                                    beforeUpload={(file) =>
                                        beforeUpload(
                                            file,
                                            "picture",
                                            inputFiles
                                        )
                                    }
                                    customRequest={(options) =>
                                        saveImage(
                                            options,
                                            `patientdata/${id}/${lastDno}/picture/front/`,
                                            5,
                                            () =>
                                                formData(
                                                    `/editor/manu/pict/${id}/${ampSelected}/${lastDno}/`,
                                                    undefined,
                                                    "get"
                                                )
                                        )
                                    }
                                    listType="picture"
                                    onRemove={(image) =>
                                        deleteMedia(image, setInputFiles)
                                    }
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Upload
                                    </Button>
                                    {formatHelper("picture", type)}
                                </Upload>
                                {infoHelper(
                                    nestData?.form_data?.file1?.upload_staff,
                                    nestData?.form_data?.file1?.upload_date
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item label="Side">
                            <div className={UploadWrapper}>
                                <Upload
                                    onProgress={onProgress}
                                    onPreview={(file) => previewImage(file, 5)}
                                    fileList={outputFiles}
                                    accept={filterFileType("picture", type)}
                                    beforeUpload={(file) =>
                                        beforeUpload(
                                            file,
                                            "picture",
                                            outputFiles
                                        )
                                    }
                                    customRequest={(options) =>
                                        saveImage(
                                            options,
                                            `patientdata/${id}/${lastDno}/picture/side/`,
                                            5,
                                            () =>
                                                formData(
                                                    `/editor/manu/pict/${id}/${ampSelected}/${lastDno}/`,
                                                    undefined,
                                                    "get"
                                                ),
                                            2
                                        )
                                    }
                                    listType="picture"
                                    onRemove={(image) =>
                                        deleteMedia(image, setOutputFiles)
                                    }
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Upload
                                    </Button>
                                    {formatHelper("picture", type)}
                                </Upload>
                                {infoHelper(
                                    nestData?.form_data?.file2?.upload_staff,
                                    nestData?.form_data?.file2?.upload_date
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item
                            style={{ paddingBottom: 0, marginBottom: 5 }}
                            label="Status"
                            name="status"
                        >
                            {FormRenderer("select", {
                                options: getOptions(
                                    { optionKey: "file_status_list" },
                                    diagnosisNestedForm
                                ),
                            })}
                        </Form.Item>
                        {assessedBy(
                            nestData?.form_data?.file1?.approval_staff,
                            nestData?.form_data?.file1?.approval_date
                        )}
                        <Form.Item label="Memo" name="memo">
                            {FormRenderer("textarea")}
                        </Form.Item>
                    </>
                )
            case "movie":
                return (
                    <>
                        <Form.Item label="Movie" name={"sl"}>
                            <div className={UploadWrapper}>
                                <Upload
                                    {...showDownloadProps}
                                    onProgress={onProgress}
                                    onPreview={(file) => previewImage(file, 6)}
                                    onDownload={(file) => {
                                        onDownload({
                                            id,
                                            dno,
                                            kind: 6,
                                            filename: file.name,
                                            url: file.url,
                                        })
                                    }}
                                    fileList={inputFiles}
                                    accept={filterFileType("movie", type)}
                                    beforeUpload={(file) =>
                                        beforeUpload(file, "movie", inputFiles)
                                    }
                                    customRequest={(options) =>
                                        saveImage(
                                            options,
                                            `patientdata/${id}/${lastDno}/movie/`,
                                            6,
                                            () =>
                                                formData(
                                                    `/editor/manu/movie/${id}/${ampSelected}/${lastDno}/`,
                                                    undefined,
                                                    "get"
                                                ),
                                            0
                                        )
                                    }
                                    listType="picture"
                                    onRemove={(image) =>
                                        deleteMedia(image, setInputFiles)
                                    }
                                    multiple
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Upload
                                    </Button>
                                    {formatHelper("movie", type)}
                                </Upload>
                                {infoHelper(
                                    nestData?.form_data?.file1?.upload_staff,
                                    nestData?.form_data?.file1?.upload_date
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item label="Status" name="status">
                            {FormRenderer("select", {
                                options: getOptions(
                                    { optionKey: "file_status_list" },
                                    diagnosisNestedForm
                                ),
                            })}
                        </Form.Item>
                        {assessedBy(
                            nestData?.form_data?.file1?.approval_staff,
                            nestData?.form_data?.file1?.approval_date
                        )}
                        <Form.Item label="Memo" name="memo">
                            {FormRenderer("textarea")}
                        </Form.Item>
                    </>
                )
            case "d_physical":
                return diagnosisForms[diagnosisNestedForm]?.[
                    proTypeDetect
                ]?.map((field) => (
                    <Form.Item key={field.name} {...field}>
                        {FormRenderer(field.type, {
                            ...field.props,
                            // value: valueFormatter(field.type, data?.basic?.[field.name]),
                            options: getOptions(field, diagnosisNestedForm),
                        })}
                    </Form.Item>
                ))
            case "lamination":
                return (
                    <Lamination
                        form={form}
                        showDownloadProps={showDownloadProps}
                        id={id}
                        ampSelected={ampSelected}
                        onProgress={onProgress}
                        saveImage={saveImage}
                        lastDno={lastDno}
                        formData={formData}
                        nestData={nestData}
                        previewImage={previewImage}
                        dno={dno}
                        getOptions={getOptions}
                        diagnosisNestedForm={diagnosisNestedForm}
                        files={inputFiles}
                        handleDiagNestSubmission={handleDiagNestSubmission}
                        setInputFiles={setInputFiles}
                        deleteMedia={deleteMedia}
                    />
                )

            default:
                return diagnosisForms[diagnosisNestedForm]?.map((field) => (
                    <Form.Item
                        rules={[
                            {
                                required: isRequired(field.name),
                                message: "Please Enter Reason!",
                            },
                        ]}
                        key={field.name}
                        {...field}
                    >
                        {FormRenderer(field.type, {
                            ...field.props,
                            // value: valueFormatter(field.type, data?.basic?.[field.name]),
                            options: getOptions(field, diagnosisNestedForm),
                        })}
                    </Form.Item>
                ))
        }
    }

    const handleDiagNestSubmission = (values, form, subno) => {
        const nestedForms = ["eiscan", "picture", "movie", "lamination"]

        if (nestedForms?.includes(form) && !inputFiles?.length) {
            return message.error("No File Uploaded")
        }

        if (form === "picture" && !outputFiles?.length) {
            return message.error("Please upload side photo")
        }

        if (multipleForm.includes(form) && form !== "d_parts") {
            const valuesCopy = { ...values }
            valuesCopy[`subno${subno}`] = subno
            return formData(urlDict[form], valuesCopy, "post")
        }

        formData(urlDict[form], values, "post")
    }

    const changeDno = (target) => {
        setLastDno(target.value)
        setNestedForm("d_stump", target.value)
    }

    return (
        <>
            <Modal
                visible={diagnosisModal}
                maskStyle={{ backgroundColor: "rgb(0 0 0 / 78%)" }}
                // mask={true}
                title="Add New Amputation"
                onCancel={() => {
                    setDiagModal(false)
                    setSelectedForm("info")
                    formData(
                        `/editor/${id}/amp/${ampSelected}/info`,
                        undefined,
                        "get"
                    )
                }}
                footer={[
                    <Button
                        onClick={() => newProForm.current.submit()}
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Save
                    </Button>,
                ]}
            >
                <Form
                    ref={newProForm}
                    name="new_prosth_form"
                    onFinish={(values) => addNewDiagnosis(values)}
                >
                    <Form.Item name="proinfo_id" label="Prosthesis Type">
                        {FormRenderer("select", {
                            options: createOptions(
                                nestData?.form_data?.prosthesis_info
                            ),
                        })}
                    </Form.Item>
                </Form>
            </Modal>
            <Row>
                <Col style={{ marginBottom: 12 }} span={24}>
                    {/* {!lastDno ? ( */}
                    <Space>
                        <Radio.Group
                            value={lastDno}
                            onChange={({ target }) => {
                                changeDno(target)
                            }}
                        >
                            {renderAllDiagnosis()}
                        </Radio.Group>
                        <Button
                            type="primary"
                            icon={<FileAddOutlined />}
                            size={"default"}
                            onClick={checkAndProceed}
                        >
                            Add Diagnosis
                        </Button>
                    </Space>
                </Col>
                {nestData?.all_diagnosis?.length ? (
                    <Col>
                        <Tree
                            showLine={{
                                showLeafIcon: false,
                            }}
                            // expandedKeys={['0-0-0']}
                            showIcon
                            // switcherIcon={<DownOutlined />}
                            selectedKeys={[diagnosisNestedForm]}
                            defaultExpandedKeys={["d_manufacture"]}
                            onSelect={(a, b) => {
                                if (!a[0] || a[0] === "0-0") return
                                setNestedForm(a[0])
                            }}
                            treeData={treeData(
                                dno,
                                proType,
                                contextStore?.currentDiagnosis
                            )}
                        />
                    </Col>
                ) : null}
                {!!lastDno && (
                    <>
                        <Col span={1}>
                            <Divider
                                style={{ height: "100%" }}
                                type="vertical"
                            ></Divider>
                        </Col>
                        <Col span={18}>
                            {!multipleForm.includes(diagnosisNestedForm) ? (
                                <Form
                                    form={form}
                                    initialValues={setInitialValues}
                                    onFinish={(values) =>
                                        handleDiagNestSubmission(
                                            values,
                                            diagnosisNestedForm
                                        )
                                    }
                                    labelCol={{ span: 10 }}
                                    labelAlign="left"
                                    wrapperCol={{ span: 10 }}
                                >
                                    {renderForm()}
                                    <Row gutter={6} justify="end">
                                        <Col>
                                            <Button
                                                onClick={() =>
                                                    navigate("/patients")
                                                }
                                            >
                                                Back to Patients
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            ) : (
                                renderSeparateForm()
                            )}
                        </Col>
                    </>
                )}
            </Row>
        </>
    )
}

export default DiagnosisInfoTab
