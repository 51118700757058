import { message } from 'antd'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { http } from '../network/axiosInstance'
import { createURL } from '../utils/helpers'

function useFormData(basePath = 'editor', path, id, keyword, method = 'get', body, onSuccess) {
  if (!basePath) basePath = 'editor'

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const formData = useCallback(
    async (path, body, method, onSuccess) => {
      setLoading(true)
      try {
        //console.log('created url is ', createURL(path,id))
        //let url = id?`${path}/${id}/`:`${path}/`
        let url = createURL(path,id)
        if (path === 'amp') {
          url = method === 'post' ? `${id}/amp/new/` : undefined
          if (!url) return
        }

        // const dataFromServer = !path
        //   ? await http[method](`${basePath}/${id}/`, body)
        //   : await http[method](`${basePath}/${url}`, body)
         const dataFromServer = await http[method](`${basePath}/${url}`, body)

        if (path === 'sub') {
          const dateFormatted = {
            ...dataFromServer.data?.sub,
          }
          dateFormatted.sub_end_date = moment(dataFromServer.data?.sub?.sub_end_date)
          dateFormatted.sub_start_date = moment(dataFromServer.data?.sub?.sub_start_date)
          dataFromServer.data.sub = dateFormatted
        } else if (path === 'basic') {
          dataFromServer.data.basic.birthday = moment(dataFromServer.data?.basic?.birthday)
        }
        
        setData(dataFromServer.data)

        if (method === 'post' || method === 'put') {
          message.info('Updated Successfully')
          onSuccess()
        }
      } catch (error) {
        
      } finally {
        setLoading(false)
      }
    },
    [basePath, id]
  )
  
  useEffect(() => {
    formData(path, body, method, onSuccess)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, formData, method])

  return { data, loading, formData }
}

export default useFormData
