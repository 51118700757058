import { useCallback, useEffect, useState } from 'react'
import { http } from '../network/axiosInstance'
import { LIMIT } from '../utils/constants'

function useTabViewData(basePath, path, keyword, method, body) {
  if (!basePath) basePath = 'editor'

  const [data, setData] = useState([])
  const [filters, setFilters] = useState({})
  const [limit, setLimit] = useState(LIMIT)
  const [currentPage, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setPage(1)
  }, [limit])

  const tableData = useCallback(
    async (path, page, limit, body) => {
      setLoading(true)
      const dataFromServer = await http[method](`${basePath}${path}?page=${page}&limit=${limit}`, body)
      
      if (keyword) {
        setData(dataFromServer.data?.[keyword])
      } else {
        setData(dataFromServer.data)
      }
      if(dataFromServer.data.search_params) setFilters(dataFromServer.data.search_params)
      setTotalPages(dataFromServer?.data?.count)
      setLoading(false)
    },
    [basePath, keyword, method]
  )

  useEffect(() => {
    tableData(path, currentPage, limit, body)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, tableData, currentPage])

  return {data, loading, totalPages, tableData, limit, setLimit, currentPage, setPage, filters}
}

export default useTabViewData
